import React, { useEffect, useState } from "react";
import PageWrapper from "../../../components/PageWrapper";
import { getPostcodes, uploadPriceListWithPostcodes } from "../../../api/quote";
import { toast } from "react-toastify";
import { MdDeleteForever } from "react-icons/md";
import { useGlobalContext } from "../../../GlobalContext";
import { useNavigate } from "react-router-dom";
import ConfirmationModal from "../../../components/ConfirmationModal";
import Modal from "../../../components/Modal";
import LoadingIcon from "../../../assets/Loading.svg";

const ImportWizard = () => {
  const { state } = useGlobalContext();
  const [postcodes, setPostcodes] = useState([]);
  const [filteredPostcodes, setFilteredPostcodes] = useState([]);
  const [checkedPostcodes, setCheckedPostcodes] = useState([]);
  const [selectedPostcodes, setSelectedPostcodes] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [file, setFile] = useState(null);
  const [rows, setRows] = useState(0);
  const [confirming, setConfirming] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchPostcodes = async () => {
      try {
        const postcodesData = await getPostcodes();
        const sortedPostcodes = postcodesData?.postcode_area_districts.sort();
        setPostcodes(sortedPostcodes);
      } catch (error) {
        console.error(error);
        toast.error("Error fetching postcodes..");
      }
    };
    fetchPostcodes();
  }, []);
  useEffect(() => {
    if (searchTerm === "") {
      const filteredData = postcodes.filter(
        (postcode) => !selectedPostcodes.includes(postcode),
      );
      setFilteredPostcodes(filteredData);
    } else {
      const filteredData = postcodes.filter(
        (postcode) =>
          postcode.toLowerCase().startsWith(searchTerm.toLowerCase()) &&
          !selectedPostcodes.includes(postcode),
      );

      setFilteredPostcodes(filteredData);
    }
  }, [searchTerm, postcodes, selectedPostcodes]);

  const onSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };
  const handleAddPostcodes = (e) => {
    e.preventDefault();
    setSelectedPostcodes((prevData) => [...prevData, ...checkedPostcodes]);
    setCheckedPostcodes([]);
  };
  const handleCheckChange = (e) => {
    if (e.target.checked) {
      setCheckedPostcodes((prevData) => [...prevData, e.target.value]);
    } else {
      setCheckedPostcodes((prevData) => {
        const filteredData = prevData.filter(
          (postcode) => postcode !== e.target.value,
        );
        return filteredData;
      });
    }
  };

  const handleCheckAllChange = (e) => {
    if (e.target.checked) {
      setCheckedPostcodes(filteredPostcodes);
    } else {
      setCheckedPostcodes([]);
    }
  };

  const deletePostcode = (postcode) => {
    setSelectedPostcodes((prevData) => {
      const filteredData = prevData.filter(
        (postcodeData) => postcodeData !== postcode,
      );
      return filteredData;
    });
  };

  const handleFileChange = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    if (!file) {
      return;
    }
    const reader = new FileReader();
    reader.onload = function (e) {
      const content = e.target.result;
      const rows = content.split("\n");
      const nonEmptyRows = rows.filter((row) => row.trim() !== "");
      const rowCount = nonEmptyRows.length - 1; // minus header
      setRows(rowCount);
      setFile(file);
      e.target.value = null;
    };

    reader.readAsText(file);
  };
  const submitCSV = async () => {
    try {
      setConfirming(false);
      setLoading(true);
      await uploadPriceListWithPostcodes(
        state.selectedCompany,
        file,
        selectedPostcodes,
      );
      setLoading(false);
      navigate("/price-list");
    } catch (error) {
      console.error(error);
      toast.error("Failed to upload prices.");
      setLoading(false);
    }
  };

  return (
    <PageWrapper>
      <div className="flex-1 p-6 xl:p-8 2xl:p-10">
        <div className="flex w-full items-center justify-between">
          <div className="my-2 mb-8 flex flex-col gap-2">
            <label htmlFor="csv-file" className="font-bold">
              Upload CSV file
            </label>
            <input
              type="file"
              name="csv-file"
              id="csv-file"
              accept=".csv"
              onChange={handleFileChange}
            />
          </div>
          <button
            className="cursor-pointer gap-2 rounded-md border border-transparent bg-primaryColor px-2 py-1 text-center text-sm text-secondaryColor shadow-md transition-all hover:border-slate-700 hover:bg-slate-700 hover:text-white hover:shadow-lg focus:border-slate-700 focus:bg-slate-700 focus:text-white active:border-slate-700 active:bg-slate-700 active:text-white lg:px-3 lg:py-2 lg:text-base"
            onClick={() => setConfirming(true)}
            disabled={file === null || selectedPostcodes.length === 0}
          >
            Submit
          </button>
        </div>
        <div className="flex gap-4">
          <form
            onSubmit={handleAddPostcodes}
            className="flex h-[55vh] w-2/3 flex-col gap-4"
          >
            <div className="flex w-full justify-between gap-4">
              <input
                name="search"
                id="search"
                type="text"
                value={searchTerm}
                placeholder="Search postcode"
                onChange={onSearchChange}
                className="h-8 w-1/2 appearance-none rounded border-2 px-3 py-2 text-sm leading-tight text-gray-700 shadow focus:outline-none focus:ring 2xl:h-10 2xl:text-base"
              />
              <button
                type="submit"
                className="cursor-pointer gap-2 rounded-md border border-transparent bg-secondaryColor px-2 py-1 text-center text-sm text-primaryColor shadow-md transition-all hover:border-gray-200 hover:bg-gray-200 hover:shadow-lg focus:border-gray-200 focus:bg-gray-200 active:border-gray-200 active:bg-gray-200"
              >
                Add Selected Postcodes
              </button>
            </div>
            <ul className="flex h-[50vh] flex-wrap content-start overflow-y-scroll border-2 p-3">
              <div className="flex w-full gap-4 text-lg">
                <input
                  type="checkbox"
                  checked={
                    JSON.stringify(checkedPostcodes) ==
                    JSON.stringify(filteredPostcodes)
                  }
                  name="check-all"
                  value="check-all"
                  onChange={handleCheckAllChange}
                />
                <label htmlFor="check-all">Select All</label>
              </div>
              {filteredPostcodes?.map((postcode, index) => {
                return (
                  <li className="flex h-fit w-1/4 gap-4" key={index}>
                    <input
                      type="checkbox"
                      checked={checkedPostcodes.includes(postcode)}
                      name={postcode}
                      value={postcode}
                      onChange={handleCheckChange}
                    />
                    <label htmlFor={postcode}>{postcode}</label>
                  </li>
                );
              })}
            </ul>
          </form>
          <div className="h-[55vh] w-1/3 border-2 bg-gray-200 p-3">
            <h2 className="text-center text-lg font-bold">
              Selected postcodes
            </h2>
            <ul className="flex h-[50vh] flex-wrap content-start justify-between overflow-y-scroll p-2">
              {selectedPostcodes.map((postcode, index) => {
                return (
                  <li
                    key={index}
                    className="flex h-fit w-5/12 justify-between gap-4"
                  >
                    <p>{postcode}</p>
                    <button onClick={() => deletePostcode(postcode)}>
                      <MdDeleteForever className="text-red-500" />
                    </button>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
      <ConfirmationModal
        isOpen={confirming}
        onClose={() => setConfirming(false)}
        onConfirm={submitCSV}
        question={`Are you sure you want to upload ${rows * selectedPostcodes.length} rows?`}
      />
      <Modal isOpen={loading}>
        <div className="flex h-[20vh] w-[20vw] flex-col items-center justify-center gap-2 p-4">
          <img src={LoadingIcon} alt="loading icon" className="size-1/2" />
          <h2 className="text-lg">Loading...</h2>
        </div>
      </Modal>
    </PageWrapper>
  );
};
export default ImportWizard;
