import React, { useEffect, useRef, useState } from "react";
import { currencyFormat, singleLineAddress } from "../../lib/formatters";
// import { downloadPDF } from "../../lib/pdf";
import { useParams } from "react-router-dom";
import {
  fetchInternalQuote,
  fetchQuotePDF,
  patchInternalQuote,
} from "../../api/quote";
import { useGlobalContext } from "../../GlobalContext";
import {
  containerTypeNameLookup,
  frequencyLookup,
  wasteTypeNameLookup,
  weeklyFrequencyLookup,
} from "../../lib/lookups";
import { getCompanyPublicDetails } from "../../api/company";
import { extractAddressParts } from "../../lib/google-maps";
import { toast } from "react-toastify";
import { downloadPDF, generateQuotePDFBytes } from "../../lib/pdf";

const QuoteDetailsInternal = () => {
  const [quote, setQuote] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    address_line_1: "",
    address_line_2: "",
    town: "",
    postcode: "",
    county: "",
  });
  const [isEditing, setIsEditing] = useState(true);
  const [formValidation, setFormValidation] = useState({
    name: null,
    email: null,
    address_line_1: null,
    town: null,
    postcode: null,
    county: null,
  });
  const [PDFBlob, setPDFBlob] = useState(null);
  const inputRef = useRef(null);
  const { state } = useGlobalContext();
  let { quoteuuid } = useParams();
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  useEffect(() => {
    const fetchQuote = async () => {
      try {
        const quote = await fetchInternalQuote(
          state?.selectedCompany,
          quoteuuid,
        );
        const supplierDetails = await getCompanyPublicDetails([
          quote?.supplier_uuid,
        ]);
        quote.supplier_name = supplierDetails[0].name;
        setQuote(quote);
        if (quote.customer_details.name) {
          setIsEditing(false);
        }
      } catch (error) {
        console.error(error);
        toast.error("Failed to fetch quote.");
      }
    };
    fetchQuote();
  }, [quoteuuid, state?.selectedCompany]);

  useEffect(() => {
    if (!window.google) return; // Ensure the Google script has loaded

    const autocomplete = new window.google.maps.places.Autocomplete(
      inputRef.current,
      {
        types: ["geocode"],
        componentRestrictions: { country: "GB" },
      },
    );

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          const userLocation = new window.google.maps.LatLng(
            latitude,
            longitude,
          );

          // Bias the autocomplete results to the user's location
          autocomplete.setBounds(
            new window.google.maps.LatLngBounds(userLocation),
          );
        },
        (error) => {
          console.error("Error getting user's location:", error);
        },
      );
    }

    inputRef.current.placeholder = "Start typing your address or postcode";

    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      if (place.address_components) {
        const components = place.address_components;
        const addressParts = extractAddressParts(components);
        setFormData((prevData) => ({
          ...prevData,
          address_line_1: addressParts.addressLine1,
          address_line_2: addressParts.addressLine2,
          town: addressParts.town,
          county: addressParts.county,
          postcode: addressParts.postcode,
        }));
      }
    });
    return () => {
      if (inputRef.current) {
        window.google.maps.event.removeListener(inputRef.current);
      }
    };
  }, []);

  const resetForm = () => {
    setFormData(quote?.customer_details || {});
    setFormValidation({
      name: null,
      email: null,
      address_line_1: null,
      town: null,
      postcode: null,
      county: null,
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValidName = validateName();
    const isValidEmail = validateEmail();
    const isValidAddressLine1 = validateAddressLine1();
    const isValidTown = validateTown();
    const isValidCounty = validateCounty();
    const isValidPostcode = validatePostcode();

    if (
      !isValidName ||
      !isValidEmail ||
      !isValidAddressLine1 ||
      !isValidTown ||
      !isValidCounty ||
      !isValidPostcode
    ) {
      return;
    }
    try {
      const newPDFBytes = await generateQuotePDFBytes(formData?.name, quote);
      await patchInternalQuote(
        formData,
        quote.quoting_session_uuid,
        quote.uuid,
        newPDFBytes,
      );
      setPDFBlob(newPDFBytes);
      setQuote((prevData) => ({ ...prevData, customer_details: formData }));
      setIsEditing(false);
    } catch {
      toast("Error accepting quote. Please try again.");
    }
  };

  const validateName = () => {
    if (formData.name) {
      setFormValidation((prevFormValidation) => ({
        ...prevFormValidation,
        name: true,
      }));
      return true;
    } else {
      setFormValidation((prevFormValidation) => ({
        ...prevFormValidation,
        name: false,
      }));
      return false;
    }
  };

  const handleNameChange = (e) => {
    formValidation.name === false &&
      e.target.value &&
      setFormValidation((prevFormValidation) => ({
        ...prevFormValidation,
        name: true,
      }));
    setFormData((prevData) => ({
      ...prevData,
      name: e.target.value,
    }));
  };
  const validateEmail = () => {
    if (emailRegex.test(formData.email)) {
      setFormValidation((prevFormValidation) => ({
        ...prevFormValidation,
        email: true,
      }));
      return true;
    } else {
      setFormValidation((prevFormValidation) => ({
        ...prevFormValidation,
        email: false,
      }));
      return false;
    }
  };

  const handleEmailChange = (e) => {
    formValidation.email === false &&
      emailRegex.test(e.target.value) &&
      setFormValidation((prevFormValidation) => ({
        ...prevFormValidation,
        email: true,
      }));
    setFormData((prevData) => ({
      ...prevData,
      email: e.target.value,
    }));
  };

  const validateAddressLine1 = () => {
    if (formData.address_line_1) {
      setFormValidation((prevFormValidation) => ({
        ...prevFormValidation,
        address_line_1: true,
      }));
      return true;
    } else {
      setFormValidation((prevFormValidation) => ({
        ...prevFormValidation,
        address_line_1: false,
      }));
      return false;
    }
  };

  const handleAddressLine1Change = (e) => {
    formValidation.address_line_1 === false &&
      e.target.value &&
      setFormValidation((prevFormValidation) => ({
        ...prevFormValidation,
        address_line_1: true,
      }));
    setFormData((prevData) => ({
      ...prevData,
      address_line_1: e.target.value,
    }));
  };

  const validateTown = () => {
    if (formData.town) {
      setFormValidation((prevFormValidation) => ({
        ...prevFormValidation,
        town: true,
      }));
      return true;
    } else {
      setFormValidation((prevFormValidation) => ({
        ...prevFormValidation,
        town: false,
      }));
      return false;
    }
  };

  const handleTownChange = (e) => {
    formValidation.town === false &&
      e.target.value &&
      setFormValidation((prevFormValidation) => ({
        ...prevFormValidation,
        town: true,
      }));
    setFormData((prevData) => ({
      ...prevData,
      town: e.target.value,
    }));
  };

  const validateCounty = () => {
    if (formData.county) {
      setFormValidation((prevFormValidation) => ({
        ...prevFormValidation,
        county: true,
      }));
      return true;
    } else {
      setFormValidation((prevFormValidation) => ({
        ...prevFormValidation,
        county: false,
      }));
      return false;
    }
  };

  const handleCountyChange = (e) => {
    formValidation.county === false &&
      e.target.value &&
      setFormValidation((prevFormValidation) => ({
        ...prevFormValidation,
        county: true,
      }));
    setFormData((prevData) => ({
      ...prevData,
      county: e.target.value,
    }));
  };

  const validatePostcode = () => {
    const postcodeRegex =
      /^([a-zA-Z]{1,2}[0-9][0-9a-zA-Z]?)\s?[0-9][a-zA-Z]{2}?/;
    if (postcodeRegex.test(formData.postcode)) {
      setFormValidation((prevFormValidation) => ({
        ...prevFormValidation,
        postcode: true,
      }));
      return true;
    } else {
      setFormValidation((prevFormValidation) => ({
        ...prevFormValidation,
        postcode: false,
      }));
      return false;
    }
  };

  const handlePostcodeChange = (e) => {
    const postcodeRegex =
      /^([a-zA-Z]{1,2}[0-9][0-9a-zA-Z]?)\s?[0-9][a-zA-Z]{2}?/;
    formValidation.postcode === false &&
      postcodeRegex.test(e.target.value) &&
      setFormValidation((prevFormValidation) => ({
        ...prevFormValidation,
        postcode: true,
      }));
    setFormData((prevData) => ({
      ...prevData,
      postcode: e.target.value,
    }));
  };

  const getPDF = async () => {
    if (PDFBlob !== null) {
      downloadPDF(PDFBlob);
    } else {
      const pdf = await fetchQuotePDF(state?.selectedCompany, quote.uuid);
      downloadPDF(pdf);
      setPDFBlob(pdf);
    }
  };

  const th = "px-1.5 py-1 lg:px-3 lg:py-2";
  const td =
    "px-1.5 py-1 text-[0.5rem] leading-3 text-gray-900 md:text-xs lg:px-3 lg:py-2 lg:text-sm";

  return (
    <div className="w-full p-4 xl:p-6 2xl:p-8">
      <div className="border-b border-slate-600 pb-3 lg:border-b-2 lg:pb-5">
        <p className="pb-3 text-base text-black md:text-lg lg:pb-5 lg:text-lg xl:pb-8 xl:text-xl">
          Thank you for accepting the quote. Add or edit customer details here.
        </p>
        {!isEditing && (
          <button
            className="rounded-md border border-transparent bg-primaryColor px-2 py-1 text-center text-xs text-secondaryColor shadow-md transition-all hover:border-slate-800 hover:bg-slate-800 hover:text-white hover:shadow-lg focus:border-slate-800 focus:bg-slate-800 focus:text-white active:border-slate-800 active:bg-slate-800 active:text-white md:text-sm lg:text-sm"
            onClick={getPDF}
          >
            Download PDF
          </button>
        )}
      </div>
      <h2 className="mb-1 mt-3 text-base font-bold text-black md:text-lg lg:mb-2 lg:mt-5 lg:text-xl xl:mb-3 xl:mt-8 xl:text-2xl 2xl:mb-4 2xl:mt-12 2xl:text-3xl">
        Quote Details
      </h2>
      <p className="mb-1 text-xs text-black md:text-sm lg:mb-2 lg:text-base xl:mb-3 xl:text-lg 2xl:mb-4 2xl:text-xl">
        <strong>Supplier:</strong> {quote?.supplier_name}
      </p>
      <p className="border-b border-slate-600 pb-3 text-xs text-black md:text-sm lg:border-b-2 lg:pb-5 lg:text-base xl:pb-8 xl:text-lg 2xl:pb-12 2xl:text-xl">
        <strong>Total Monthly Price:</strong>{" "}
        {currencyFormat(quote?.total_price_gbp)}
      </p>
      <h2 className="mb-1 mt-3 text-base font-bold text-black md:text-sm lg:mb-2 lg:mt-5 lg:text-xl xl:mb-3 xl:mt-8 xl:text-2xl 2xl:mb-4 2xl:mt-12 2xl:text-3xl">
        Service Breakdown
      </h2>
      <div className="relative w-full overflow-x-auto">
        <table className="w-full table-auto text-left text-[0.5rem] text-gray-500 md:text-xs lg:text-sm">
          <thead className="bg-gray-100 text-[0.5rem] uppercase text-gray-700 md:text-[0.6rem] lg:text-xs">
            <tr>
              <th className={th}>Type</th>
              <th className={th}>Container</th>
              <th className={th}>Price</th>
              <th className={th}>Quantity</th>
              <th className={th}>Frequency</th>
              <th className={th}>Monthly Price</th>
              <th className={th}>Excess Weight Charge</th>
              <th className={th}>Weight Restriction</th>
            </tr>
          </thead>
          <tbody>
            {quote?.pricing_breakdown?.map((breakdown) => (
              <tr
                key={`${breakdown.type}.${breakdown.container}`}
                className="border-b bg-white"
              >
                <td className={td}>{wasteTypeNameLookup[breakdown.type]}</td>
                <td className={td}>
                  {containerTypeNameLookup[breakdown.container]}
                </td>
                <td className={td}>{currencyFormat(breakdown.price_gbp)}</td>
                <td className={td}>{breakdown.quantity}</td>
                <td className={td}>
                  {+breakdown.frequency >= 4.33
                    ? weeklyFrequencyLookup[breakdown.frequency]
                    : frequencyLookup[breakdown.frequency]}
                </td>
                <td className={td}>
                  {currencyFormat(breakdown.total_price_gbp)}
                </td>
                <td className={td}>
                  {breakdown.excess_weight_charge_gbp
                    ? currencyFormat(breakdown.excess_weight_charge_gbp)
                    : "None"}
                </td>
                <td className={td}>
                  {breakdown.weight_restriction_kilos ?? "None"}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <h2 className="mb-1 mt-3 text-base font-bold text-black md:text-sm lg:mb-2 lg:mt-5 lg:text-xl xl:mb-3 xl:mt-8 xl:text-2xl 2xl:mb-4 2xl:mt-12 2xl:text-3xl">
          Customer Details
        </h2>
        {isEditing ? (
          <>
            <p className="mb-3 text-xs text-black lg:mb-6 lg:text-lg">
              Please add customer details and address so we can generate an
              invoice.
            </p>
            <form onSubmit={handleSubmit} noValidate className="px-2">
              <div className="relative mb-1 lg:mb-2">
                <input
                  type="text"
                  id="name"
                  className={`peer appearance-none border-2 shadow ${formValidation.name ? "border-successColor ring-successColor" : formValidation.name === false && "border-errorColor ring-errorColor"} h-8 w-full rounded px-2 py-1 text-xs leading-tight text-gray-700 placeholder:text-transparent focus:outline-none focus:ring lg:h-12 lg:px-3 lg:py-2 lg:text-base`}
                  name="name"
                  value={formData.name || ""}
                  onChange={handleNameChange}
                  onBlur={validateName}
                  required
                  placeholder="Name"
                />
                <label
                  htmlFor="name"
                  className="absolute left-0 top-2 ml-2 -translate-y-4 bg-white px-1 text-xs text-gray-500 duration-100 ease-linear peer-placeholder-shown:translate-y-0 peer-focus:ml-2 peer-focus:-translate-y-4 peer-focus:px-1 lg:top-2.5 lg:-translate-y-6 lg:text-base lg:peer-focus:-translate-y-6"
                >
                  Name
                </label>
                <p
                  className={`text-xs italic text-errorColor lg:text-sm ${formValidation.name !== false && "invisible"}`}
                >
                  Please provide a valid name.
                </p>
              </div>
              <div className="relative mb-3.5 lg:mb-7">
                <input
                  type="text"
                  id="company_name"
                  name="company_name"
                  className="peer h-8 w-full appearance-none rounded border-2 px-2 py-1 text-xs leading-tight text-gray-700 shadow placeholder:text-transparent focus:outline-none focus:ring lg:h-12 lg:px-3 lg:py-2 lg:text-base"
                  value={formData.company_name}
                  onChange={handleChange}
                  placeholder="Company name"
                />
                <label
                  htmlFor="company_name"
                  className="absolute left-0 top-2 ml-2 -translate-y-4 bg-white px-1 text-xs text-gray-500 duration-100 ease-linear peer-placeholder-shown:translate-y-0 peer-focus:ml-2 peer-focus:-translate-y-4 peer-focus:px-1 lg:top-2.5 lg:-translate-y-6 lg:text-base lg:peer-focus:-translate-y-6"
                >
                  Company Name (optional)
                </label>
              </div>
              <div className="relative mb-1 lg:mb-2">
                <input
                  type="email"
                  id="email"
                  name="email"
                  className={`peer appearance-none border-2 shadow ${formValidation.email ? "border-successColor ring-successColor" : formValidation.email === false && "border-errorColor ring-errorColor"} h-8 w-full rounded px-2 py-1 text-xs leading-tight text-gray-700 placeholder:text-transparent focus:outline-none focus:ring lg:h-12 lg:px-3 lg:py-2 lg:text-base`}
                  value={formData.email}
                  onChange={handleEmailChange}
                  placeholder="Email"
                  onBlur={validateEmail}
                  required
                />
                <label
                  htmlFor="email"
                  className="absolute left-0 top-2 ml-2 -translate-y-4 bg-white px-1 text-xs text-gray-500 duration-100 ease-linear peer-placeholder-shown:translate-y-0 peer-focus:ml-2 peer-focus:-translate-y-4 peer-focus:px-1 lg:top-2.5 lg:-translate-y-6 lg:text-base lg:peer-focus:-translate-y-6"
                >
                  Email
                </label>
                <p
                  className={`text-xs italic text-errorColor lg:text-sm ${formValidation.email !== false && "invisible"}`}
                >
                  Please provide a valid email.
                </p>
              </div>
              <div className="relative mb-3.5 lg:mb-7">
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  className="peer h-8 w-full appearance-none rounded border-2 px-2 py-1 text-xs leading-tight text-gray-700 shadow placeholder:text-transparent focus:outline-none focus:ring lg:h-12 lg:px-3 lg:py-2 lg:text-base"
                  value={formData.phone}
                  onChange={handleChange}
                  placeholder="Phone"
                />
                <label
                  htmlFor="phone"
                  className="absolute left-0 top-2 ml-2 -translate-y-4 bg-white px-1 text-xs text-gray-500 duration-100 ease-linear peer-placeholder-shown:translate-y-0 peer-focus:ml-2 peer-focus:-translate-y-4 peer-focus:px-1 lg:top-2.5 lg:-translate-y-6 lg:text-base lg:peer-focus:-translate-y-6"
                >
                  Phone (optional)
                </label>
              </div>
              <div className="relative mb-1 lg:mb-2">
                <input
                  type="text"
                  id="address_line_1"
                  className={`peer appearance-none border-2 shadow ${formValidation.address_line_1 ? "border-successColor ring-successColor" : formValidation.address_line_1 === false && "border-errorColor ring-errorColor"} h-8 w-full rounded px-2 py-1 text-xs leading-tight text-gray-700 focus:outline-none focus:ring lg:h-12 lg:px-3 lg:py-2 lg:text-base`}
                  name="address_line_1"
                  ref={inputRef}
                  value={formData.address_line_1 || ""}
                  onChange={handleAddressLine1Change}
                  onBlur={validateAddressLine1}
                  required
                />
                <label
                  htmlFor="address_line_1"
                  className="absolute left-0 top-2 ml-2 -translate-y-4 bg-white px-1 text-xs text-gray-500 duration-100 ease-linear lg:top-2.5 lg:-translate-y-6 lg:text-base"
                >
                  Address Line 1
                </label>
                <p
                  className={`text-xs italic text-errorColor lg:text-sm ${formValidation.address_line_1 !== false && "invisible"}`}
                >
                  Please provide a valid address.
                </p>
              </div>
              <div className="relative mb-3.5 lg:mb-7">
                <input
                  type="text"
                  id="address_line_2"
                  className="peer h-8 w-full appearance-none rounded border-2 px-2 py-1 text-xs leading-tight text-gray-700 shadow placeholder:text-transparent focus:outline-none focus:ring lg:h-12 lg:px-3 lg:py-2 lg:text-base"
                  name="address_line_2"
                  value={formData.address_line_2 || ""}
                  onChange={handleChange}
                  placeholder="Address Line 2"
                />
                <label
                  htmlFor="address_line_2"
                  className="absolute left-0 top-2 ml-2 -translate-y-4 bg-white px-1 text-xs text-gray-500 duration-100 ease-linear peer-placeholder-shown:translate-y-0 peer-focus:ml-2 peer-focus:-translate-y-4 peer-focus:px-1 lg:top-2.5 lg:-translate-y-6 lg:text-base lg:peer-focus:-translate-y-6"
                >
                  Address Line 2 (optional)
                </label>
              </div>
              <div className="relative mb-1 lg:mb-2">
                <input
                  type="text"
                  id="town"
                  className={`peer appearance-none border-2 shadow ${formValidation.town ? "border-successColor ring-successColor" : formValidation.town === false && "border-errorColor ring-errorColor"} h-8 w-full rounded px-2 py-1 text-xs leading-tight text-gray-700 placeholder:text-transparent focus:outline-none focus:ring lg:h-12 lg:px-3 lg:py-2 lg:text-base`}
                  name="town"
                  value={formData.town || ""}
                  onChange={handleTownChange}
                  onBlur={validateTown}
                  required
                  placeholder="Town"
                />
                <label
                  htmlFor="town"
                  className="absolute left-0 top-2 ml-2 -translate-y-4 bg-white px-1 text-xs text-gray-500 duration-100 ease-linear peer-placeholder-shown:translate-y-0 peer-focus:ml-2 peer-focus:-translate-y-4 peer-focus:px-1 lg:top-2.5 lg:-translate-y-6 lg:text-base lg:peer-focus:-translate-y-6"
                >
                  Town
                </label>
                <p
                  className={`text-xs italic text-errorColor lg:text-sm ${formValidation.town !== false && "invisible"}`}
                >
                  Please provide a valid town.
                </p>
              </div>
              <div className="relative mb-1 lg:mb-2">
                <input
                  type="text"
                  id="county"
                  className={`peer appearance-none border-2 shadow ${formValidation.county ? "border-successColor ring-successColor" : formValidation.county === false && "border-errorColor ring-errorColor"} h-8 w-full rounded px-2 py-1 text-xs leading-tight text-gray-700 placeholder:text-transparent focus:outline-none focus:ring lg:h-12 lg:px-3 lg:py-2 lg:text-base`}
                  name="county"
                  value={formData.county || ""}
                  onChange={handleCountyChange}
                  onBlur={validateCounty}
                  required
                  placeholder="County"
                />
                <label
                  htmlFor="county"
                  className="absolute left-0 top-2 ml-2 -translate-y-4 bg-white px-1 text-xs text-gray-500 duration-100 ease-linear peer-placeholder-shown:translate-y-0 peer-focus:ml-2 peer-focus:-translate-y-4 peer-focus:px-1 lg:top-2.5 lg:-translate-y-6 lg:text-base lg:peer-focus:-translate-y-6"
                >
                  County
                </label>
                <p
                  className={`text-xs italic text-errorColor lg:text-sm ${formValidation.county !== false && "invisible"}`}
                >
                  Please provide a valid county.
                </p>
              </div>
              <div className="relative mb-1 lg:mb-2">
                <input
                  type="text"
                  id="postcode"
                  name="postcode"
                  className={`peer appearance-none border-2 shadow ${formValidation.postcode ? "border-successColor ring-successColor" : formValidation.postcode === false && "border-errorColor ring-errorColor"} h-8 w-full rounded px-2 py-1 text-xs leading-tight text-gray-700 placeholder:text-transparent focus:outline-none focus:ring lg:h-12 lg:px-3 lg:py-2 lg:text-base`}
                  value={formData.postcode}
                  onChange={handlePostcodeChange}
                  placeholder="Postcode"
                  onBlur={validatePostcode}
                  required
                />
                <label
                  htmlFor="postcode"
                  className="absolute left-0 top-2 ml-2 -translate-y-4 bg-white px-1 text-xs text-gray-500 duration-100 ease-linear peer-placeholder-shown:translate-y-0 peer-focus:ml-2 peer-focus:-translate-y-4 peer-focus:px-1 lg:top-2.5 lg:-translate-y-6 lg:text-base lg:peer-focus:-translate-y-6"
                >
                  Postcode
                </label>
                <p
                  className={`text-xs italic text-errorColor lg:text-sm ${formValidation.postcode !== false && "invisible"}`}
                >
                  Please provide a valid postcode.
                </p>
              </div>
              <div className="flex justify-center">
                <button
                  className="rounded-md border border-transparent bg-secondaryColor px-2 py-1 text-center text-xs text-primaryColor shadow-sm transition-all hover:border-slate-800 hover:bg-slate-800 hover:text-white hover:shadow-lg focus:border-slate-800 focus:bg-slate-800 focus:text-white active:border-slate-800 active:bg-slate-800 active:text-white lg:px-4 lg:py-2 lg:text-lg"
                  type="button"
                  onClick={resetForm}
                >
                  Cancel
                </button>
                <button
                  className="ml-16 rounded-md border border-transparent bg-primaryColor px-2 py-1 text-center text-xs text-secondaryColor shadow-md transition-all hover:bg-slate-700 hover:shadow-lg focus:bg-slate-700 focus:shadow-none active:bg-slate-700 active:shadow-none lg:px-4 lg:py-2 lg:text-lg"
                  type="submit"
                >
                  Save
                </button>
              </div>
            </form>
          </>
        ) : (
          <div className="relative w-full overflow-x-auto">
            <table className="w-full table-auto text-left text-[0.5rem] text-gray-500 md:text-xs lg:text-sm">
              <thead className="bg-gray-100 text-[0.5rem] uppercase text-gray-700 md:text-[0.6rem] lg:text-xs">
                <tr>
                  <th className={th}>Name</th>
                  <th className={th}>Comapany Name</th>
                  <th className={th}>Email</th>
                  <th className={th}>Phone</th>
                  <th className={th}>Address</th>
                </tr>
              </thead>
              <tbody>
                <tr className="border-b bg-white">
                  <td className={td}>{quote?.customer_details?.name}</td>
                  <td className={td}>
                    {quote?.customer_details?.company_name || "Not provided"}
                  </td>
                  <td className={td}>{quote?.customer_details?.email}</td>
                  <td className={td}>
                    {quote?.customer_details?.phone || "Not provided"}
                  </td>
                  <td className={td}>
                    {singleLineAddress(quote?.customer_details)}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default QuoteDetailsInternal;
