import React, { useState } from "react";
import "./index.css";
import { useGlobalContext } from "../../GlobalContext";
import { addCompanyUser } from "../../api/company";
import ErrorMessage from "../ErrorMessage";

const AddCompanyUser = ({ closeModalCb }) => {
  const [error, setError] = useState("");
  const { state } = useGlobalContext();
  const [formData, setFormData] = useState({
    email: "",
    company_role: "reader",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => {
      const updatedData = {
        ...prevData,
        [name]: value,
      };

      return updatedData;
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const create = async () => {
      try {
        await addCompanyUser(
          state.selectedCompany,
          formData.email,
          formData.company_role,
        );
        closeModalCb();
      } catch (error) {
        setError(error.message);
      }
    };

    create();
  };

  return (
    <div className="add-company-user-wrapper">
      <h2>Add Company User</h2>
      {error && (
        <ErrorMessage>
          <p>{error}</p>
        </ErrorMessage>
      )}
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Email Address</label>
          <input
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Company Role</label>
          <select
            type="text"
            id="company_role"
            name="company_role"
            value={formData.company_role}
            onChange={handleChange}
            required
          >
            <option value="admin">Admin</option>
            <option value="writer">Writer</option>
            <option value="reader">Reader</option>
          </select>
        </div>
        <div className="form-actions">
          <button type="submit">Add</button>
          <button type="button" onClick={closeModalCb}>
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

AddCompanyUser.propTypes = {};

export default AddCompanyUser;
