import React, { useState } from "react";
import { currencyFormat } from "../../lib/formatters";
import PropTypes from "prop-types";
import QuoteSelectionWasteService from "../QouteSelectionWasteService";
import { MdEdit } from "react-icons/md";

const QuoteOption = ({
  quote,
  onQuoteSelection,
  frequencyLookup,
  wasteTypeNameLookup,
}) => {
  const [detailsVisible, setDetailsVisible] = useState(false);

  return (
    <div className="w-full rounded bg-slate-200 p-4 shadow-lg">
      <p className="text-md border-b-2 border-slate-600 p-4 text-gray-900 xl:text-lg 2xl:text-xl">
        {quote.supplier_uuid}
      </p>
      <div className="flex justify-between p-4">
        <p className="text-black-900 text-xl xl:text-2xl 2xl:text-3xl">
          <b className="text-3xl font-bold xl:text-4xl 2xl:text-5xl">
            {currencyFormat(quote.price_gbp)}
          </b>{" "}
          a month
        </p>
        <div className="flex flex-col gap-3 xl:gap-4 2xl:gap-5">
          <button
            className="text-md h-12 w-32 rounded-md border border-transparent bg-primaryColor px-4 py-2 text-center text-secondaryColor shadow-md transition-all hover:bg-slate-700 hover:shadow-lg focus:bg-slate-700 focus:shadow-none active:bg-slate-700 active:shadow-none xl:h-14 xl:w-40 xl:px-6 xl:py-3 xl:text-xl 2xl:h-16 2xl:w-48 2xl:px-8 2xl:py-4 2xl:text-2xl"
            onClick={() => onQuoteSelection(quote)}
          >
            Select
          </button>
          <button
            className="text-md text-black-700 hover:text-black-400 underline xl:text-lg 2xl:text-xl"
            onClick={() => setDetailsVisible(!detailsVisible)}
          >
            More info
          </button>
        </div>
      </div>
      {detailsVisible && (
        <div className="w-full border-t-2 border-slate-600 p-4">
          <h2 className="text-black-900 text-md mb-4 xl:text-lg 2xl:text-xl">
            Service Breakdown
          </h2>
          <div className="relative w-full overflow-x-auto">
            <table className="w-full table-auto text-left text-sm text-gray-500">
              <thead className="bg-gray-100 text-xs uppercase text-gray-700">
                <tr>
                  <th className="px-3 py-2 xl:px-6 xl:py-3">Type</th>
                  <th className="px-3 py-2 xl:px-6 xl:py-3">Container</th>
                  <th className="px-3 py-2 xl:px-6 xl:py-3">Price</th>
                  <th className="px-3 py-2 xl:px-6 xl:py-3">Quantity</th>
                  <th className="px-3 py-2 xl:px-6 xl:py-3">Frequency</th>
                  <th className="px-3 py-2 xl:px-6 xl:py-3">Monthly Price</th>
                  <th className="px-3 py-2 xl:px-6 xl:py-3">
                    Excess Weight Charge
                  </th>
                  <th className="px-3 py-2 xl:px-6 xl:py-3">
                    Weight Restriction
                  </th>
                </tr>
              </thead>
              <tbody>
                {quote.price_breakdown.map((breakdown) => (
                  <tr
                    key={`${breakdown.type}.${breakdown.container}`}
                    className="border-b bg-white"
                  >
                    <td className="xl:text-md px-3 py-2 text-sm text-gray-900 xl:px-6 xl:py-3">
                      {wasteTypeNameLookup[breakdown.type]}
                    </td>
                    <td className="xl:text-md px-3 py-2 text-sm text-gray-900 xl:px-6 xl:py-3">
                      {breakdown.container}
                    </td>
                    <td className="xl:text-md px-3 py-2 text-sm text-gray-900 xl:px-6 xl:py-3">
                      {currencyFormat(breakdown.price_gbp)}
                    </td>
                    <td className="xl:text-md px-3 py-2 text-sm text-gray-900 xl:px-6 xl:py-3">
                      {breakdown.quantity}
                    </td>
                    <td className="xl:text-md px-3 py-2 text-sm text-gray-900 xl:px-6 xl:py-3">
                      {frequencyLookup[breakdown.frequency]}
                    </td>
                    <td className="xl:text-md px-3 py-2 text-sm text-gray-900 xl:px-6 xl:py-3">
                      {currencyFormat(breakdown.total_price_gbp)}
                    </td>
                    <td className="xl:text-md px-3 py-2 text-sm text-gray-900 xl:px-6 xl:py-3">
                      {breakdown.excess_weight_charge_gbp
                        ? currencyFormat(breakdown.excess_weight_charge_gbp)
                        : "None"}
                    </td>
                    <td className="xl:text-md px-3 py-2 text-sm text-gray-900 xl:px-6 xl:py-3">
                      {breakdown.weight_restriction_kilos ?? "None"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

const QuoteSelection = ({
  customerDetails,
  quotes,
  services,
  modifyServices,
  onQuoteSelection,
  frequencyLookup,
  wasteTypeNameLookup,
}) => {
  return (
    <>
      <div className="w-full flex-1 bg-slate-400 p-10">
        <h2 className="mb-6 block text-2xl font-bold text-white xl:mb-10 xl:text-3xl 2xl:text-4xl">
          Your details
        </h2>
        <div className="w-fit rounded bg-white p-4">
          <div className="flex justify-between">
            <p className="xl:text-md w-full border-b-2 px-2 py-4 text-sm text-gray-700 2xl:text-lg">
              Postcode :&nbsp;
              <span className="text-black-900 font-bold">
                {customerDetails.postcode}
              </span>
            </p>
            <button onClick={() => modifyServices("POSTCODE_COLLECTION")}>
              <MdEdit className="text-primaryColor" />
            </button>
          </div>
          <div className="flex justify-between">
            <p className="xl:text-md w-full border-b-2 px-2 py-4 text-sm text-gray-700 2xl:text-lg">
              Email :&nbsp;
              <span className="text-black-900 font-bold">
                {customerDetails.email}
              </span>
            </p>
            <button onClick={() => modifyServices("EMAIL_COLLECTION")}>
              <MdEdit className="text-primaryColor" />
            </button>
          </div>
          <div className="flex justify-between px-2 py-4">
            <p className="xl:text-md text-sm text-gray-700 2xl:text-lg">
              Your services:
            </p>
            <button onClick={() => modifyServices("SERVICE_SELECTION")}>
              <MdEdit className="text-primaryColor" />
            </button>
          </div>
          <div className="flex flex-wrap gap-4">
            {services.map((service, index) => (
              <QuoteSelectionWasteService
                selectedService={service}
                key={index}
                frequencyLookup={frequencyLookup}
                wasteTypeNameLookup={wasteTypeNameLookup}
              />
            ))}
          </div>
        </div>
      </div>
      {quotes.length === 0 ? (
        <p className="text-black-700 mb-3 text-center text-xl xl:text-2xl 2xl:text-3xl">
          Sorry there are no quotes available at this time.
        </p>
      ) : (
        <div className="w-full p-10">
          <h2 className="text-black-900 mb-6 block text-2xl font-bold xl:mb-10 xl:text-3xl 2xl:text-4xl">
            Your quotes
          </h2>
          <div className="flex w-full flex-col gap-4">
            {quotes.map((quote) => (
              <QuoteOption
                key={quote.quote_uuid}
                quote={quote}
                onQuoteSelection={onQuoteSelection}
                frequencyLookup={frequencyLookup}
                wasteTypeNameLookup={wasteTypeNameLookup}
              />
            ))}
          </div>
        </div>
      )}
    </>
  );
};

QuoteSelection.propTypes = {
  customerDetails: PropTypes.object.isRequired,
  quotes: PropTypes.arrayOf(PropTypes.object).isRequired,
  services: PropTypes.arrayOf(PropTypes.object).isRequired,
  modifyServices: PropTypes.func.isRequired,
  onQuoteSelection: PropTypes.func.isRequired,
  frequencyLookup: PropTypes.object.isRequired,
  wasteTypeNameLookup: PropTypes.object.isRequired,
};

QuoteOption.propTypes = {
  quote: PropTypes.object.isRequired,
  onQuoteSelection: PropTypes.func.isRequired,
  frequencyLookup: PropTypes.object.isRequired,
  wasteTypeNameLookup: PropTypes.object.isRequired,
};

export default QuoteSelection;
