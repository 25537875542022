import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Modal from "../Modal";

const CompanyOption = ({ name, onClick, selected }) => {
  return (
    <div
      className={`w-full rounded p-2 text-center xl:p-3 2xl:p-4 ${selected ? "bg-blue-600 text-white" : "text-black-800 bg-gray-200"}`}
      onClick={onClick}
    >
      <p className="text-md xl:text-lg 2xl:text-xl">{name}</p>
    </div>
  );
};

const ChangeCompanyModal = ({
  isOpen,
  onCancel,
  onCompanySelected,
  companies,
}) => {
  const [selectedCompany, setSelectedCompany] = useState(null);

  useEffect(() => {
    setSelectedCompany(null);
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen}>
      <div className="flex min-h-[35vh] min-w-[35vw] flex-col items-center justify-center gap-6 p-16 xl:gap-8 xl:p-20 2xl:gap-10 2xl:p-24">
        <h2 className="text-black-900 text-xl font-bold xl:text-2xl 2xl:text-3xl">
          Your Companies
        </h2>
        <div className="flex w-full flex-col gap-2 xl:gap-3 2xl:gap-4">
          {companies.map((company) => (
            <CompanyOption
              key={company.uuid}
              uuid={company.uuid}
              name={company.name}
              onClick={() => setSelectedCompany(company)}
              selected={
                selectedCompany && selectedCompany.uuid === company.uuid
              }
            />
          ))}
        </div>
        <div className="flex gap-10 xl:gap-6 2xl:gap-8">
          <button
            className="text-md rounded-md border border-transparent bg-secondaryColor px-4 py-2 text-center text-primaryColor shadow-sm transition-all hover:border-slate-800 hover:bg-slate-800 hover:text-white hover:shadow-lg active:border-slate-800 active:bg-slate-800 active:text-white xl:text-lg 2xl:text-xl"
            onClick={onCancel}
          >
            Cancel
          </button>
          <button
            className="text-md rounded-md border border-transparent bg-primaryColor px-4 py-2 text-center text-secondaryColor shadow-md transition-all hover:bg-slate-700 hover:shadow-lg active:bg-slate-700 active:shadow-none xl:text-lg 2xl:text-xl"
            onClick={() => {
              if (!selectedCompany) {
                return;
              }
              onCompanySelected(selectedCompany.uuid);
              setSelectedCompany(null);
            }}
          >
            Select
          </button>
        </div>
      </div>
    </Modal>
  );
};

PropTypes.ChangeCompanyModal = {
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onCompanySelected: PropTypes.func.isRequired,
  companies: PropTypes.array.isRequired,
};

export default ChangeCompanyModal;
