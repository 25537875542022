import React, { useEffect, useState } from "react";
import WhitelabelWrapper from "../../components/WhitelabelWrapper";
import { Outlet, useNavigate } from "react-router-dom";
import { ImBin } from "react-icons/im";
import { FaRegAddressBook } from "react-icons/fa";
import { IoPricetagOutline } from "react-icons/io5";
import { MdOutlineMail } from "react-icons/md";

const QuoteWhitelabel = ({
  whitelabelIconLink,
  whitelabelCompanyName,
  whitelabelHeadline,
}) => {
  const [desiredServices, setDesiredServices] = useState([]);
  const [quotes, setQuotes] = useState([]);
  const [quoteSessionUUID, setQuoteSessionUUID] = useState(null);
  const [customerDetails, setCustomerDetails] = useState({
    name: "",
    company_name: "",
    email: "",
    phone_number: "",
    address_line_1: "",
    address_line_2: "",
    town: "",
    postcode: "",
    county: "",
  });
  const [desiredQuote, setDesiredQuote] = useState(null);
  const [quoteAcceptConfirm, setQuoteAcceptConfirm] = useState(false);
  const [PDFBytes, setPDFBytes] = useState(null);
  const [alternativeQuotes, setAlternativeQuotes] = useState([]);

  const pages = [
    { page: "postcode", title: "Address", icon: <FaRegAddressBook /> },
    { page: "waste-services", title: "Services", icon: <ImBin /> },
    { page: "email", title: "Email", icon: <MdOutlineMail /> },
    { page: "quote-selection", title: "Quotes", icon: <IoPricetagOutline /> },
  ];

  const navigate = useNavigate();
  useEffect(() => {
    navigate("/quote/postcode");
  }, []);

  return (
    <WhitelabelWrapper
      iconLink={whitelabelIconLink}
      whitelabelCompanyName={whitelabelCompanyName}
      whitelabelHeadline={whitelabelHeadline}
    >
      <div className="flex size-full flex-col">
        <Outlet
          context={[
            setCustomerDetails,
            customerDetails,
            desiredServices,
            setDesiredServices,
            setQuotes,
            setQuoteSessionUUID,
            quotes,
            setDesiredQuote,
            setQuoteAcceptConfirm,
            desiredQuote,
            quoteAcceptConfirm,
            quoteSessionUUID,
            pages,
            PDFBytes,
            setPDFBytes,
            alternativeQuotes,
            setAlternativeQuotes,
          ]}
        />
      </div>
    </WhitelabelWrapper>
  );
};

export default QuoteWhitelabel;
