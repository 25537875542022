import React from "react";
import PropTypes from "prop-types";
import "./index.css";

const SideNav = ({ links }) => {
  return (
    <nav className="h-full w-48 bg-secondaryColor px-2 py-4 xl:w-56 xl:px-3 xl:py-6 2xl:w-64 2xl:px-4 2xl:py-8">
      <ul>
        {links.map((link, index) => (
          <li
            key={link.id}
            className={`border-b-2 border-gray-300 ${index === 0 && "border-t-2"} py-3 xl:py-5 2xl:py-8`}
          >
            <a
              href={link.url}
              className="text-md uppercase text-gray-700 xl:text-lg 2xl:text-xl"
            >
              {link.name}
            </a>
          </li>
        ))}
      </ul>
    </nav>
  );
};

SideNav.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default SideNav;
