import PropTypes from "prop-types";
import React from "react";
import { GiGrass } from "react-icons/gi";
import DMR from "../../assets/waste_icons/DMR.png";
import GEN from "../../assets/waste_icons/GEN.png";
import GLS from "../../assets/waste_icons/GLS.png";
import CRD from "../../assets/waste_icons/CRD.png";
import FOD from "../../assets/waste_icons/FOD.png";
import MandV from "../../assets/waste_icons/M&V.png";
import WEEE from "../../assets/waste_icons/WEEE.png";

const WasteIcon = ({ wasteType }) => {
  const wasteTypeIconLookup = {
    CRD: <img src={CRD} className="size-full" />,
    DMR: <img src={DMR} className="size-full" />,
    FOD: <img src={FOD} className="size-full" />,
    GEN: <img src={GEN} className="size-full" />,
    GLS: <img src={GLS} className="size-full" />,
    WEEE: <img src={WEEE} className="size-full" />,
    GAR: <GiGrass className="size-full" />,
    "M&V": <img src={MandV} className="size-full" />,
  };
  return <>{wasteTypeIconLookup[wasteType]}</>;
};
WasteIcon.propTypes = {
  wasteType: PropTypes.string.isRequired,
};
export default WasteIcon;
