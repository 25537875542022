import React, { useState, useEffect } from "react";
import { wasteTypes } from "../../lib/lookups";
import { MdClose } from "react-icons/md";
import { currencyFormat } from "../../lib/formatters";
import { MdModeEdit, MdDeleteForever } from "react-icons/md";
import { FaCheck } from "react-icons/fa6";
import {
  createWasteContainerInfo,
  deleteWasteContainerInfo,
  patchWasteContainerInfo,
} from "../../api/quote";
import { useGlobalContext } from "../../GlobalContext";
import { toast } from "react-toastify";

const WasteTypeWeightCharge = ({
  wasteType,
  charge,
  loadWasteContainerInfo,
}) => {
  const { state } = useGlobalContext();

  const [isEditing, setIsEditing] = useState(false);
  const [chargeInput, setChargeInput] = useState(null);

  useEffect(() => {
    setChargeInput(charge);
  }, [charge]);

  const handleChange = (e) => {
    const value = parseFloat(e.target.value);
    const valueString = value.toString();
    const decimalPlaces = valueString.includes(".")
      ? valueString.split(".")[1].length
      : 0;

    if (decimalPlaces <= 2) {
      setChargeInput(value);
    }
  };

  const handleSubmit = async () => {
    try {
      if (!charge && chargeInput) {
        await createWasteContainerInfo(state.selectedCompany, {
          waste_type: wasteType.value,
          excess_weight_charge_gbp: chargeInput,
        });
        toast.success("Excess weight charge added.");
      } else if (charge && charge !== chargeInput) {
        await patchWasteContainerInfo(state.selectedCompany, {
          waste_type: wasteType.value,
          excess_weight_charge_gbp: chargeInput,
        });
        toast.success("Excess weight charge updated.");
      }
      setIsEditing(false);
      loadWasteContainerInfo();
    } catch (error) {
      console.error(error);
      toast.error("Failed to update excess weight charge.");
    }
  };
  const handleDelete = async () => {
    try {
      if (charge) {
        await deleteWasteContainerInfo(state.selectedCompany, wasteType.value);
        toast.success("Excess weight charge deleted.");
        loadWasteContainerInfo();
      }
    } catch (error) {
      console.error(error);
      toast.error("Failed to delete excess weight charge.");
    }
  };

  return (
    <tr className="h-20">
      <td className="w-2/5 whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900">
        {wasteType.name}
      </td>
      <td className="w-2/5 whitespace-nowrap px-6 py-4 text-sm text-gray-800">
        {isEditing ? (
          <input
            type="number"
            id="quantity"
            name="quantity"
            className="h-full border p-2"
            value={chargeInput}
            onChange={handleChange}
            step=".01"
            required
          />
        ) : (
          <p>{charge && currencyFormat(charge)}</p>
        )}
      </td>

      <td className="w-1/5 space-x-3 whitespace-nowrap px-6 py-4 text-base">
        {isEditing ? (
          <button onClick={handleSubmit}>
            <FaCheck className="cursor-pointer text-lg text-green-500" />
          </button>
        ) : (
          <button onClick={() => setIsEditing(true)}>
            <MdModeEdit className="cursor-pointer text-lg text-blue-500" />
          </button>
        )}
        <button onClick={handleDelete}>
          <MdDeleteForever className="cursor-pointer text-lg text-red-500" />
        </button>
      </td>
    </tr>
  );
};
const ManageExcessWeightCharges = ({
  onClose,
  excessWeightChargesMap,
  loadWasteContainerInfo,
}) => {
  return (
    <div className="h-[60vh] w-[60vw] p-6">
      <div className="flex justify-end">
        <button className="ml-auto" onClick={onClose}>
          <MdClose className="text-xl" />
        </button>
      </div>
      <h1 className="mb-8 mt-2 text-2xl font-bold text-black">
        Excess Weight Charges
      </h1>
      <p>Configure the excess weight charges for your company's price list.</p>

      <div className="mt-4">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
                Waste Type
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
                Excess Weight Charge
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
            {wasteTypes.map((wasteType, index) => (
              <WasteTypeWeightCharge
                wasteType={wasteType}
                key={index}
                charge={excessWeightChargesMap[wasteType.value]}
                loadWasteContainerInfo={loadWasteContainerInfo}
              />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ManageExcessWeightCharges;
