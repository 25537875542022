import React, { useEffect, useState } from "react";
import PageWrapper from "../../components/PageWrapper";
import PaginationControls from "../../components/PaginationControls";
import { fetchCompanyLostLeads } from "../../api/quote";
import { useGlobalContext } from "../../GlobalContext";
import {
  currencyFormat,
  relativeTimeFormatter,
  singleLineAddress,
} from "../../lib/formatters";
import { DayPicker } from "react-day-picker";
import "react-day-picker/style.css";
import Modal from "../../components/Modal";
import {
  containerTypeNameLookup,
  daysLookup,
  frequencyLookup,
  quoteSessionStageLookup,
  wasteTypeNameLookup,
  weeklyFrequencyLookup,
} from "../../lib/lookups";
import { getCompanyPublicDetails } from "../../api/company";

const LostLeadDetails = ({ lostLead }) => {
  const [detailsVisible, setDetailsVisible] = useState(false);

  const th = "px-3 py-2";
  const td = "px-3 py-2 text-sm text-gray-900";

  return (
    <div
      className={`${lostLead.selected_quote?.alternative_quote ? "bg-green-200" : "bg-slate-200"} w-full rounded p-4 shadow-lg`}
    >
      <div className="flex justify-between p-4">
        <div className="flex flex-col gap-3 xl:gap-4">
          {lostLead.selected_quote && (
            <p className="text-xl text-black xl:text-2xl">
              <b className="text-2xl font-bold xl:text-3xl">
                {currencyFormat(lostLead?.selected_quote?.total_price_gbp)}
              </b>{" "}
              a month
            </p>
          )}
          <p className="text-xl text-black xl:text-2xl">
            <b className="text-2xl font-bold xl:text-3xl">Stage:</b>{" "}
            {quoteSessionStageLookup[lostLead.quoting_session?.stage] || ""}
          </p>
          <p>
            {lostLead?.quoting_session?.customer_details?.name &&
              lostLead?.quoting_session?.customer_details?.name + ", "}
            {lostLead?.quoting_session?.customer_details?.email &&
              lostLead?.quoting_session?.customer_details?.email + ", "}
            {relativeTimeFormatter(lostLead?.quoting_session?.created_at)}
          </p>
        </div>
        <div className="flex flex-col gap-3 xl:gap-4">
          <button
            className="text-base text-black underline hover:text-black"
            onClick={() => setDetailsVisible(!detailsVisible)}
          >
            {detailsVisible ? "Less info" : "More info"}
          </button>
        </div>
      </div>
      {detailsVisible && (
        <>
          <div className="w-full border-t-2 border-slate-600 p-4">
            <h2 className="mb-4 text-base text-black xl:text-lg">
              Customer Details
            </h2>

            <div className="relative w-full overflow-x-auto">
              <table className="w-full table-auto text-left text-sm text-gray-500">
                <thead className="bg-gray-100 text-xs uppercase text-gray-700">
                  <tr>
                    <th className={th}>Name</th>
                    <th className={th}>Company Name</th>
                    <th className={th}>Email</th>
                    <th className={th}>Phone</th>
                    <th className={th}>Address</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="border-b bg-white">
                    <td className={td}>
                      {lostLead?.quoting_session?.customer_details?.name}
                    </td>
                    <td className={td}>
                      {lostLead?.quoting_session?.customer_details
                        ?.company_name || "Not provided"}
                    </td>
                    <td className={td}>
                      {lostLead?.quoting_session?.customer_details?.email}
                    </td>
                    <td className={td}>
                      {lostLead?.quoting_session?.customer_details?.phone ||
                        "Not provided"}
                    </td>
                    <td className={td}>
                      {singleLineAddress(
                        lostLead?.quoting_session?.customer_details,
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          {lostLead?.quoting_session?.requested_services &&
            lostLead?.quoting_session?.requested_services.length > 0 && (
              <div className="w-full border-t-2 border-slate-600 p-4">
                <h2 className="mb-4 text-base text-black xl:text-lg">
                  Selected Services
                </h2>
                <div className="relative w-full overflow-x-auto">
                  <table className="w-full table-auto text-left text-sm text-gray-500">
                    <thead className="bg-gray-100 text-xs uppercase text-gray-700">
                      <tr>
                        <th className={th}>Type</th>
                        <th className={th}>Container</th>
                        <th className={th}>Quantity</th>
                        <th className={th}>Frequency</th>
                      </tr>
                    </thead>
                    <tbody>
                      {lostLead?.quoting_session?.requested_services?.map(
                        (service, index) => (
                          <tr key={index} className="border-b bg-white">
                            <td className={td}>
                              {wasteTypeNameLookup[service.waste_type]}
                            </td>
                            <td className={td}>
                              {containerTypeNameLookup[service.container_type]}
                            </td>
                            <td className={td}>{service.quantity}</td>
                            <td className={td}>
                              {+service.frequency >= 4.33
                                ? weeklyFrequencyLookup[service.frequency]
                                : frequencyLookup[service.frequency]}
                            </td>
                          </tr>
                        ),
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          {lostLead.selected_quote && (
            <div className="w-full border-t-2 border-slate-600 p-4">
              <h2 className="mb-4 text-base text-black xl:text-lg">
                Selected Quote Breakdown
              </h2>
              {lostLead.selected_quote.supplier_name && (
                <h3 className="mb-2 text-sm text-black xl:text-base">
                  Supplier: {lostLead.selected_quote.supplier_name}
                </h3>
              )}
              {lostLead.selected_quote.total_price_gbp && (
                <h3 className="mb-2 text-sm text-black xl:text-base">
                  Total Price:{" "}
                  {currencyFormat(lostLead.selected_quote.total_price_gbp)}
                </h3>
              )}
              <div className="relative w-full overflow-x-auto">
                <table className="w-full table-auto text-left text-sm text-gray-500">
                  <thead className="bg-gray-100 text-xs uppercase text-gray-700">
                    <tr>
                      <th className={th}>Type</th>
                      <th className={th}>Container</th>
                      <th className={th}>Marked Up Price</th>
                      <th className={th}>Original Price</th>
                      <th className={th}>Markup Factor</th>
                      <th className={th}>Quantity</th>
                      <th className={th}>Frequency</th>
                      <th className={th}>Monthly Price</th>
                      <th className={th}>Excess Weight Charge</th>
                      <th className={th}>Weight Restriction</th>
                    </tr>
                  </thead>
                  <tbody>
                    {lostLead?.selected_quote?.pricing_breakdown?.map(
                      (breakdown) => (
                        <tr
                          key={`${breakdown.type}.${breakdown.container}`}
                          className="border-b bg-white"
                        >
                          <td className={td}>
                            {wasteTypeNameLookup[breakdown.type]}
                          </td>
                          <td className={td}>
                            {containerTypeNameLookup[breakdown.container]}
                          </td>
                          <td className={td}>
                            {currencyFormat(breakdown.price_gbp)}
                          </td>
                          <td className={td}>
                            {breakdown.original_price > 0
                              ? currencyFormat(breakdown.original_price)
                              : ""}
                          </td>
                          <td className={td}>
                            {breakdown.markup_factor > 0 &&
                              breakdown.markup_factor}
                          </td>
                          <td className={td}>{breakdown.quantity}</td>
                          <td className={td}>
                            {+breakdown.frequency >= 4.33
                              ? weeklyFrequencyLookup[breakdown.frequency]
                              : frequencyLookup[breakdown.frequency]}
                          </td>
                          <td className={td}>
                            {currencyFormat(breakdown.total_price_gbp)}
                          </td>
                          <td className={td}>
                            {breakdown.excess_weight_charge_gbp
                              ? currencyFormat(
                                  breakdown.excess_weight_charge_gbp,
                                )
                              : "None"}
                          </td>
                          <td className={td}>
                            {breakdown.weight_restriction_kilos ?? "None"}
                          </td>
                        </tr>
                      ),
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          )}
          {lostLead.generated_quotes?.length > 0 && (
            <div className="w-full border-t-2 border-slate-600 p-4">
              <h2 className="mb-4 text-base text-black xl:text-lg">
                All Generated Quotes
              </h2>
              {lostLead.generated_quotes?.map((quote, index) => {
                return (
                  <div className="relative w-full overflow-x-auto" key={index}>
                    <h3 className="mb-2 text-sm text-black xl:text-base">
                      Quote {index + 1}
                    </h3>
                    {quote.supplier_name && (
                      <h3 className="mb-2 text-xs text-black xl:text-sm">
                        Supplier: {quote.supplier_name}
                      </h3>
                    )}
                    {quote.total_price_gbp && (
                      <h3 className="mb-2 text-sm text-black xl:text-base">
                        Total Price: {currencyFormat(quote.total_price_gbp)}
                      </h3>
                    )}
                    <table className="w-full table-auto text-left text-sm text-gray-500">
                      <thead className="bg-gray-100 text-xs uppercase text-gray-700">
                        <tr>
                          <th className={th}>Type</th>
                          <th className={th}>Container</th>
                          <th className={th}>Marked Up Price</th>
                          <th className={th}>Original Price</th>
                          <th className={th}>Markup Factor</th>
                          <th className={th}>Quantity</th>
                          <th className={th}>Frequency</th>
                          <th className={th}>Monthly Price</th>
                          <th className={th}>Excess Weight Charge</th>
                          <th className={th}>Weight Restriction</th>
                        </tr>
                      </thead>
                      <tbody>
                        {quote?.pricing_breakdown?.map((breakdown) => (
                          <tr
                            key={`${breakdown.type}.${breakdown.container}`}
                            className="border-b bg-white"
                          >
                            <td className={td}>
                              {wasteTypeNameLookup[breakdown.type]}
                            </td>
                            <td className={td}>
                              {containerTypeNameLookup[breakdown.container]}
                            </td>
                            <td className={td}>
                              {currencyFormat(breakdown.price_gbp)}
                            </td>
                            <td className={td}>
                              {breakdown.original_price > 0
                                ? currencyFormat(breakdown.original_price)
                                : ""}
                            </td>
                            <td className={td}>
                              {breakdown.markup_factor > 0 &&
                                breakdown.markup_factor}
                            </td>
                            <td className={td}>{breakdown.quantity}</td>
                            <td className={td}>
                              {+breakdown.frequency >= 4.33
                                ? weeklyFrequencyLookup[breakdown.frequency]
                                : frequencyLookup[breakdown.frequency]}
                            </td>
                            <td className={td}>
                              {currencyFormat(breakdown.total_price_gbp)}
                            </td>
                            <td className={td}>
                              {breakdown.excess_weight_charge_gbp
                                ? currencyFormat(
                                    breakdown.excess_weight_charge_gbp,
                                  )
                                : "None"}
                            </td>
                            <td className={td}>
                              {breakdown.weight_restriction_kilos ?? "None"}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                );
              })}
            </div>
          )}
        </>
      )}
    </div>
  );
};
const LostLeads = () => {
  const { state } = useGlobalContext();
  const daysAgo = (n) => {
    const d = new Date();
    d.setDate(d.getDate() - n);
    return d;
  };

  const [companyLostLeads, setCompanyLostLeads] = useState([]);
  const [filteredCompanyLostLeads, setFilteredCompanyLostLeads] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [startDateValue, setStartDateValue] = useState(30);
  const [dateRange, setDateRange] = useState({
    from: daysAgo(30),
    to: daysAgo(0),
  });
  const [showDatePickerButton, setShowDatePickerButton] = useState(false);
  const [showDatePickerModal, setShowDatePickerModal] = useState(false);

  useEffect(() => {
    const fetchLostLeads = async () => {
      try {
        const date = new Date();
        date.setDate(new Date(dateRange?.to).getDate() + 1);
        const newEndDate = date.toISOString();
        const lostLeads = await fetchCompanyLostLeads(
          state?.selectedCompany,
          new Date(dateRange?.from).toISOString(),
          newEndDate,
        );
        if (lostLeads.length === 0) {
          setCompanyLostLeads([]);
          setFilteredCompanyLostLeads([]);
          setPageCount(1);
          setPageNumber(1);
        } else {
          const nonRedactedSuppliers = [];
          lostLeads?.forEach((lostLead) => {
            if (lostLead.selected_quote?.supplier_uuid) {
              nonRedactedSuppliers.push(lostLead.selected_quote.supplier_uuid);
            }
            lostLead.generated_quotes?.forEach((quote) => {
              nonRedactedSuppliers.push(quote.supplier_uuid);
            });
          });
          const companyDetails =
            await getCompanyPublicDetails(nonRedactedSuppliers);
          const companyLookup = {};
          companyDetails?.forEach((company) => {
            companyLookup[company.uuid] = company.name;
          });

          const lostLeadsWithSupplierName = lostLeads?.map((lead) => {
            const newLead = { ...lead };
            if (newLead.selected_quote) {
              newLead.selected_quote.supplier_name =
                companyLookup[newLead.selected_quote.supplier_uuid];
            }

            const quotesWithSupplierNames = newLead.generated_quotes.map(
              (quote) => {
                const quoteWithSupplierName = { ...quote };
                quoteWithSupplierName.supplier_name =
                  companyLookup[quote.supplier_uuid];

                return quoteWithSupplierName;
              },
            );
            newLead.generated_quotes = quotesWithSupplierNames;

            return newLead;
          });
          setCompanyLostLeads(lostLeadsWithSupplierName);
          setFilteredCompanyLostLeads(lostLeadsWithSupplierName);
          setSearchTerm("");
          setPageNumber(1);
          setPageCount(Math.ceil(lostLeads.length / 15));
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchLostLeads();
  }, [dateRange, state.selectedCompany]);

  useEffect(() => {
    const lostLeads = companyLostLeads.filter((lostLead) => {
      return (
        /^\s+$/.test(searchTerm) ||
        lostLead.quoting_session?.customer_details?.name
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        lostLead.quoting_session?.customer_details?.company_name
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        lostLead.selected_quote?.supplier_name
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        lostLead.quoting_session?.customer_details?.email
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        lostLead.quoting_session?.customer_details?.address_line_1
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        lostLead.quoting_session?.customer_details?.postcode
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase())
      );
    });

    setFilteredCompanyLostLeads(lostLeads);
    setPageCount(Math.ceil(lostLeads.length / 15));
  }, [searchTerm]);

  const onSearchChange = (e) => {
    setPageNumber(1);
    setSearchTerm(e.target.value);
  };

  const handleDateChange = async (e) => {
    const date = e.target.value;
    setStartDateValue(date);
    if (date === "custom") {
      setShowDatePickerButton(true);
    } else {
      setShowDatePickerButton(false);
      setDateRange({ from: daysAgo(date), to: daysAgo(0) });
    }
  };

  return (
    <>
      <PageWrapper>
        <div className="size-full p-8 xl:p-10 2xl:p-12">
          <h1 className="my-8 block text-2xl font-bold text-black">
            Lost Leads
          </h1>
          <div className="mb-3 flex w-full items-center justify-between gap-4">
            <input
              name="search"
              id="search"
              type="text"
              placeholder="Search name, supplier, address or email"
              onChange={onSearchChange}
              className="h-8 w-1/4 appearance-none rounded border-2 px-3 py-2 text-sm leading-tight text-gray-700 shadow focus:outline-none focus:ring 2xl:h-10 2xl:text-base"
            />
            <select
              className="h-8 w-1/6 rounded border-2 px-2 py-1 text-sm leading-tight text-gray-700 shadow focus:outline-none focus:ring"
              id="time-period"
              name="time-period"
              value={startDateValue}
              onChange={handleDateChange}
            >
              {daysLookup.map((day, index) => {
                return (
                  <option key={index} value={day.value}>
                    {day.text}
                  </option>
                );
              })}
              <option value="custom">Custom</option>
            </select>
            {showDatePickerButton && (
              <button
                className="h-8 w-1/6 rounded border-2 px-2 py-1 text-sm leading-tight text-gray-700 shadow focus:outline-none focus:ring"
                onClick={() => setShowDatePickerModal(true)}
              >
                {new Date(dateRange?.from).toLocaleDateString("en-GB")} to{" "}
                {new Date(dateRange?.to).toLocaleDateString("en-GB")}
              </button>
            )}
            <PaginationControls
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
              pageCount={pageCount}
            />
          </div>
          <ul>
            {filteredCompanyLostLeads
              ?.slice((pageNumber - 1) * 15, (pageNumber - 1) * 15 + 15)
              .map((lostLead, index) => (
                <li key={index} className="mb-4">
                  <LostLeadDetails lostLead={lostLead} />
                </li>
              ))}
          </ul>
          <PaginationControls
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            pageCount={pageCount}
          />
        </div>
      </PageWrapper>
      <Modal isOpen={showDatePickerModal}>
        <div className="flex flex-col items-center gap-4 p-10">
          <DayPicker
            mode="range"
            selected={dateRange}
            onSelect={setDateRange}
          />
          <button
            className="rounded-md border border-transparent bg-primaryColor px-4 py-2 text-center text-base text-secondaryColor shadow-md transition-all hover:bg-slate-700 hover:shadow-lg focus:bg-slate-700 focus:shadow-none active:bg-slate-700 active:shadow-none"
            onClick={() => setShowDatePickerModal(false)}
          >
            Close
          </button>
        </div>
      </Modal>
    </>
  );
};

export default LostLeads;
