export const containerTypes = [
  { value: "240LTR", name: "240 Litre Wheelie Bin" },
  { value: "260LTR", name: "260 Litre Wheelie Bin" },
  { value: "360LTR", name: "360 Litre Wheelie Bin" },
  { value: "660LTR", name: "660 Litre Wheelie Bin" },
  { value: "1100LTR", name: "1100 Litre Wheelie Bin" },
  { value: "50BAGS", name: "50 Bags" },
  { value: "M&V", name: "Man & Van" },

  { value: "1280LTR", name: "1280 Litre Wheelie Bin" },
  { value: "770LTR", name: "770 Litre Wheelie Bin" },
  { value: "140LTR", name: "140 Litre Wheelie Bin" },
  { value: "6YARDFEL", name: "6 Yard Front End Loader" },
  { value: "8YARDFEL", name: "8 Yard Front End Loader" },
  { value: "10YARDFEL", name: "10 Yard Front End Loader" },
  { value: "10YARDREL", name: "10 Yard Rear End Loader" },
  { value: "12YARDREL", name: "12 Yard Rear End Loader" },
  { value: "14YARDREL", name: "14 Yard Rear End Loader" },
  { value: "20YARDRORO", name: "20 Yard Roll On Roll Off" },
  { value: "25YARDRORO", name: "25 Yard Roll On Roll Off" },
  { value: "35YARDRORO", name: "35 Yard Roll On Roll Off" },
  { value: "40YARDRORO", name: "40 Yard Roll On Roll Off" },
  { value: "2YARDSKIP", name: "2 Yard Skip" },
  { value: "3YARDSKIP", name: "3 Yard Skip" },
  { value: "4YARDSKIP", name: "4 Yard Skip" },
  { value: "5YARDSKIP", name: "5 Yard Skip" },
  { value: "6YARDSKIP", name: "6 Yard Skip" },
  { value: "8YARDSKIP", name: "8 Yard Skip" },
  { value: "10YARDSKIP", name: "10 Yard Skip" },
  { value: "12YARDSKIP", name: "12 Yard Skip" },
];

export const containerTypeNameLookup = containerTypes.reduce((acc, type) => {
  acc[type.value] = type.name;
  return acc;
}, {});

export const wasteTypes = [
  { value: "CRD", name: "Cardboard" },
  { value: "DMR", name: "Dry Mixed Recycling" },
  { value: "FOD", name: "Food" },
  { value: "GEN", name: "General Waste" },
  { value: "GLS", name: "Glass" },
  { value: "WEEE", name: "Electronics" },
  { value: "GAR", name: "Garden" },
  { value: "M&V", name: "Man & Van" },
];

export const wasteTypeNameLookup = wasteTypes.reduce((acc, type) => {
  acc[type.value] = type.name;
  return acc;
}, {});
export const frequencyLookup = {
  1: "Monthly",
  2.17: "Fortnightly",
  4.33: "Weekly",
};
export const weeklyFrequencyLookup = {
  4.33: "Once a week",
  8.67: "Twice a week",
  13: "Three times a week",
  17.33: "Four times a week",
  21.67: "Five times a week",
  26: "Six times a week",
  30.33: "Seven times a week",
};

export const daysLookup = [
  { text: "1 Day", value: 1 },
  { text: "3 Days", value: 3 },
  { text: "1 Week", value: 7 },
  { text: "2 Weeks", value: 14 },
  { text: "30 Days", value: 30 },
];

export const quoteSessionStageLookup = {
  SERVICE_SELECTION: "Waste Service Selection",
  EMAIL_COLLECTION: "Email Collection",
  QUOTE_SELECTION: "Quote Selection",
  ACCEPTED_NO_DETAILS_COLLECTED: "Customer Details Collection",
};

export const wasteTypeOrdering = {
  GEN: 1,
  DMR: 2,
  CRD: 3,
  FOD: 4,
  GLS: 5,
  GAR: 6,
  WEEE: 7,
  "M&V": 8,
};

export const containerSizeOrdering = {
  popular: {
    "1280LTR": 1,
    "1100LTR": 2,
    "770LTR": 3,
    "660LTR": 4,
    "360LTR": 5,
    "260LTR": 6,
    "240LTR": 7,
    "140LTR": 8,
    "50BAGS": 9,
  },
  rest: {
    "M&V": 1,
    "6YARDFEL": 2,
    "8YARDFEL": 3,
    "10YARDFEL": 4,
    "10YARDREL": 5,
    "12YARDREL": 6,
    "14YARDREL": 7,
    "20YARDRORO": 8,
    "25YARDRORO": 9,
    "35YARDRORO": 10,
    "40YARDRORO": 11,
    "2YARDSKIP": 12,
    "3YARDSKIP": 13,
    "4YARDSKIP": 14,
    "5YARDSKIP": 15,
    "6YARDSKIP": 16,
    "8YARDSKIP": 17,
    "10YARDSKIP": 18,
    "12YARDSKIP": 19,
  },
};
