import React, { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { createWhitelabelQuote } from "../../api/quote";
import ProgressBar from "../ProgressBar";
import { toast } from "react-toastify";
import { getCompanyPublicDetails } from "../../api/company";
import Modal from "../Modal";
import LoadingIcon from "../../assets/Loading.svg";

const EmailCollection = () => {
  const context = useOutletContext();
  const navigate = useNavigate();
  const setCustomerDetails = context[0];
  const customerDetails = context[1];
  const desiredServices = context[2];
  const setQuotes = context[4];
  const quoteSessionUUID = context[11];
  const pages = context[12];
  const setAlternativeQuotes = context[16];
  const [customerEmail, setCustomerEmail] = useState(
    customerDetails?.email || "",
  );
  const [formValidation, setFormValidation] = useState(null);
  const [loadingQuotes, setLoadingQuotes] = useState(false);
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  useEffect(() => {
    if (customerDetails?.postcode === "") {
      navigate("/quote/postcode");
      return;
    } else if (desiredServices.length === 0) {
      navigate("/quote/waste-services");
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValidEmail = validateEmail();

    if (!isValidEmail) {
      return;
    }
    setLoadingQuotes(true);
    setCustomerDetails((prevDetails) => ({
      ...prevDetails,
      email: customerEmail,
    }));

    try {
      const newQuoteSession = await createWhitelabelQuote(
        customerEmail,
        customerDetails?.postcode,
        desiredServices,
        quoteSessionUUID,
      );
      // check if more than one supplier in quotes
      const uniqueSuppliers = Array.from(
        new Set(newQuoteSession.quotes?.map((quote) => quote.supplier_uuid)),
      );
      // if no quotes set as empty array
      if (!newQuoteSession.quotes || newQuoteSession.quotes.length === 0) {
        setQuotes([]);
      } else if (uniqueSuppliers.length < 2) {
        // if quotes but only one supplier, if redact_supplier_details just set as quote without any name, otherwise get supplier name
        if (newQuoteSession.quotes[0].redact_supplier_details) {
          setQuotes(newQuoteSession.quotes);
        } else {
          const companyDetails = await getCompanyPublicDetails([
            newQuoteSession.quotes[0].supplier_uuid,
          ]);
          const quotesWithSupplierName = newQuoteSession?.quotes?.map(
            (quote) => ({
              ...quote,
              supplier_name: companyDetails[0].name,
            }),
          );
          setQuotes(quotesWithSupplierName);
        }
      } else {
        // if multiple suppliers set supplier_name, either redacted name or real name
        const nonRedactedSuppliers = [];
        const companyLookup = {};
        newQuoteSession?.quotes?.forEach((quote) => {
          if (!quote.redact_supplier_details) {
            nonRedactedSuppliers.push(quote.supplier_uuid);
          } else if (!companyLookup[quote.supplier_uuid]) {
            companyLookup[quote.supplier_uuid] =
              `Supplier ${Object.keys(companyLookup).length + nonRedactedSuppliers.length + 1}`;
          }
        });

        let companyDetails = [];
        if (nonRedactedSuppliers.length > 0) {
          companyDetails = await getCompanyPublicDetails(nonRedactedSuppliers);
        }
        companyDetails?.forEach((company) => {
          companyLookup[company.uuid] = company.name;
        });
        const quotesWithSupplierName = newQuoteSession?.quotes?.map((quote) => {
          const newQuote = { ...quote };
          newQuote.supplier_name = companyLookup[quote.supplier_uuid];
          return newQuote;
        });

        setQuotes(quotesWithSupplierName);
      }
      if (newQuoteSession.alternative_quotes) {
        setAlternativeQuotes(newQuoteSession.alternative_quotes);
      }
      setLoadingQuotes(false);
      navigate("/quote/quote-selection");
    } catch (error) {
      toast.error("Failed to create quote");
      console.error(error);
      setLoadingQuotes(false);
    }
  };

  const validateEmail = () => {
    if (emailRegex.test(customerEmail)) {
      setFormValidation(true);
      return true;
    } else {
      setFormValidation(false);
      return false;
    }
  };

  const handleEmailChange = (e) => {
    formValidation === false &&
      emailRegex.test(e.target.value) &&
      setFormValidation(true);
    setCustomerEmail(e.target.value);
  };

  return (
    <>
      <ProgressBar
        setPage={(page) => navigate(`/quote/${page}`)}
        currPageIndex={2}
        pages={pages}
      />
      <div className="flex size-full flex-col items-center justify-center px-8 pb-20">
        <h1 className="mb-2 block text-center text-base font-bold text-black md:text-lg lg:mb-4 lg:text-xl xl:text-2xl">
          Please enter your email.
        </h1>
        <h2 className="mb-3 block text-center text-sm text-black md:text-base lg:mb-8 lg:text-lg xl:mb-12 xl:text-xl">
          We'll send over your quotes so you can access them later on.
        </h2>
        <form
          onSubmit={handleSubmit}
          noValidate
          className="flex w-full flex-col items-center md:w-3/4 lg:w-2/3"
        >
          <div className="relative mb-1 w-full lg:mb-2">
            <input
              type="text"
              id="email"
              autoCapitalize="off"
              className={`peer appearance-none border-2 shadow ${formValidation ? "border-successColor ring-successColor" : formValidation === false && "border-errorColor ring-errorColor"} h-8 w-full rounded px-2 py-1 text-xs leading-tight text-gray-700 placeholder:text-transparent focus:outline-none focus:ring md:h-12 lg:h-12 lg:px-3 lg:py-2 lg:text-base`}
              name="email"
              value={customerEmail || ""}
              onChange={handleEmailChange}
              onBlur={validateEmail}
              required
              placeholder="Email"
            />
            <label
              htmlFor="email"
              className="absolute left-0 top-2 ml-2 -translate-y-4 bg-white px-1 text-xs text-gray-500 duration-100 ease-linear peer-placeholder-shown:translate-y-0 peer-focus:ml-2 peer-focus:-translate-y-4 peer-focus:px-1 lg:top-2.5 lg:-translate-y-6 lg:text-base lg:peer-focus:-translate-y-6"
            >
              Email
            </label>
            <p
              className={`text-xs italic text-errorColor md:text-sm lg:text-sm ${formValidation !== false && "invisible"}`}
            >
              Please provide a valid email.
            </p>
          </div>
          <button
            className="w-1/2 rounded-md border border-transparent bg-primaryColor px-2 py-1 text-center text-xs text-secondaryColor shadow-md transition-all hover:bg-slate-700 hover:shadow-lg focus:bg-slate-700 focus:shadow-none active:bg-slate-700 active:shadow-none lg:px-4 lg:py-2 lg:text-lg"
            type="submit"
          >
            Continue to quotes
          </button>
        </form>
      </div>
      <Modal isOpen={loadingQuotes}>
        <div className="flex h-[50vh] w-[70vw] flex-col items-center justify-center gap-2 p-4 lg:h-[30vh] lg:w-[30vw]">
          <img src={LoadingIcon} alt="loading icon" className="size-1/2" />
          <h2 className="text-center text-sm lg:text-lg">
            We're fetching your quotes. Please do not leave this window.
          </h2>
        </div>
      </Modal>
    </>
  );
};

export default EmailCollection;
