import { getCompanyApiUrl } from "./main";
import { getAccessToken } from "./../lib/auth";

export const getCompanies = async () => {
  const accessToken = getAccessToken();

  const response = await fetch(getCompanyApiUrl("/company"), {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  });

  if (response.ok) {
    return await response.json();
  } else {
    throw new Error("Failed to fetch companies");
  }
};

export const getCompany = async (uuid) => {
  const accessToken = getAccessToken();
  const response = await fetch(
    getCompanyApiUrl("/company/" + encodeURIComponent(uuid)),
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );

  if (response.ok) {
    return await response.json();
  } else {
    // Handle error
    console.error("Failed to fetch selected company");
    throw new Error("Failed to fetch selected company");
  }
};

export const getCompanyRole = async (companyUuid) => {
  const accessToken = getAccessToken();
  const response = await fetch(
    getCompanyApiUrl(
      "/company/" + encodeURIComponent(companyUuid) + "/company-role",
    ),
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );

  if (response.ok) {
    return (await response.json()).company_role;
  } else {
    throw new Error("Failed to fetch selected company role");
  }
};

export const getCompanyUsers = async (companyUuid) => {
  const accessToken = getAccessToken();
  const response = await fetch(
    getCompanyApiUrl("/company/" + encodeURIComponent(companyUuid) + "/users"),
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );

  if (response.ok) {
    return await response.json();
  } else {
    throw new Error("Failed to fetch company users");
  }
};

export const addCompanyUser = async (companyUuid, email, companyRole) => {
  const accessToken = getAccessToken();
  const response = await fetch(
    getCompanyApiUrl("/company/" + encodeURIComponent(companyUuid) + "/user"),
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ user_email: email, company_role: companyRole }),
    },
  );

  if (response.ok) {
    return;
  } else {
    switch (response.status) {
      case 409:
        throw new Error("User is already a company user");
      case 404:
        throw new Error("User doesn't exist");
      default:
        throw new Error("Failed to add company user");
    }
  }
};

export const removeCompanyUser = async (companyUuid, userUuid) => {
  const accessToken = getAccessToken();
  const response = await fetch(
    getCompanyApiUrl(
      "/company/" +
        encodeURIComponent(companyUuid) +
        "/user/" +
        encodeURIComponent(userUuid),
    ),
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    },
  );

  if (!response.ok) {
    throw new Error("Failed to remove company user");
  }
};
