import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import ProgressBar from "../ProgressBar";
import { extractAddressParts } from "../../lib/google-maps";

const PostcodeCollectionWhitelabel = () => {
  const navigate = useNavigate();
  const location = useNavigate();
  const context = useOutletContext();
  const setCustomerDetails = context[0];
  const customerDetails = context[1];
  const setDesiredServices = context[3];
  const pages = context[12];
  const companyNameRef = useRef(null);
  const addressLine1Ref = useRef(null);
  const [formData, setFormData] = useState(customerDetails);
  const [formValidation, setFormValidation] = useState({
    name: null,
    address_line_1: null,
    town: null,
    postcode: null,
  });

  useEffect(() => {
    setDesiredServices([]);
  }, [location]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const isValidPostcode = validatePostcode();
    const isValidAddressLine1 = validateAddressLine1();
    const isValidTown = validateTown();
    if (!isValidAddressLine1 || !isValidTown || !isValidPostcode) {
      return;
    }
    setCustomerDetails(formData);
    navigate("/quote/waste-services");
  };

  const validatePostcode = () => {
    const postcodeRegex =
      /^([a-zA-Z]{1,2}[0-9][0-9a-zA-Z]?)\s?[0-9][a-zA-Z]{2}?/;
    if (postcodeRegex.test(formData.postcode)) {
      setFormValidation((prevFormValidation) => ({
        ...prevFormValidation,
        postcode: true,
      }));
      return true;
    } else {
      setFormValidation((prevFormValidation) => ({
        ...prevFormValidation,
        postcode: false,
      }));
      return false;
    }
  };

  const handlePostcodeChange = (e) => {
    const postcodeRegex =
      /^([a-zA-Z]{1,2}[0-9][0-9a-zA-Z]?)\s?[0-9][a-zA-Z]{2}?/;

    formValidation.postcode === false &&
      postcodeRegex.test(e.target.value) &&
      setFormValidation((prevFormValidation) => ({
        ...prevFormValidation,
        postcode: true,
      }));
    setFormData((prevData) => ({
      ...prevData,
      postcode: e.target.value,
    }));
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const validateAddressLine1 = () => {
    if (formData.address_line_1) {
      setFormValidation((prevFormValidation) => ({
        ...prevFormValidation,
        address_line_1: true,
      }));
      return true;
    } else {
      setFormValidation((prevFormValidation) => ({
        ...prevFormValidation,
        address_line_1: false,
      }));
      return false;
    }
  };

  const handleAddressLine1Change = (e) => {
    formValidation.address_line_1 === false &&
      e.target.value &&
      setFormValidation((prevFormValidation) => ({
        ...prevFormValidation,
        address_line_1: true,
      }));
    setFormData((prevData) => ({
      ...prevData,
      address_line_1: e.target.value,
    }));
  };

  const validateTown = () => {
    if (formData.town) {
      setFormValidation((prevFormValidation) => ({
        ...prevFormValidation,
        town: true,
      }));
      return true;
    } else {
      setFormValidation((prevFormValidation) => ({
        ...prevFormValidation,
        town: false,
      }));
      return false;
    }
  };

  const handleTownChange = (e) => {
    formValidation.town === false &&
      e.target.value &&
      setFormValidation((prevFormValidation) => ({
        ...prevFormValidation,
        town: true,
      }));
    setFormData((prevData) => ({
      ...prevData,
      town: e.target.value,
    }));
  };

  useEffect(() => {
    if (!window.google) return; // Ensure the Google script has loaded

    const autocompleteCompanyName = new window.google.maps.places.Autocomplete(
      companyNameRef.current,
      {
        types: ["establishment"],
        componentRestrictions: { country: "GB" },
        fields: ["name", "address_components"],
      },
    );
    const autocompleteAddress = new window.google.maps.places.Autocomplete(
      addressLine1Ref.current,
      {
        types: ["geocode"],
        componentRestrictions: { country: "GB" },
        fields: ["address_components"],
      },
    );

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          const userLocation = new window.google.maps.LatLng(
            latitude,
            longitude,
          );

          // Bias the autocomplete results to the user's location
          autocompleteCompanyName.setBounds(
            new window.google.maps.LatLngBounds(userLocation),
          );
          autocompleteAddress.setBounds(
            new window.google.maps.LatLngBounds(userLocation),
          );
        },
        (error) => {
          console.error("Error getting user's location:", error);
        },
      );
    }
    companyNameRef.current.placeholder = "Start typing your company or address";

    addressLine1Ref.current.placeholder =
      "Start typing your address or postcode";

    autocompleteCompanyName.addListener("place_changed", () => {
      const place = autocompleteCompanyName.getPlace();

      if (place.address_components) {
        const components = place.address_components;
        const addressParts = extractAddressParts(components);
        setFormData((prevData) => ({
          ...prevData,
          address_line_1: addressParts.addressLine1,
          address_line_2: addressParts.addressLine2,
          town: addressParts.town,
          county: addressParts.county,
          postcode: addressParts.postcode,
        }));
      }
      if (place.name) {
        setFormData((prevData) => ({
          ...prevData,
          company_name: place.name,
        }));
      }
    });
    autocompleteAddress.addListener("place_changed", () => {
      const place = autocompleteAddress.getPlace();
      if (place.address_components) {
        const components = place.address_components;
        const addressParts = extractAddressParts(components);
        setFormData((prevData) => ({
          ...prevData,
          address_line_1: addressParts.addressLine1,
          address_line_2: addressParts.addressLine2,
          town: addressParts.town,
          county: addressParts.county,
          postcode: addressParts.postcode,
        }));
      }
    });
    return () => {
      if (companyNameRef.current) {
        window.google.maps.event.removeListener(companyNameRef.current);
      }
      if (addressLine1Ref.current) {
        window.google.maps.event.removeListener(addressLine1Ref.current);
      }
    };
  }, []);

  return (
    <>
      <ProgressBar
        setPage={(page) => navigate(`/quote/${page}`)}
        currPageIndex={0}
        pages={pages}
      />
      <div className="flex size-full flex-col items-center px-8 py-4 lg:py-6">
        <h1 className="mb-5 block text-center text-base font-bold text-black md:text-lg lg:mb-7 lg:text-xl xl:text-2xl">
          Hi, what is your address?
        </h1>
        <form
          className="flex w-full flex-col items-center md:w-3/4 lg:w-2/3"
          onSubmit={handleSubmit}
          noValidate
        >
          <div className="relative mb-5 w-full lg:mb-7">
            <input
              type="text"
              id="company_name"
              name="company_name"
              ref={companyNameRef}
              className="peer h-8 w-full appearance-none rounded border-2 px-2 py-1 text-xs leading-tight text-gray-700 shadow focus:outline-none focus:ring lg:h-12 lg:px-3 lg:py-2 lg:text-base"
              value={formData.company_name}
              onChange={handleChange}
            />
            <label
              htmlFor="company_name"
              className="absolute left-0 top-2 ml-2 -translate-y-4 bg-white px-1 text-xs text-gray-500 duration-100 ease-linear lg:top-2.5 lg:-translate-y-6 lg:text-base"
            >
              Company Name (optional)
            </label>
          </div>
          <div className="relative mb-1 w-full lg:mb-2">
            <input
              type="text"
              id="address_line_1"
              className={`peer appearance-none border-2 shadow ${formValidation.address_line_1 ? "border-successColor ring-successColor" : formValidation.address_line_1 === false && "border-errorColor ring-errorColor"} h-8 w-full rounded px-2 py-1 text-xs leading-tight text-gray-700 focus:outline-none focus:ring lg:h-12 lg:px-3 lg:py-2 lg:text-base`}
              name="address_line_1"
              ref={addressLine1Ref}
              value={formData.address_line_1 || ""}
              onChange={handleAddressLine1Change}
              onBlur={validateAddressLine1}
              required
            />
            <label
              htmlFor="address_line_1"
              className="absolute left-0 top-2 ml-2 -translate-y-4 bg-white px-1 text-xs text-gray-500 duration-100 ease-linear lg:top-2.5 lg:-translate-y-6 lg:text-base"
            >
              Address Line 1
            </label>
            <p
              className={`text-xs italic text-errorColor lg:text-sm ${formValidation.address_line_1 !== false && "invisible"}`}
            >
              Please provide a valid address.
            </p>
          </div>
          <div className="relative mb-5 w-full lg:mb-7">
            <input
              type="text"
              id="address_line_2"
              className="peer h-8 w-full appearance-none rounded border-2 px-2 py-1 text-xs leading-tight text-gray-700 shadow placeholder:text-transparent focus:outline-none focus:ring lg:h-12 lg:px-3 lg:py-2 lg:text-base"
              name="address_line_2"
              value={formData.address_line_2 || ""}
              onChange={handleChange}
              placeholder="Address Line 2"
            />
            <label
              htmlFor="address_line_2"
              className="absolute left-0 top-2 ml-2 -translate-y-4 bg-white px-1 text-xs text-gray-500 duration-100 ease-linear peer-placeholder-shown:translate-y-0 peer-focus:ml-2 peer-focus:-translate-y-4 peer-focus:px-1 lg:top-2.5 lg:-translate-y-6 lg:text-base lg:peer-focus:-translate-y-6"
            >
              Address Line 2 (optional)
            </label>
          </div>
          <div className="relative mb-1 w-full lg:mb-2">
            <input
              type="text"
              id="town"
              className={`peer appearance-none border-2 shadow ${formValidation.town ? "border-successColor ring-successColor" : formValidation.town === false && "border-errorColor ring-errorColor"} h-8 w-full rounded px-2 py-1 text-xs leading-tight text-gray-700 placeholder:text-transparent focus:outline-none focus:ring lg:h-12 lg:px-3 lg:py-2 lg:text-base`}
              name="town"
              value={formData.town || ""}
              onChange={handleTownChange}
              onBlur={validateTown}
              required
              placeholder="Town"
            />
            <label
              htmlFor="town"
              className="absolute left-0 top-2 ml-2 -translate-y-4 bg-white px-1 text-xs text-gray-500 duration-100 ease-linear peer-placeholder-shown:translate-y-0 peer-focus:ml-2 peer-focus:-translate-y-4 peer-focus:px-1 lg:top-2.5 lg:-translate-y-6 lg:text-base lg:peer-focus:-translate-y-6"
            >
              Town
            </label>
            <p
              className={`text-xs italic text-errorColor lg:text-sm ${formValidation.town !== false && "invisible"}`}
            >
              Please provide a valid town.
            </p>
          </div>
          <div className="relative mb-5 w-full lg:mb-7">
            <input
              type="text"
              id="county"
              className="peer h-8 w-full appearance-none rounded border-2 px-2 py-1 text-xs leading-tight text-gray-700 shadow placeholder:text-transparent focus:outline-none focus:ring lg:h-12 lg:px-3 lg:py-2 lg:text-base"
              name="county"
              value={formData.county || ""}
              onChange={handleChange}
              required
              placeholder="County"
            />
            <label
              htmlFor="county"
              className="absolute left-0 top-2 ml-2 -translate-y-4 bg-white px-1 text-xs text-gray-500 duration-100 ease-linear peer-placeholder-shown:translate-y-0 peer-focus:ml-2 peer-focus:-translate-y-4 peer-focus:px-1 lg:top-2.5 lg:-translate-y-6 lg:text-base lg:peer-focus:-translate-y-6"
            >
              County (optional)
            </label>
          </div>
          <div className="relative mb-1 w-full lg:mb-2">
            <input
              type="text"
              id="postcode"
              className={`peer appearance-none border-2 shadow ${formValidation.postcode ? "border-successColor ring-successColor" : formValidation.postcode === false && "border-errorColor ring-errorColor"} h-8 w-full rounded px-2 py-1 text-xs leading-tight text-gray-700 placeholder:text-transparent focus:outline-none focus:ring lg:h-12 lg:px-3 lg:py-2 lg:text-base`}
              name="postcode"
              onChange={handlePostcodeChange}
              onBlur={validatePostcode}
              value={formData.postcode}
              required
              placeholder="Postcode"
            />
            <label
              htmlFor="postcode"
              className="absolute left-0 top-2 ml-2 -translate-y-4 bg-white px-1 text-xs text-gray-500 duration-100 ease-linear peer-placeholder-shown:translate-y-0 peer-focus:ml-2 peer-focus:-translate-y-4 peer-focus:px-1 lg:top-2.5 lg:-translate-y-6 lg:text-base lg:peer-focus:-translate-y-6"
            >
              Postcode
            </label>
            <p
              className={`text-xs italic text-errorColor lg:text-sm ${formValidation.postcode !== false && "invisible"}`}
            >
              Please provide a valid postcode.
            </p>
          </div>
          <button
            className="w-1/2 rounded-md border border-transparent bg-primaryColor px-2 py-1 text-center text-xs text-secondaryColor shadow-md transition-all hover:bg-slate-700 hover:shadow-lg focus:bg-slate-700 focus:shadow-none active:bg-slate-700 active:shadow-none lg:px-4 lg:py-2 lg:text-lg"
            type="submit"
          >
            Next
          </button>
        </form>
      </div>
    </>
  );
};

export default PostcodeCollectionWhitelabel;
