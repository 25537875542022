import React from "react";

const ProgressBar = ({ setPage, currPageIndex, pages }) => {
  return (
    <section className="my-4 flex w-full flex-col items-center xl:my-6">
      <div className="flex items-center">
        {pages.map((page, index) => {
          return (
            <div key={index} className="flex">
              <div className="flex flex-col items-center">
                <button
                  onClick={() => setPage(page.page)}
                  disabled={currPageIndex < index}
                  className={`z-10 -mx-1 flex size-10 items-center justify-center rounded-full text-lg md:size-14 md:text-2xl lg:size-16 lg:text-3xl xl:size-20 xl:text-4xl ${currPageIndex >= index ? "bg-primaryColor text-white" : "bg-gray-300 text-primaryColor"}`}
                >
                  {page.icon}
                </button>
                <p
                  className={`mt-2 text-xs md:text-sm lg:text-base xl:text-lg ${currPageIndex >= index ? "text-primaryColor" : "text-gray-400"}`}
                >
                  {page.title}
                </p>
              </div>
              {index !== pages.length - 1 && (
                <div className="-mx-3 flex h-8 flex-col justify-center md:h-10 lg:h-12 xl:h-16">
                  <div
                    className={`h-2 w-16 md:w-24 lg:w-32 xl:h-3 xl:w-40 2xl:h-3 ${currPageIndex > index ? "bg-primaryColor" : "bg-gray-300"}`}
                  ></div>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default ProgressBar;
