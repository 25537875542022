export const getNavLinks = (systemRole, companyRole) => {
  const navLinks = [
    { id: "1", name: "Home", url: "/" },
    { id: "2", name: "Create a Quote", url: "/quote" },
  ];

  if (systemRole === "admin") {
    // system admins can see everything
    navLinks.push({ id: "3", name: "Company Details", url: "/company" });
    navLinks.push({ id: "4", name: "Company User List", url: "/users" });
    navLinks.push({ id: "5", name: "Whitelabel", url: "/whitelabel" });
    navLinks.push({ id: "6", name: "Price List", url: "/price-list" });
    navLinks.push({
      id: "7",
      name: "Waste Container Info",
      url: "/waste-container-info",
    });
    return navLinks;
  }

  if (companyRole != null) {
    // anyone who is a company user can see these
    navLinks.push({ id: "3", name: "Company Details", url: "/company" });
    navLinks.push({ id: "6", name: "Price List", url: "/price-list" });
    navLinks.push({
      id: "7",
      name: "Waste Container Info",
      url: "/waste-container-info",
    });
  }

  if (companyRole === "admin") {
    // company admins can see these
    navLinks.push({ id: "4", name: "Company User List", url: "/users" });
    navLinks.push({ id: "5", name: "Whitelabel", url: "/whitelabel" });
  }

  // TODO(Jon): perhaps i can have a company nav links, and a system nav links.
  // company ones are shown if the user is part of a company,
  // system ones are shown if the user is a system admin

  return navLinks;
};
