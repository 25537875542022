import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import AccountButton from "../AccountButton";
import SideNav from "../SideNav";
import { getNavLinks } from "../../lib/navLinks";
import { useGlobalContext } from "../../GlobalContext";
import { getSystemRole } from "./../../lib/auth";
import ENV from "../../lib/config";

const PageWrapper = ({ children }) => {
  const [navLinks, setNavLinks] = useState([]);
  const { state } = useGlobalContext();

  useEffect(() => {
    setNavLinks(getNavLinks(getSystemRole(), state.selectedCompanyRole));
  }, [state.selectedCompanyRole]);

  return (
    <div className="flex h-screen w-full flex-col font-fontFamily">
      <header className="flex h-28 items-center justify-between bg-primaryColor p-10 xl:h-32 2xl:h-36">
        <h1 className="text-xl text-secondaryColor xl:text-2xl 2xl:text-3xl">
          Switch Waste
        </h1>
        <AccountButton
          clientId={ENV.get().REACT_APP_OAUTH2_CLIENT_ID}
          loginUrl={ENV.get().REACT_APP_OAUTH2_LOGIN_URL}
        />
      </header>
      <div className="flex flex-1">
        <SideNav links={navLinks} />
        <main className="h-full flex-1 bg-backgroundColor">{children}</main>
      </div>
      <footer className="flex h-16 w-full items-center justify-center bg-secondaryColor xl:h-20 2xl:h-24">
        <p className="text-md text-primaryColor xl:text-lg 2xl:text-xl">
          &copy; 2024 Switch Waste. All rights reserved.
        </p>
      </footer>
    </div>
  );
};

PageWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PageWrapper;
