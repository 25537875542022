import React from "react";
import PropTypes from "prop-types";
import Modal from "../Modal";

const ConfirmationModal = ({
  isOpen,
  onClose,
  onConfirm,
  question = "Are you sure?",
}) => {
  return (
    <Modal isOpen={isOpen}>
      <div className="flex h-[30vh] w-[50vw] flex-col items-center justify-center p-4 xl:p-6 2xl:h-[25vh] 2xl:p-8">
        <h2 className="text-black-700 text-md mb-4 block text-center font-bold xl:mb-6 xl:text-lg 2xl:mb-10 2xl:text-2xl">
          {question}
        </h2>
        <div className="flex w-full justify-center">
          <button
            onClick={onClose}
            className="ml-12 h-8 w-1/5 rounded-md border border-transparent bg-errorColor px-2 py-1 text-center text-sm text-white shadow-md transition-all hover:bg-slate-700 hover:shadow-lg active:bg-slate-700 active:shadow-none xl:h-12 xl:px-4 xl:py-2 xl:text-xl 2xl:h-16 2xl:px-6 2xl:py-3 2xl:text-2xl"
            autoFocus={false}
          >
            No
          </button>
          <button
            onClick={onConfirm}
            className="ml-12 h-8 w-1/5 rounded-md border border-transparent bg-successColor px-2 py-1 text-center text-sm text-white shadow-md transition-all hover:bg-slate-700 hover:shadow-lg focus:bg-slate-700 focus:shadow-none active:bg-slate-700 active:shadow-none xl:ml-16 xl:h-12 xl:px-4 xl:py-2 xl:text-xl 2xl:h-16 2xl:px-6 2xl:py-3 2xl:text-2xl"
          >
            Yes
          </button>
        </div>
      </div>
    </Modal>
  );
};

ConfirmationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  question: PropTypes.string,
};

export default ConfirmationModal;
