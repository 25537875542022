import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Modal from "../Modal";
import WasteIcon from "../WasteIcon";
import ContainerIcon from "../ContainerIcon";
import Select from "react-select";

const WasteServiceType = ({
  wasteType,
  containerTypes,
  services,
  setServices,
  onSubmit,
  frequencyLookup,
  wasteTypeNameLookup,
}) => {
  const [isSizeError, setIsSizeError] = useState(null);
  const [isSuccessfullyAdded, setIsSuccessfullyAdded] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [formData, setFormData] = useState({
    size: "select size",
    quantity: 1,
    frequency: "1",
  });
  const [containerOptions, setContainerOptions] = useState([]);
  useEffect(() => {
    const newOptions = containerTypes.map((containerType) => {
      return {
        value: containerType,
        label: containerType,
        icon: <ContainerIcon containerType={containerType} />,
      };
    });
    setContainerOptions(newOptions);
  }, []);
  const handleServiceClick = () => {
    setIsOpen(true);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSizeChange = (e) => {
    setFormData((prevData) => ({ ...prevData, size: e.value }));
    e.value === "select size" ? setIsSizeError(true) : setIsSizeError(false);
  };

  const handleFrequencyChange = (e) => {
    setFormData((prevData) => ({ ...prevData, frequency: e.target.value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData.size === "select size") {
      setIsSizeError(true);
    } else {
      setServices((prevServices) => {
        const updatedServices = [...prevServices];

        for (let i = 0; i < updatedServices.length; i++) {
          if (
            updatedServices[i].waste_type === wasteType &&
            updatedServices[i].container_type === formData.size &&
            updatedServices[i].frequency === formData.frequency
          ) {
            updatedServices[i].quantity += 1;
            return updatedServices;
          }
        }

        return [
          ...prevServices,
          {
            container_type: formData.size,
            waste_type: wasteType,
            quantity: 1,
            frequency: formData.frequency,
          },
        ];
      });
      setIsSuccessfullyAdded(true);
    }
  };

  const closeModal = () => {
    setIsOpen(false);
    setIsSuccessfullyAdded(false);
    setFormData({
      size: "select size",
      quantity: 1,
      frequency: "1",
    });
    setIsSizeError(null);
  };

  return (
    <>
      <button
        onClick={handleServiceClick}
        className="h-56 w-40 xl:h-72 xl:w-56 2xl:h-80 2xl:w-72"
      >
        <div className="flex size-full flex-col items-center justify-center rounded-md border border-transparent bg-slate-200 p-3 text-center shadow-sm transition-all hover:bg-slate-400 hover:shadow-lg xl:p-6">
          <div className="mb-6 size-24 xl:size-36 2xl:size-44">
            <WasteIcon wasteType={wasteType} />
          </div>
          <h3 className="text-black-700 text-md block font-bold xl:text-lg 2xl:text-xl">
            {wasteTypeNameLookup[wasteType]}
          </h3>
        </div>
      </button>

      <Modal isOpen={isOpen}>
        {isSuccessfullyAdded ? (
          <div className="flex h-[70vh] w-[50vw] flex-col items-center justify-center">
            <div className="mb-10 size-32">
              <WasteIcon wasteType={wasteType} />
            </div>

            <p className="text-black-900 mb-20 block text-center text-xl font-bold">
              {wasteTypeNameLookup[wasteType]} has been added to your quote.
            </p>
            <div className="flex w-full justify-center">
              <button
                onClick={closeModal}
                className="text-md h-12 w-1/3 rounded-md border border-transparent bg-secondaryColor px-4 py-2 text-center text-primaryColor shadow-sm transition-all hover:border-slate-800 hover:bg-slate-800 hover:text-white hover:shadow-lg focus:border-slate-800 focus:bg-slate-800 focus:text-white active:border-slate-800 active:bg-slate-800 active:text-white xl:text-xl"
              >
                Add something else
              </button>
              <button
                onClick={() => onSubmit(services)}
                className="text-md ml-16 h-12 w-1/3 rounded-md border border-transparent bg-primaryColor px-4 py-2 text-center text-secondaryColor shadow-md transition-all hover:bg-slate-700 hover:shadow-lg focus:bg-slate-700 focus:shadow-none active:bg-slate-700 active:shadow-none xl:text-xl"
              >
                Continue to quotes
              </button>
            </div>
          </div>
        ) : (
          <form
            onSubmit={handleSubmit}
            className="flex h-[70vh] w-[50vw] flex-col items-center justify-center"
          >
            <div className="mb-3 size-24 xl:mb-6 xl:size-32">
              <WasteIcon wasteType={wasteType} />
            </div>
            <h3 className="text-black-700 text-md mb-2 block font-bold xl:mb-3 xl:text-lg">
              {wasteTypeNameLookup[wasteType]}
            </h3>
            <div className="relative mb-1 w-1/2">
              <label
                htmlFor="size"
                className="text-md mb-1 text-gray-800 xl:text-xl"
              >
                Size
              </label>
              <Select
                placeholder="select size ..."
                value={{ label: formData.size }}
                onChange={handleSizeChange}
                options={containerOptions}
                formatOptionLabel={(containerOption) => (
                  <div className="flex justify-between">
                    <p>{containerOption.label}</p>
                    <div className="size-6">{containerOption.icon}</div>
                  </div>
                )}
              />
              {/* <select
                className={`shadow appearance-none border border-2 rounded w-full ${isSizeError === false ? "border-successColor" : isSizeError && "border-errorColor"} py-1 xl:py-2 px-2 xl:px-3 h-8 xl:h-12 text-gray-700 text-sm xl:text-lg leading-tight focus:outline-none focus:shadow-outline`}
                value={formData.size}
                onChange={handleSizeChange}
                id="size"
                name="size"
              >
                <option key={1000} value={"select size"}>
                  Choose a size ...
                </option>
                {containerTypes.map((container, index) => (
                  <option
                    key={index}
                    value={container}
                    className="flex justify-between"
                  >
                    {container}
                  </option>
                ))}
              </select>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.2"
                stroke="currentColor"
                className="h-5 w-5 ml-1 absolute top-8 xl:top-10 right-2.5 text-gray-700"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M8.25 15 12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"
                />
              </svg> */}
              <p
                className={`xl:text-md text-xs italic text-errorColor ${!isSizeError && "invisible"}`}
              >
                Select size to continue.
              </p>
            </div>
            <div className="mb-4 w-1/2 xl:mb-6">
              <label
                htmlFor="quantity"
                className="text-md mb-1 text-gray-800 xl:text-xl"
              >
                Quantity
              </label>
              <input
                type="number"
                min={1}
                id="quantity"
                name="quantity"
                value={formData.quantity}
                onChange={handleChange}
                className="focus:shadow-outline h-8 w-full appearance-none rounded border-2 px-2 py-1 text-sm leading-tight text-gray-700 shadow focus:outline-none xl:h-12 xl:px-3 xl:py-2 xl:text-lg"
              />
            </div>
            <div className="relative mb-4 w-1/2 xl:mb-6">
              <label
                htmlFor="frequency"
                className="text-md mb-1 text-gray-800 xl:text-xl"
              >
                Frequency
              </label>
              <select
                className="focus:shadow-outline h-8 w-full appearance-none rounded border-2 px-2 py-1 text-sm leading-tight text-gray-700 shadow focus:outline-none xl:h-12 xl:px-3 xl:py-2 xl:text-lg"
                value={formData.frequency}
                onChange={handleFrequencyChange}
                id="frequency"
                name="frequency"
              >
                {Object.keys(frequencyLookup).map((frequency, index) => {
                  return (
                    <option key={index} value={frequency}>
                      {frequencyLookup[frequency]}
                    </option>
                  );
                })}
              </select>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.2"
                stroke="currentColor"
                className="absolute right-2.5 top-8 ml-1 size-5 text-gray-700 xl:top-10"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M8.25 15 12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"
                />
              </svg>
            </div>
            <div className="flex w-full justify-center">
              <button
                className="h-8 w-1/5 rounded-md border border-transparent bg-secondaryColor px-2 py-1 text-center text-sm text-primaryColor shadow-md transition-all hover:border-slate-800 hover:bg-slate-800 hover:text-white hover:shadow-lg focus:border-slate-800 focus:bg-slate-800 focus:text-white active:border-slate-800 active:bg-slate-800 active:text-white xl:h-12 xl:px-4 xl:py-2 xl:text-xl"
                type="button"
                onClick={closeModal}
              >
                Cancel
              </button>
              <button
                className="ml-12 h-8 w-1/5 rounded-md border border-transparent bg-primaryColor px-2 py-1 text-center text-sm text-secondaryColor shadow-md transition-all hover:bg-slate-700 hover:shadow-lg focus:bg-slate-700 focus:shadow-none active:bg-slate-700 active:shadow-none xl:ml-16 xl:h-12 xl:px-4 xl:py-2 xl:text-xl"
                type="submit"
              >
                Add
              </button>
            </div>
          </form>
        )}
      </Modal>
    </>
  );
};
WasteServiceType.propTypes = {
  wasteType: PropTypes.string.isRequired,
  containerTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  services: PropTypes.arrayOf(PropTypes.object).isRequired,
  setServices: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  frequencyLookup: PropTypes.object.isRequired,
  wasteTypeNameLookup: PropTypes.object.isRequired,
};

export default WasteServiceType;
