// Helper function to extract address components
export const extractAddressParts = (components) => {
  let addressLine1 = "";
  let addressLine2 = "";
  let town = "";
  let postcode = "";
  let county = "";

  components.forEach((component) => {
    const types = component.types;
    if (types.includes("street_number")) {
      addressLine1 = component.long_name + " " + addressLine1;
    }
    if (types.includes("route")) {
      addressLine1 += component.long_name;
    }
    if (types.includes("locality")) {
      addressLine2 = component.long_name;
    }
    if (types.includes("postal_town")) {
      town = component.long_name;
    }
    if (types.includes("postal_code")) {
      postcode = component.long_name;
    }
    if (types.includes("administrative_area_level_2")) {
      county = component.long_name;
    }
  });

  return { addressLine1, addressLine2, town, postcode, county };
};
