import React from "react";
import PropTypes from "prop-types";
import "./index.css";

const PopoutModal = ({ children, isOpen }) => {
  if (!isOpen) return null;

  return (
    <div className="popout-modal-overlay">
      <div className="popout-modal-content">{children}</div>
    </div>
  );
};

PopoutModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
};

export default PopoutModal;
