import React, { useState, useEffect, useRef } from "react";
import PageWrapper from "../../components/PageWrapper";
import {
  fetchPriceList,
  uploadPriceList,
  deletePriceListItem,
  getPriceListVisibility,
  setPriceListVisibility,
  getPriceListImports,
  deleteAllCompanyPriceListItems,
  fetchPriceListCSV,
  fetchWasteContainerInfo,
} from "../../api/quote";
import ConfirmationModal from "../../components/ConfirmationModal";
import { currencyFormat, relativeTimeFormatter } from "../../lib/formatters";
import { MdModeEdit, MdDeleteForever } from "react-icons/md";
import { FaExternalLinkAlt, FaFileImport } from "react-icons/fa";
import EditPriceListItem from "../../components/EditPriceListItem";
import { useGlobalContext } from "../../GlobalContext";
import { toast } from "react-toastify";
import Modal from "../../components/Modal";
import ManageExternalPriceList from "../../components/ManageExternalPriceList";
import { getCompanyPublicDetails } from "../../api/company";
import PaginationControls from "../../components/PaginationControls";
import { FaDownload, FaPlus } from "react-icons/fa6";
import { downloadCSV, downloadCSVTemplate } from "../../lib/csv";
import AddPriceListItem from "../../components/AddPriceListItem";
import { Link } from "react-router-dom";
import { getUserNameById } from "../../api/company";
import LoadingIcon from "../../assets/Loading.svg";
import {
  containerTypeNameLookup,
  wasteTypeNameLookup,
} from "../../lib/lookups";
import ManageExcessWeightCharges from "../../components/ManageExcessWeightCharges";

const IsPublicToggle = ({ checked, handleChange, disabled }) => {
  return (
    <div className="relative">
      <label className="inline-flex cursor-pointer items-center">
        <label className="mr-2 bg-white px-1 text-base">Public</label>
        <input
          type="checkbox"
          className="peer sr-only"
          checked={checked}
          onChange={handleChange}
          disabled={disabled}
        />
        <div className="peer relative h-6 w-11 rounded-full bg-gray-200 after:absolute after:start-[2px] after:top-[2px] after:size-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-blue-600 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rtl:peer-checked:after:-translate-x-full dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-blue-800"></div>
      </label>
    </div>
  );
};

const PriceList = () => {
  const { state } = useGlobalContext();
  const [priceList, setPriceList] = useState(null);
  const [file, setFile] = useState(null);
  const [rows, setRows] = useState(0);
  const fileInputRef = useRef(null);
  const [editingPriceList, setIsEditingPriceList] = useState(null);
  const [isPublic, setIsPublic] = useState(false);
  const [isPublicWorking, setIsPublicWorking] = useState(false);
  const [isManagingExternalPriceList, setIsManagingExternalPriceList] =
    useState(false);
  const [importedPriceLists, setImportedPriceLists] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [addingPriceListItem, setAddingPriceListItem] = useState(false);
  const [deletingPriceList, setDeletingPriceList] = useState(false);
  const [userNames, setUserNames] = useState({});
  const [loading, setLoading] = useState(false);
  const [wciMap, setWciMap] = useState({});
  const [isManagingExcessWeightCharges, setIsManagingExcessWeightCharges] =
    useState(false);

  useEffect(() => {
    const fetchUserNames = async () => {
      if (!priceList) return;
      const names = {};
      const uniqueUserIds = new Set(priceList.map((item) => item.updated_by));
      for (const userId of uniqueUserIds) {
        try {
          const userName = await getUserNameById(state.selectedCompany, userId);
          names[userId] = userName;
        } catch (error) {
          console.error("Failed to fetch user name:", error);
          names[userId] = "Unknown User";
        }
      }
      setUserNames(names);
    };

    fetchUserNames();
  }, [priceList]);

  const handleCsvImport = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (!file) {
      return;
    }
    const reader = new FileReader();
    reader.onload = function (e) {
      const content = e.target.result;
      const rows = content.split("\n");
      const nonEmptyRows = rows.filter((row) => row.trim() !== "");
      const rowCount = nonEmptyRows.length - 1; // minus header
      setRows(rowCount);
      setFile(file);
      event.target.value = null;
    };

    reader.readAsText(file);
  };

  const handleConfirmCsvImport = () => {
    const uploadFile = async () => {
      try {
        await uploadPriceList(state.selectedCompany, file);
        toast.success("Price list uploaded successfully");
        await loadPriceList();
      } catch (error) {
        console.error(error);
        toast.error("Failed to upload price list");
      }
      setFile(null);
    };

    uploadFile();
  };

  const loadSupplierNames = async () => {
    try {
      if (importedPriceLists.length < 1) return;
      const details = await getCompanyPublicDetails(
        importedPriceLists.map((ipl) => ipl.supplier_uuid),
      );
      const map = {};
      details.forEach((detail) => {
        map[detail.uuid] = detail.name;
      });
    } catch (error) {
      console.error(error);
    }
  };

  const loadPriceList = async () => {
    try {
      const res = await fetchPriceList(
        state.selectedCompany,
        pageNumber,
        searchTerm === "" ? null : searchTerm,
      );
      setPriceList(res.price_list);
      setPageCount(res.pages);
    } catch (error) {
      console.error(error);
      toast.error("Failed to load price list");
    }
  };

  const loadIsPublic = async () => {
    setIsPublicWorking(true);
    try {
      const isPublic =
        (await getPriceListVisibility(state.selectedCompany)) == "public";
      setIsPublic(isPublic);
    } catch (error) {
      console.error(error);
    }
    setIsPublicWorking(false);
  };

  const loadImportedPriceLists = async () => {
    try {
      const importedPriceLists = await getPriceListImports(
        state.selectedCompany,
      );
      setImportedPriceLists(importedPriceLists);
    } catch (error) {
      console.error(error);
      toast.error("Failed to load imported price lists");
    }
  };

  const exportCSV = async () => {
    setLoading(true);
    try {
      const csvBlob = await fetchPriceListCSV(state.selectedCompany);
      const csvRef = window.URL.createObjectURL(csvBlob);
      await downloadCSV(csvRef, "price-list.csv");
      toast.success("Price List CSV successfully downloaded.");
    } catch (error) {
      console.error(error);
      toast.error("Error downloading Price List CSV. Please try again.");
    }
    setLoading(false);
  };
  const loadWasteContainerInfo = async () => {
    try {
      const wasteContainerInfo = await fetchWasteContainerInfo(
        state.selectedCompany,
      );
      const wCIMap = {};
      wasteContainerInfo.forEach(
        (wc) => (wCIMap[wc.waste_type] = wc.excess_weight_charge_gbp),
      );
      setWciMap(wCIMap);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    loadPriceList();
    loadIsPublic();
    loadImportedPriceLists();
    loadWasteContainerInfo();
  }, [state.selectedCompany]);

  useEffect(() => {
    loadPriceList();
  }, [pageNumber, searchTerm]);

  useEffect(() => {
    loadSupplierNames();
  }, [importedPriceLists]);

  const onSearchChange = (e) => {
    setPageNumber(1);
    setSearchTerm(e.target.value);
  };

  const formatPriceRange = (prices) => {
    if (prices?.length === 0) {
      return currencyFormat(0);
    }

    if (prices?.length === 1) {
      return currencyFormat(prices[0]);
    }
    prices.sort();
    return `${currencyFormat(prices[0])} - ${currencyFormat(prices[prices?.length - 1])}`;
  };

  const formatWeightRange = (weights) => {
    if (weights?.length === 0) {
      return "Not Provided";
    }

    if (weights?.length === 1) {
      return weights[0] + "Kg";
    }
    weights.sort();
    return `${weights[0]} - ${weights[weights?.length - 1]} Kg`;
  };

  const handleDeletingPriceList = async () => {
    try {
      await deleteAllCompanyPriceListItems(state.selectedCompany);
      setDeletingPriceList(false);
      toast.success("Price list deleted.");
      loadPriceList();
    } catch (error) {
      console.error(error);
      toast.error("Error deleting price list.");
    }
  };
  const th = "border-r-2 px-3 py-2";
  const td = "border-r-2 px-3 py-2 text-sm text-gray-900";
  const button =
    "flex h-fit cursor-pointer gap-2 rounded-md border border-transparent bg-primaryColor px-2 py-1 text-center text-sm text-secondaryColor shadow-md transition-all hover:border-slate-800 hover:bg-slate-800 hover:text-white hover:shadow-lg focus:border-slate-800 focus:bg-slate-800 focus:text-white active:border-slate-800 active:bg-slate-800 active:text-white";

  return (
    <PageWrapper>
      <div className="size-full p-6 xl:p-8 2xl:p-10">
        <h1 className="mb-3 text-2xl font-bold text-black">Price List</h1>
        <div className="mb-4 flex flex-wrap gap-4">
          <button
            className={button}
            onClick={() => setIsManagingExternalPriceList(true)}
          >
            <p className="h-fit">Manage Imported Price Lists</p>
            <FaExternalLinkAlt className="m-1 text-green-500" />
          </button>
          <button onClick={handleCsvImport} className={button}>
            <p className="h-fit">CSV Import</p>
            <FaFileImport className="m-1 text-green-500" />
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              accept=".csv"
              onChange={handleFileChange}
            />
          </button>
          <Link to="/price-list/import-wizard" className={button}>
            Import Wizard
            <FaFileImport className="m-1 text-green-500" />
          </Link>
          <button
            className={button}
            onClick={() => setIsManagingExcessWeightCharges(true)}
          >
            <p className="h-fit">Manage Excess Weight Charges</p>
            <MdModeEdit className="m-1 text-green-500" />
          </button>
          <button onClick={() => downloadCSVTemplate()} className={button}>
            <p className="h-fit">Download CSV Template</p>
            <FaDownload className="m-1 text-green-500" />
          </button>
          <button onClick={() => exportCSV()} className={button}>
            <p className="h-fit">Export Price List</p>
            <FaDownload className="m-1 text-green-500" />
          </button>
          <button
            onClick={() => setAddingPriceListItem(true)}
            className={button}
          >
            <p className="h-fit">Add Item</p>
            <FaPlus className="m-1 text-green-500" />
          </button>
          <button onClick={() => setDeletingPriceList(true)} className={button}>
            <p className="h-fit">Delete All</p>
            <MdDeleteForever className="m-1 text-red-500" />
          </button>
        </div>
        <IsPublicToggle
          checked={isPublic}
          disabled={isPublicWorking}
          handleChange={(e) => {
            setIsPublicWorking(true);
            const isChecked = e.target.checked;
            setPriceListVisibility(state.selectedCompany, isChecked)
              .then(() => {
                setIsPublic(isChecked);
                toast.success(
                  `Your company's price list is now ${isChecked ? "public" : "private"}`,
                );
              })
              .finally(() => {
                setIsPublicWorking(false);
              });
          }}
        />
        {priceList === null ? (
          <p className="my-6 text-gray-500">Loading...</p>
        ) : (
          <>
            <div className="mb-3 flex w-full items-center justify-between">
              <input
                name="search"
                id="search"
                type="text"
                placeholder="Search container, waste or postcode"
                onChange={onSearchChange}
                className="h-8 w-1/4 appearance-none rounded border-2 px-3 py-2 text-sm leading-tight text-gray-700 shadow focus:outline-none focus:ring 2xl:h-10 2xl:text-base"
              />

              <PaginationControls
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
                pageCount={pageCount}
              />
            </div>
            <table className="mb-3 w-full table-auto border-2 text-left text-sm text-gray-500">
              <thead className="bg-gray-100 text-xs uppercase text-gray-700">
                <tr>
                  <th className={th}>Container</th>
                  <th className={th}>Waste</th>
                  <th className={th}>Postcode Area District</th>
                  <th className={th}>Price</th>
                  <th className={th}>Weight</th>
                  <th className={th}>Excess Weight Charge</th>
                  <th className={th}>Updated By</th>
                  <th className={th}>Last Updated</th>
                  <th className={th} />
                  <th className={th} />
                </tr>
              </thead>
              <tbody>
                {priceList.map((item, index) => (
                  <tr
                    key={item.uuid}
                    className={`${index % 2 !== 0 && "bg-gray-100"}`}
                  >
                    <td className={td}>
                      {containerTypeNameLookup[item.container_type]}
                    </td>
                    <td className={td}>
                      {wasteTypeNameLookup[item.waste_type]}
                    </td>
                    <td className={td}>{item.postcode_area_district}</td>
                    <td className={td}>
                      {formatPriceRange(
                        item?.waste_weight_prices.flatMap((weightPrice) =>
                          weightPrice.bulk_prices.map(
                            (price) => price.price_gbp,
                          ),
                        ) || [],
                      )}
                    </td>
                    <td className={td}>
                      {formatWeightRange(
                        item?.waste_weight_prices?.map(
                          (weight) => weight.weight_kilos,
                        ),
                      )}
                    </td>
                    <td className={td}>
                      {wciMap[item.waste_type] > 0 &&
                        currencyFormat(wciMap[item.waste_type])}
                    </td>
                    <td className={td}>
                      {userNames[item.updated_by] || "Loading..."}
                    </td>
                    <td className={td}>
                      {relativeTimeFormatter(item.updated_at)}
                    </td>
                    <td className={td}>
                      <MdModeEdit
                        className={`cursor-pointer text-lg text-blue-500`}
                        onClick={() => {
                          setIsEditingPriceList(item);
                        }}
                      />
                    </td>
                    <td className={td}>
                      <MdDeleteForever
                        className={`cursor-pointer text-lg text-red-500`}
                        onClick={() => {
                          deletePriceListItem(
                            state.selectedCompany,
                            item.uuid,
                          ).then(() => loadPriceList());
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <PaginationControls
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
              pageCount={pageCount}
            />
          </>
        )}
      </div>
      <ConfirmationModal
        isOpen={file !== null}
        onClose={() => setFile(null)}
        onConfirm={handleConfirmCsvImport}
        question={`Are you sure you want to upload ${rows} rows?`}
      />
      <ConfirmationModal
        isOpen={deletingPriceList}
        onClose={() => setDeletingPriceList(false)}
        onConfirm={handleDeletingPriceList}
        question={`Are you sure you want to delete all entries? This action cannot be undone.`}
      />
      {isManagingExternalPriceList && (
        <Modal isOpen>
          <ManageExternalPriceList
            onClose={() => {
              setIsManagingExternalPriceList(false);
              loadImportedPriceLists();
            }}
          />
        </Modal>
      )}
      {isManagingExcessWeightCharges && (
        <Modal isOpen>
          <ManageExcessWeightCharges
            excessWeightChargesMap={wciMap}
            loadWasteContainerInfo={loadWasteContainerInfo}
            onClose={() => {
              setIsManagingExcessWeightCharges(false);
              loadWasteContainerInfo();
            }}
          />
        </Modal>
      )}
      {editingPriceList !== null && (
        <Modal isOpen>
          <EditPriceListItem
            priceListItem={editingPriceList}
            closeModalCb={() => {
              loadPriceList();
              loadWasteContainerInfo();
              setIsEditingPriceList(null);
            }}
          />
        </Modal>
      )}
      {addingPriceListItem && (
        <Modal isOpen>
          <AddPriceListItem
            closeModalCb={() => {
              loadPriceList();
              setAddingPriceListItem(false);
            }}
          />
        </Modal>
      )}
      <Modal isOpen={loading}>
        <div className="flex h-[20vh] w-[20vw] flex-col items-center justify-center gap-2 p-4">
          <img src={LoadingIcon} alt="loading icon" className="size-1/2" />
          <h2 className="text-lg">Loading...</h2>
        </div>
      </Modal>
    </PageWrapper>
  );
};

export default PriceList;
