import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import AccountButton from "../AccountButton";
import SideNav from "../SideNav";
import { getNavLinks } from "../../lib/navLinks";
import { useGlobalContext } from "../../GlobalContext";
import { getSystemRole } from "./../../lib/auth";
import ENV from "../../lib/config";
import SelectedCompany from "../SelectedCompany";

const PageWrapper = ({ children }) => {
  const [navLinks, setNavLinks] = useState([]);
  const { state } = useGlobalContext();

  useEffect(() => {
    setNavLinks(getNavLinks(getSystemRole(), state.selectedCompanyRole));
  }, [state.selectedCompanyRole]);

  return (
    <div className="flex min-h-screen w-full flex-col font-fontFamily">
      <header className="fixed inset-x-0 top-0 z-20 flex h-28 items-center justify-between bg-primaryColor p-10 xl:h-32 2xl:h-36">
        <h1 className="text-xl text-secondaryColor xl:text-2xl 2xl:text-3xl">
          Switch Waste
        </h1>
        <SelectedCompany />
        <AccountButton
          clientId={ENV.get().REACT_APP_OAUTH2_CLIENT_ID}
          loginUrl={ENV.get().REACT_APP_OAUTH2_LOGIN_URL}
        />
      </header>
      <div className="flex flex-1 pt-28 xl:pt-32 2xl:pt-36">
        <div className="fixed bottom-0 top-28 shrink-0 xl:top-32 2xl:top-36">
          <SideNav links={navLinks} />
        </div>
        <div className="ml-64 flex-1 overflow-y-auto bg-backgroundColor p-4">
          <main className="h-full">{children}</main>
        </div>
      </div>
      <footer className="bottom-0 flex h-8 w-full items-center justify-center bg-secondaryColor xl:h-10 2xl:h-12">
        <p className="text-sm text-primaryColor xl:text-base">
          &copy; 2025 Switch Waste. All rights reserved.
        </p>
      </footer>
    </div>
  );
};

PageWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PageWrapper;
