import React, { useEffect, useState } from "react";
import {
  fetchAvailableWhitelabelServices,
  uploadWhitelabelRequestedServices,
} from "../../api/quote";
import WasteServiceType from "../WasteServiceType";
import SelectedWasteService from "../SelectedWasteService";
import { FaLongArrowAltRight } from "react-icons/fa";
import { useNavigate, useOutletContext } from "react-router-dom";
import { getPostcodeAreaDistrict } from "../../lib/address";
import ProgressBar from "../ProgressBar";
import { toast } from "react-toastify";
import { containerSizeOrdering, wasteTypeOrdering } from "../../lib/lookups";
import { getCompanyRedirectStatus } from "../../api/company";
import { PiSmileySad } from "react-icons/pi";

const WasteServiceSelectionWhitelabel = ({
  whitelabelUuid,
  whitelabelCompanyName,
}) => {
  const context = useOutletContext();
  const navigate = useNavigate();
  const customerDetails = context[1];
  const existingServices = context[2];
  const setDesiredServices = context[3];
  const setQuoteSessionUUID = context[5];
  const quoteSessionUUID = context[11];
  const pages = context[12];
  const [services, setServices] = useState(existingServices);
  const [availableServices, setAvailableServices] = useState({});
  const [loadingAvailableServices, setLoadingAvailableServices] =
    useState(true);
  const [showRedirectMessage, setShowRedirectMessage] = useState(false);

  useEffect(() => {
    if (customerDetails?.postcode === "") {
      navigate("/quote/postcode");
      return;
    }
    const postcodeAreaDistrict = getPostcodeAreaDistrict(
      customerDetails?.postcode,
    );
    const setServices = async () => {
      try {
        const data = await fetchAvailableWhitelabelServices(
          customerDetails,
          postcodeAreaDistrict,
        );
        setQuoteSessionUUID(data.quote_session_uuid);
        if (data?.available_services?.length === 0) {
          const redirect = await getCompanyRedirectStatus(whitelabelUuid);
          setShowRedirectMessage(redirect);
        } else {
          const availableServicesByWasteType = {};
          data?.available_services?.forEach((service) => {
            if (availableServicesByWasteType[service.waste_type]) {
              availableServicesByWasteType[service.waste_type].push(
                service.container_type,
              );
            } else {
              availableServicesByWasteType[service.waste_type] = [
                service.container_type,
              ];
            }
          });

          const sortedAvailableServicesByWasteType = Object.keys(
            availableServicesByWasteType,
          )
            .sort(
              (a, b) =>
                (wasteTypeOrdering[a] || Infinity) -
                (wasteTypeOrdering[b] || Infinity),
            )
            .reduce((obj, key) => {
              const containerTypes = availableServicesByWasteType[key];
              const sortedContainerTypes = {
                popular: containerTypes
                  .filter((type) => containerSizeOrdering.popular[type])
                  .sort(
                    (a, b) =>
                      (containerSizeOrdering.popular[a] || Infinity) -
                      (containerSizeOrdering.popular[b] || Infinity),
                  ),
                rest: containerTypes
                  .filter((type) => containerSizeOrdering.rest[type])
                  .sort(
                    (a, b) =>
                      (containerSizeOrdering.rest[a] || Infinity) -
                      (containerSizeOrdering.rest[b] || Infinity),
                  ),
              };
              obj[key] = sortedContainerTypes;
              return obj;
            }, {});

          setAvailableServices(sortedAvailableServicesByWasteType);
        }
        setLoadingAvailableServices(false);
      } catch {
        toast.error("Error fetching available services.");
      }
    };
    setServices();
  }, []);

  const handleSubmit = async (services) => {
    try {
      await uploadWhitelabelRequestedServices(quoteSessionUUID, services);
      setDesiredServices(services);
      navigate("/quote/email");
    } catch (error) {
      toast.error("Error uploading. Please try again.");
      console.error(error);
    }
  };

  return (
    <>
      <ProgressBar
        setPage={(page) => navigate(`/quote/${page}`)}
        currPageIndex={1}
        pages={pages}
      />
      <div className="flex w-full flex-1 flex-col items-center">
        <div className="w-5/6 border-b-2 px-3 py-4 xl:px-6 xl:py-8">
          <div className="flex w-full flex-col items-center justify-center gap-2 text-center text-base lg:text-xl xl:text-2xl">
            {loadingAvailableServices ? (
              <h2 className="">Loading...</h2>
            ) : Object.keys(availableServices).length === 0 ? (
              <>
                <PiSmileySad className="text-lg text-errorColor lg:text-2xl xl:text-3xl" />
                <p>
                  {whitelabelCompanyName} have no available services in your
                  area.
                </p>
              </>
            ) : (
              <h2 className="mb-4 block text-center text-base font-bold text-black md:text-lg lg:mb-8 lg:text-2xl">
                These are the services available in your area. Select at least
                one to continue.
              </h2>
            )}
            {showRedirectMessage && (
              <p>
                Try our partners at{" "}
                <a
                  href="https://commercialwaste.co.uk"
                  className="text-primaryColor hover:text-blue-400"
                  target="blank"
                >
                  commercialwaste.co.uk
                </a>{" "}
                to get an instant quote for your area.
              </p>
            )}
          </div>
          <div className="flex flex-wrap justify-evenly gap-2 lg:gap-4 xl:gap-6">
            {Object.keys(availableServices)?.map((wasteType, index) => {
              return (
                <WasteServiceType
                  wasteType={wasteType}
                  containerTypes={availableServices[wasteType]}
                  key={index}
                  setServices={setServices}
                  onSubmit={() => handleSubmit(services)}
                  services={services}
                />
              );
            })}
          </div>
        </div>
        {Object.keys(availableServices).length > 0 && (
          <div className="w-5/6 px-3 py-4 xl:px-6 xl:py-8">
            <h2 className="mb-4 block text-center text-lg font-bold text-black md:text-xl lg:text-2xl">
              Selected services
            </h2>
            {services?.length === 0 ? (
              <p className="text-center text-base text-black md:text-lg lg:text-xl">
                Add a service to continue your quote.
              </p>
            ) : (
              <div className="w-full px-3 lg:flex lg:px-6">
                <button
                  className="mb-2 w-full rounded-md border border-transparent bg-primaryColor p-2 text-center text-sm text-secondaryColor shadow-md transition-all hover:bg-slate-700 hover:shadow-lg focus:bg-slate-700 focus:shadow-none active:bg-slate-700 active:shadow-none disabled:pointer-events-none disabled:opacity-70 disabled:shadow-none md:w-1/2 md:text-base lg:hidden"
                  onClick={() => handleSubmit(services)}
                  disabled={services?.length == 0}
                >
                  Continue to quotes
                </button>
                <div className="flex w-full flex-wrap gap-2 lg:w-4/5 lg:gap-3 xl:gap-5">
                  {services?.map((service, index) => {
                    return (
                      <SelectedWasteService
                        selectedService={service}
                        setServices={setServices}
                        key={index}
                        index={index}
                      />
                    );
                  })}
                </div>
                <div className="ml-4 hidden w-1/5 lg:block xl:ml-8">
                  <button
                    className="flex w-full flex-col items-center justify-center rounded-md border border-transparent bg-primaryColor p-4 text-center text-lg text-secondaryColor shadow-md transition-all hover:bg-slate-700 hover:shadow-lg focus:bg-slate-700 focus:shadow-none active:bg-slate-700 active:shadow-none disabled:pointer-events-none disabled:opacity-70 disabled:shadow-none xl:text-xl"
                    onClick={() => handleSubmit(services)}
                    disabled={services?.length == 0}
                  >
                    Continue
                    <br />
                    to quotes
                    <FaLongArrowAltRight className="mt-2 w-full" />
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default WasteServiceSelectionWhitelabel;
