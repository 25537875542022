import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  setWhitelabelDomainStyle,
  fetchWhitelabelDomainStlye,
  uploadWhitelabelIcon,
  fetchWhitelabelIcon,
} from "../../api/quote";
import { useGlobalContext } from "../../GlobalContext";
import "react-toastify/dist/ReactToastify.css";
import { HexColorPicker } from "react-colorful";
import Modal from "../Modal";

const WhitelabelStyle = () => {
  const { state } = useGlobalContext();
  const [formData, setFormData] = useState({
    primary_color: "#0f172a",
    secondary_color: "#e9eef5",
    success_color: "#16a34a",
    error_color: "#ef4444",
    background_color: "#ffffff",
    font_family: "Sans Serif",
    headline: "",
    icon_link: "",
  });
  const [formValidation, setFormValidation] = useState({
    primary_color: null,
    secondary_color: null,
    success_color: null,
    error_color: null,
    background_color: null,
  });
  const [iconFile, setIconFile] = useState(null);
  const [iconObjectURL, setIconObjectURL] = useState(null);
  const [isNewIcon, setIsNewIcon] = useState(false);
  const [isColorPickerOpen, setIsColorPickerOpen] = useState(false);
  const [colorPickerField, setColorPickerField] = useState("");
  const [pickedColor, setPickedColor] = useState("");
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const fontFamilies = [
    "Sans Serif",
    "Serif",
    "Courier New",
    "Arial",
    "Helvetica",
    "Times New Roman",
    "Georgia",
    "Roboto",
    "Open Sans",
    "Lato",
    "Montserrat",
    "Verdana",
  ];

  const handleChange = (e) => {
    setShowSuccessMessage(false);
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleHexChange = (e) => {
    const hexRegex = /^#([a-f0-9]{6}|[a-f0-9]{3})$/;
    setShowSuccessMessage(false);

    const { name, value } = e.target;
    if (!hexRegex.test(value)) {
      setFormValidation((prevValidation) => ({
        ...prevValidation,
        [name]: false,
      }));
    } else {
      setFormValidation((prevValidation) => ({
        ...prevValidation,
        [name]: true,
      }));
    }
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const openModal = (field) => {
    setColorPickerField(field);
    setPickedColor(formData[field]);
    setIsColorPickerOpen(true);
  };
  const handleColorChange = (value) => {
    setPickedColor(value);
  };

  const onSubmitColor = () => {
    const hexRegex = /^#([a-f0-9]{6}|[a-f0-9]{3})$/;
    setShowSuccessMessage(false);

    setIsColorPickerOpen(false);
    setFormData((prevData) => ({
      ...prevData,
      [colorPickerField]: pickedColor,
    }));
    if (hexRegex.test(pickedColor)) {
      setFormValidation((prevValidation) => ({
        ...prevValidation,
        [colorPickerField]: true,
      }));
    }
    setColorPickerField("");
    setPickedColor("");
  };
  const closeModal = () => {
    setIsColorPickerOpen(false);
    setColorPickerField("");
    setPickedColor("");
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (isColorPickerOpen) {
      return;
    }
    if (Object.values(formValidation).includes(false)) {
      return;
    }
    const submit = async () => {
      try {
        const newStyles = { ...formData };
        if (newStyles.icon_link === "") {
          newStyles.icon_link = null;
        }
        if (newStyles.headline === "") {
          newStyles.headline = null;
        }
        await setWhitelabelDomainStyle(state.selectedCompany, newStyles);
        if (iconFile) {
          await uploadWhitelabelIcon(state.selectedCompany, iconFile);
        }
        setShowSuccessMessage(true);

        toast.success("Whitelabel style updated");
      } catch (err) {
        console.error(err);
        toast.error(err.message);
      }
    };

    submit();
  };

  useEffect(() => {
    const fetchStyle = async () => {
      try {
        const style = await fetchWhitelabelDomainStlye(state.selectedCompany);
        if (style.icon_link === null) {
          style.icon_link = "";
        }
        if (style.headline === null) {
          style.headline = "";
        }
        setFormData(style);
      } catch (err) {
        if (err.status === 404) return;
        console.error(err);
      }
      try {
        const icon = await fetchWhitelabelIcon(state.selectedCompany);
        setIconFile(icon);
      } catch (err) {
        console.error(err);
      }
    };

    fetchStyle();
  }, [state.selectedCompany]);

  useEffect(() => {
    if (iconFile) {
      if (iconObjectURL) {
        URL.revokeObjectURL(iconObjectURL);
      }

      const newURL = URL.createObjectURL(iconFile);
      setIconObjectURL(newURL);

      return () => {
        URL.revokeObjectURL(newURL);
      };
    }
  }, [iconFile]);

  return (
    <>
      <div className="flex min-w-[300px] max-w-[600px] flex-col items-center rounded bg-slate-200 p-6 shadow-lg">
        <h3 className="mb-2 block text-xl font-bold text-black">
          Styling Configuration
        </h3>

        <p className="mb-3 block text-base text-black">
          Configure the look and feel of the quotation interface for your
          customers.
        </p>
        <form onSubmit={handleSubmit} className="w-full">
          <div className="flex w-full justify-between">
            <label className="mb-2 block text-base font-bold text-black xl:mb-3 xl:text-lg 2xl:mb-4 2xl:text-xl">
              Primary Colour
            </label>
            <div className="flex gap-2 xl:gap-3 2xl:gap-4">
              <div className="w-16 xl:w-20 2xl:w-24">
                <input
                  type="text"
                  name="primary_color"
                  id="primary_color"
                  value={formData.primary_color}
                  onChange={handleHexChange}
                  className={`appearance-none border-2 shadow ${formValidation.primary_color ? "border-successColor ring-successColor" : formValidation.primary_color === false && "border-errorColor ring-errorColor"} h-6 w-full rounded p-1 text-sm leading-tight text-gray-700 focus:outline-none focus:ring xl:h-8 xl:text-base 2xl:h-10 2xl:p-2 2xl:text-lg`}
                />
                <p
                  className={`mt-0.5 text-xs italic text-errorColor xl:text-sm 2xl:text-base ${formValidation.primary_color !== false && "invisible"}`}
                >
                  Invalid hex.
                </p>
              </div>
              <button
                className="size-6 xl:size-8 2xl:size-10"
                onClick={() => openModal("primary_color")}
                style={{ backgroundColor: formData.primary_color }}
              ></button>
            </div>
          </div>
          <div className="flex w-full justify-between">
            <label className="mb-2 block text-base font-bold text-black xl:mb-3 xl:text-lg 2xl:mb-4 2xl:text-xl">
              Secondary Colour
            </label>
            <div className="flex gap-2 xl:gap-3 2xl:gap-4">
              <div className="w-16 xl:w-20 2xl:w-24">
                <input
                  type="text"
                  name="secondary_color"
                  id="secondary_color"
                  value={formData.secondary_color}
                  onChange={handleHexChange}
                  className={`appearance-none border-2 shadow ${formValidation.secondary_color ? "border-successColor ring-successColor" : formValidation.secondary_color === false && "border-errorColor ring-errorColor"} h-6 w-full rounded p-1 text-sm leading-tight text-gray-700 focus:outline-none focus:ring xl:h-8 xl:text-base 2xl:h-10 2xl:p-2 2xl:text-lg`}
                />
                <p
                  className={`mt-0.5 text-xs italic text-errorColor xl:text-sm 2xl:text-base ${formValidation.secondary_color !== false && "invisible"}`}
                >
                  Invalid hex.
                </p>
              </div>
              <button
                className="size-6 xl:size-8 2xl:size-10"
                onClick={() => openModal("secondary_color")}
                style={{ backgroundColor: formData.secondary_color }}
              ></button>
            </div>
          </div>
          <div className="flex w-full justify-between">
            <label className="mb-2 block text-base font-bold text-black xl:mb-3 xl:text-lg 2xl:mb-4 2xl:text-xl">
              Success Colour
            </label>
            <div className="flex gap-2 xl:gap-3 2xl:gap-4">
              <div className="w-16 xl:w-20 2xl:w-24">
                <input
                  type="text"
                  name="success_color"
                  id="success_color"
                  value={formData.success_color}
                  onChange={handleHexChange}
                  className={`appearance-none border-2 shadow ${formValidation.success_color ? "border-successColor ring-successColor" : formValidation.success_color === false && "border-errorColor ring-errorColor"} h-6 w-full rounded p-1 text-sm leading-tight text-gray-700 focus:outline-none focus:ring xl:h-8 xl:text-base 2xl:h-10 2xl:p-2 2xl:text-lg`}
                />
                <p
                  className={`mt-0.5 text-xs italic text-errorColor xl:text-sm 2xl:text-base ${formValidation.success_color !== false && "invisible"}`}
                >
                  Invalid hex.
                </p>
              </div>
              <button
                className="size-6 xl:size-8 2xl:size-10"
                onClick={() => openModal("success_color")}
                style={{ backgroundColor: formData.success_color }}
              ></button>
            </div>
          </div>
          <div className="flex w-full justify-between">
            <label className="mb-2 block text-base font-bold text-black xl:mb-3 xl:text-lg 2xl:mb-4 2xl:text-xl">
              Error Colour
            </label>
            <div className="flex gap-2 xl:gap-3 2xl:gap-4">
              <div className="w-16 xl:w-20 2xl:w-24">
                <input
                  type="text"
                  name="error_color"
                  id="error_color"
                  value={formData.error_color}
                  onChange={handleHexChange}
                  className={`appearance-none border-2 shadow ${formValidation.error_color ? "border-successColor ring-successColor" : formValidation.error_color === false && "border-errorColor ring-errorColor"} h-6 w-full rounded p-1 text-sm leading-tight text-gray-700 focus:outline-none focus:ring xl:h-8 xl:text-base 2xl:h-10 2xl:p-2 2xl:text-lg`}
                />
                <p
                  className={`mt-0.5 text-xs italic text-errorColor xl:text-sm 2xl:text-base ${formValidation.error_color !== false && "invisible"}`}
                >
                  Invalid hex.
                </p>
              </div>
              <button
                className="size-6 xl:size-8 2xl:size-10"
                onClick={() => openModal("error_color")}
                style={{ backgroundColor: formData.error_color }}
              ></button>
            </div>
          </div>
          <div className="flex w-full justify-between">
            <label className="mb-2 block text-base font-bold text-black xl:mb-3 xl:text-lg 2xl:mb-4 2xl:text-xl">
              Background Colour
            </label>
            <div className="flex gap-2 xl:gap-3 2xl:gap-4">
              <div className="w-16 xl:w-20 2xl:w-24">
                <input
                  type="text"
                  name="background_color"
                  id="background_color"
                  value={formData.background_color}
                  onChange={handleHexChange}
                  className={`appearance-none border-2 shadow ${formValidation.background_color ? "border-successColor ring-successColor" : formValidation.background_color === false && "border-errorColor ring-errorColor"} h-6 w-full rounded p-1 text-sm leading-tight text-gray-700 focus:outline-none focus:ring xl:h-8 xl:text-base 2xl:h-10 2xl:p-2 2xl:text-lg`}
                />
                <p
                  className={`mt-0.5 text-xs italic text-errorColor xl:text-sm 2xl:text-base ${formValidation.background_color !== false && "invisible"}`}
                >
                  Invalid hex.
                </p>
              </div>
              <button
                className="size-6 xl:size-8 2xl:size-10"
                onClick={() => openModal("background_color")}
                style={{ backgroundColor: formData.background_color }}
              ></button>
            </div>
          </div>
          <div className="relative mb-2 w-full xl:mb-3 2xl:mb-4">
            <label className="mb-1 block text-base font-bold text-black xl:mb-2 xl:text-lg 2xl:text-xl">
              Font Family
            </label>
            <select
              id="font_family"
              name="font_family"
              value={formData.font_family}
              onChange={handleChange}
              className="h-8 w-full appearance-none rounded border-2 px-2 py-1 text-sm leading-tight text-gray-700 shadow focus:outline-none focus:ring xl:h-12 xl:px-3 xl:py-2 xl:text-lg"
            >
              {fontFamilies.map((font, index) => (
                <option key={index} value={font} style={{ fontFamily: font }}>
                  {font}
                </option>
              ))}
            </select>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.2"
              stroke="currentColor"
              className="absolute right-2.5 top-11 ml-1 size-3 text-gray-700 xl:top-14 xl:size-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M8.25 15 12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"
              />
            </svg>
          </div>
          <div className="mb-2 w-full xl:mb-3 2xl:mb-4">
            <label className="mb-1 block text-base font-bold text-black xl:mb-2 xl:text-lg 2xl:text-xl">
              Headline
            </label>
            <textarea
              type="text"
              id="headline"
              name="headline"
              value={formData.headline}
              onChange={handleChange}
              className="h-16 w-full appearance-none rounded border-2 px-2 py-1 text-sm leading-tight text-gray-700 shadow focus:outline-none focus:ring xl:h-24 xl:px-3 xl:py-2 xl:text-lg"
            />
          </div>
          <div className="mb-2 w-full xl:mb-3 2xl:mb-4">
            <label className="mb-1 block text-base font-bold text-black xl:mb-2 xl:text-lg 2xl:text-xl">
              Icon link
            </label>
            <input
              type="text"
              id="icon_link"
              name="icon_link"
              value={formData.icon_link}
              onChange={handleChange}
              className="h-8 w-full appearance-none rounded border-2 px-2 py-1 text-sm leading-tight text-gray-700 shadow focus:outline-none focus:ring xl:h-12 xl:px-3 xl:py-2 xl:text-lg"
            />
          </div>
          <div className="mb-2 w-full xl:mb-3 2xl:mb-4">
            <label className="mb-1 block text-base font-bold text-black xl:mb-2 xl:text-lg 2xl:text-xl">
              Icon
            </label>
            <div className="flex gap-8 xl:gap-10">
              {iconObjectURL && (
                <img src={iconObjectURL} alt="icon" width="200" />
              )}
              <input
                type="file"
                id="icon"
                accept="image/*"
                onChange={(e) => {
                  setIconFile(e.target.files[0]);
                  setIsNewIcon(true);
                  setShowSuccessMessage(false);
                }}
                className="hidden"
              />
              <div className="flex flex-col items-center justify-center gap-1 xl:gap-2">
                <label
                  htmlFor="icon"
                  className="h-fit rounded-md border border-transparent bg-gray-400 px-4 py-2 text-center text-base text-primaryColor shadow-sm transition-all hover:border-slate-800 hover:bg-slate-800 hover:text-white hover:shadow-lg focus:border-slate-800 focus:bg-slate-800 focus:text-white active:border-slate-800 active:bg-slate-800 active:text-white xl:text-lg 2xl:text-xl"
                >
                  Choose file
                </label>
                <p
                  className={`text-sm xl:text-base 2xl:text-lg ${isNewIcon && "invisible"}`}
                >
                  No file chosen
                </p>
              </div>
            </div>
          </div>
          <div className="mt-4 flex w-full flex-col xl:mt-6 2xl:mt-8">
            <p
              className={`mb-3 text-center text-base text-successColor xl:mb-4 xl:text-lg 2xl:mb-5 2xl:text-xl ${!showSuccessMessage && "invisible"}`}
            >
              Your changes have been saved.
            </p>
            <button
              type="submit"
              className="rounded-md border border-transparent bg-primaryColor px-4 py-2 text-center text-sm text-secondaryColor shadow-md transition-all hover:bg-slate-700 hover:shadow-lg focus:bg-slate-700 focus:shadow-none active:bg-slate-700 active:shadow-none xl:px-6 xl:text-base 2xl:px-8 2xl:text-lg"
            >
              Update
            </button>
          </div>
        </form>
      </div>
      <Modal isOpen={isColorPickerOpen}>
        <div className="flex h-[40vh] w-[30vw] flex-col justify-between p-4 xl:p-6 2xl:p-8 [&_.react-colorful]:h-2/3 [&_.react-colorful]:w-full">
          <HexColorPicker color={pickedColor} onChange={handleColorChange} />
          <div className="flex items-center justify-center">
            <p className="mr-2 text-sm xl:mr-3 xl:text-base 2xl:mr-4 2xl:text-lg">
              Current
            </p>
            <div
              style={{ backgroundColor: formData[colorPickerField] }}
              className="h-6 w-12 xl:h-8 xl:w-16 2xl:h-10 2xl:w-20"
            ></div>
            <div
              style={{ backgroundColor: pickedColor }}
              className="h-6 w-12 xl:h-8 xl:w-16 2xl:h-10 2xl:w-20"
            ></div>
            <p className="ml-2 text-sm xl:ml-3 xl:text-base 2xl:ml-4 2xl:text-lg">
              New
            </p>
          </div>
          <div className="flex justify-center gap-6 xl:gap-8 2xl:gap-10">
            <button
              type="button"
              className="rounded-md border border-transparent bg-secondaryColor px-4 py-2 text-center text-base text-primaryColor shadow-sm transition-all hover:border-slate-800 hover:bg-slate-800 hover:text-white hover:shadow-lg focus:border-slate-800 focus:bg-slate-800 focus:text-white active:border-slate-800 active:bg-slate-800 active:text-white xl:text-lg 2xl:text-xl"
              onClick={closeModal}
            >
              Cancel
            </button>
            <button
              onClick={onSubmitColor}
              type="button"
              className="rounded-md border border-transparent bg-primaryColor px-4 py-2 text-center text-base text-secondaryColor shadow-md transition-all hover:bg-slate-700 hover:shadow-lg focus:bg-slate-700 focus:shadow-none active:bg-slate-700 active:shadow-none xl:text-lg 2xl:text-xl"
            >
              Save
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default WhitelabelStyle;
