import React, { useState, useEffect } from "react";
import "./index.css";
import PropTypes from "prop-types";
import { relativeTimeFormatter } from "../../lib/formatters";
import { singleLineAddress } from "../../lib/formatters";
import { getCompany } from "../../api/company";
import { useGlobalContext } from "../../GlobalContext";

const CompanyDetails = () => {
  const [company, setCompany] = useState(null);
  const { state } = useGlobalContext();

  useEffect(() => {
    const loadCompany = async () => {
      try {
        if (!state.selectedCompany) {
          return;
        }
        const company = await getCompany(state.selectedCompany);
        setCompany(company);
      } catch (error) {
        console.error("Failed to load company", error);
      }
    };
    loadCompany();
  }, [state.selectedCompany]);

  if (!company) {
    return;
  }

  return (
    <div className="company-details-container">
      <div>
        <h2>{company.name}</h2>
        <div className="company-details-item">
          <label>Name</label>
          <div>{company.name}</div>
        </div>
        <div className="company-details-item">
          <label>Types</label>
          <div>
            {company.types
              .map((type) => type.charAt(0).toUpperCase() + type.slice(1))
              .join(", ")}
          </div>
        </div>
        <div className="company-details-item">
          <label>Website</label>
          <div>{company.website || "None"}</div>
        </div>
        <div className="company-details-item">
          <label>Contact Email Address</label>
          <div>{company.contact_email}</div>
        </div>
        <div className="company-details-item">
          <label>Contact Phone Number</label>
          <div>{company.contact_phone_number}</div>
        </div>
        <div className="company-details-item">
          <label>Address</label>
          <div>{singleLineAddress(company)}</div>
        </div>
        <div className="company-details-item">
          <p>
            Created {relativeTimeFormatter(company.created_at)}, last updated{" "}
            {relativeTimeFormatter(company.updated_at)}
          </p>
        </div>
      </div>
    </div>
  );
};

PropTypes.CompanyDetails = {
  company: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    types: PropTypes.arrayOf(PropTypes.string),
    website: PropTypes.string,
    contact_email: PropTypes.string.isRequired,
    contact_phone_number: PropTypes.string.isRequired,
    address_line_1: PropTypes.string.isRequired,
    address_line_2: PropTypes.string,
    town: PropTypes.string.isRequired,
    county: PropTypes.string.isRequired,
    postcode: PropTypes.string.isRequired,
    created_at: PropTypes.string.isRequired,
    updated_at: PropTypes.string.isRequired,
  }).isRequired,
};

export default CompanyDetails;
