import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { updatePriceListItem } from "../../api/quote";
import { useGlobalContext } from "../../GlobalContext";
import { toast } from "react-toastify";
import { CiCirclePlus } from "react-icons/ci";
import { MdDeleteForever } from "react-icons/md";
import { currencyFormat } from "../../lib/formatters";
import { FaCheck } from "react-icons/fa6";
import { RxCross2 } from "react-icons/rx";
import {
  containerTypeNameLookup,
  wasteTypeNameLookup,
} from "../../lib/lookups";

const EditPriceListItem = ({ priceListItem, closeModalCb }) => {
  const { state } = useGlobalContext();

  const [isAddingWeightPrice, setIsAddingWeightPrice] = useState(false);
  const [pricesData, setPricesData] = useState([]);
  const [weightPriceFormData, setWeightPriceFormData] = useState({
    weight_kilos: 1,
    quantity: 1,
    price_gbp: 0,
  });
  const [weightPriceError, setWeightPriceError] = useState(null);

  useEffect(() => {
    const existingPrices = [];
    priceListItem?.waste_weight_prices?.forEach((wasteWeight) => {
      wasteWeight?.bulk_prices?.forEach((bulkPrice) => {
        existingPrices.push({
          weight_kilos: wasteWeight.weight_kilos,
          quantity: bulkPrice.quantity,
          price_gbp: bulkPrice.price_gbp,
        });
      });
    });
    setPricesData(existingPrices);
  }, []);

  const handleWeightPriceOnChange = (e) => {
    const { name, value } = e.target;
    setWeightPriceFormData((prevData) => {
      const updatedData = {
        ...prevData,
        [name]: parseFloat(value),
      };

      return updatedData;
    });
  };

  const handleAddPrice = () => {
    const isValid = validateWeightPrice();
    if (!isValid) {
      return;
    }
    setIsAddingWeightPrice(false);
    setPricesData((prevData) => [...prevData, { ...weightPriceFormData }]);
    setWeightPriceFormData({
      weight_kilos: 1,
      quantity: 1,
      price_gbp: 0,
    });
  };

  const validateWeightPrice = () => {
    if (weightPriceFormData.weight_kilos % 1 !== 0) {
      setWeightPriceError("Weight must be a whole number.");
      return false;
    }
    if (
      weightPriceFormData.quantity % 1 !== 0 ||
      weightPriceFormData.quantity > 5 ||
      weightPriceFormData.quantity < 0
    ) {
      setWeightPriceError("Quantity must be a whole number between 1 and 5");
      return false;
    }
    setWeightPriceError(null);
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (weightPriceError || isAddingWeightPrice) {
      return;
    }
    const groupedPrices = [];
    pricesData.forEach((price) => {
      const { weight_kilos, quantity, price_gbp } = price;
      if (!groupedPrices[weight_kilos]) {
        groupedPrices[weight_kilos] = [];
      }
      groupedPrices[weight_kilos].push({ quantity, price_gbp });
    });

    const wasteWeightPrices = Object.keys(groupedPrices).map(
      (weight_kilos) => ({
        weight_kilos: parseFloat(weight_kilos),
        bulk_prices: groupedPrices[weight_kilos],
      }),
    );
    try {
      await updatePriceListItem(state.selectedCompany, {
        uuid: priceListItem.uuid,
        waste_weight_prices: wasteWeightPrices,
      });
      toast.success("Price list item updated");
      closeModalCb();
    } catch (err) {
      console.error(err);
      if (err.status === 400) {
        toast.error((await err.json()).error);
        return;
      }
      toast.error("Failed to update price list item");
    }
  };

  return (
    <div className="flex w-[35vw] flex-col items-center justify-center p-8 xl:p-10 2xl:p-12">
      <form onSubmit={handleSubmit}>
        <div className="my-3">
          <label
            htmlFor="container_type"
            className="mb-1 block text-base text-gray-800"
          >
            Container Type
          </label>
          <div className="h-8 w-full appearance-none rounded border-2 px-2 py-1 text-sm leading-tight text-gray-700 shadow">
            {containerTypeNameLookup[priceListItem.container_type]}
          </div>
        </div>
        <div className="my-3">
          <label
            htmlFor="waste_type"
            className="mb-1 block text-base text-gray-800"
          >
            Waste Type
          </label>
          <div className="h-8 w-full appearance-none rounded border-2 px-2 py-1 text-sm leading-tight text-gray-700 shadow">
            {wasteTypeNameLookup[priceListItem.waste_type]}
          </div>
        </div>
        <div className="my-3">
          <label
            className="mb-1 block text-base text-gray-800"
            htmlFor="waste_type"
          >
            Postcode Area District
          </label>
          <div className="h-8 w-full appearance-none rounded border-2 px-2 py-1 text-sm leading-tight text-gray-700 shadow">
            {priceListItem.postcode_area_district}
          </div>
        </div>
        <div className="my-3">
          <p className="mb-1 block text-base text-gray-800">Prices</p>
          <p className="mb-3 text-sm italic text-gray-600">
            You can configure multiple prices for the same Waste and Container
            type based on weight and bulk discounts.
          </p>
          <table className="mb-3 w-full table-fixed border-2 text-left text-sm text-gray-500">
            <thead className="bg-gray-100 text-xs uppercase text-gray-700">
              <tr>
                <th className="border-r-2 px-3 py-2">Weight Limit</th>
                <th className="border-r-2 px-3 py-2">Min. Quantity</th>
                <th className="border-r-2 px-3 py-2">Price per Bin</th>
                <th className="w-16" />
              </tr>
            </thead>
            <tbody className="w-full">
              {pricesData.map((weightPrice, index) => (
                <tr key={index}>
                  <td className="border-r-2 px-3 py-2 text-sm text-gray-900">
                    {weightPrice.weight_kilos} Kg
                  </td>
                  <td className="border-r-2 px-3 py-2 text-sm text-gray-900">
                    {weightPrice.quantity}
                  </td>
                  <td className="border-r-2 px-3 py-2 text-sm text-gray-900">
                    {currencyFormat(weightPrice.price_gbp)}
                  </td>
                  <td className="px-3 py-2">
                    <MdDeleteForever
                      className="cursor-pointer text-lg text-red-500"
                      onClick={() => {
                        setPricesData((prevData) => {
                          const newData = [...prevData];
                          newData.splice(index, 1);
                          return newData;
                        });
                      }}
                    />
                  </td>
                </tr>
              ))}

              {isAddingWeightPrice && (
                <tr className="w-full">
                  <td className="border-r-2 px-3 py-2 text-sm text-gray-900">
                    <input
                      type="number"
                      id="weight_kilos"
                      name="weight_kilos"
                      className="w-full"
                      value={weightPriceFormData.weight_kilos}
                      onChange={handleWeightPriceOnChange}
                      step="1"
                      required
                    />
                  </td>
                  <td className="border-r-2 px-3 py-2 text-sm text-gray-900">
                    <input
                      type="number"
                      id="quantity"
                      name="quantity"
                      className="w-full"
                      value={weightPriceFormData.quantity}
                      onChange={handleWeightPriceOnChange}
                      step="1"
                      required
                    />
                  </td>
                  <td className="border-r-2 px-3 py-2 text-sm text-gray-900">
                    <input
                      type="number"
                      id="price_gbp"
                      name="price_gbp"
                      className="w-full"
                      value={weightPriceFormData.price_gbp}
                      onChange={handleWeightPriceOnChange}
                      step="0.01"
                      required
                    />
                  </td>
                  <td className="flex px-3 py-2">
                    <FaCheck
                      className="mr-2 cursor-pointer text-lg text-green-500"
                      onClick={handleAddPrice}
                    />
                    <RxCross2
                      className="cursor-pointer text-lg text-red-500"
                      onClick={() => {
                        setIsAddingWeightPrice(false);
                        setWeightPriceError(null);
                        setWeightPriceFormData({
                          weight_kilos: 1,
                          quantity: 1,
                          price_gbp: 0,
                        });
                      }}
                    />
                  </td>
                </tr>
              )}
              {!isAddingWeightPrice ? (
                <tr>
                  <td
                    colSpan={3}
                    className="px-3 py-2 text-center text-sm text-gray-900"
                  >
                    <CiCirclePlus
                      className="mx-auto cursor-pointer text-xl text-green-500"
                      onClick={() => {
                        setWeightPriceFormData({
                          weight_kilos: 1,
                          quantity: 1,
                          price_gbp: 0,
                        });
                        setIsAddingWeightPrice(true);
                      }}
                    />
                  </td>
                </tr>
              ) : (
                <tr>
                  <td
                    colSpan={3}
                    className="px-3 py-2 text-center text-sm text-errorColor"
                  >
                    <p>{weightPriceError}</p>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <div className="mt-6 flex w-full justify-center gap-4">
          <button
            type="submit"
            className="h-8 rounded-md border border-transparent bg-primaryColor px-2 py-1 text-center text-sm text-secondaryColor shadow-md transition-all hover:bg-slate-700 hover:shadow-lg focus:bg-slate-700 focus:shadow-none active:bg-slate-700 active:shadow-none"
          >
            Save
          </button>
          <button
            type="button"
            onClick={closeModalCb}
            className="h-8 rounded-md border border-transparent bg-secondaryColor px-2 py-1 text-center text-sm text-primaryColor shadow-md transition-all hover:border-slate-800 hover:bg-slate-800 hover:text-white hover:shadow-lg focus:border-slate-800 focus:bg-slate-800 focus:text-white active:border-slate-800 active:bg-slate-800 active:text-white"
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

EditPriceListItem.propTypes = {
  wasteContainerInfo: PropTypes.object,
  closeModalCb: PropTypes.func.isRequired,
};

export default EditPriceListItem;
