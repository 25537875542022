// Helper function to extract address components
export const extractAddressParts = (components) => {
  let addressLine1 = "";
  let addressLine2 = "";
  let town = "";
  let postcode = "";
  let county = "";
  let premise = "";

  components.forEach((component) => {
    const types = component.types;
    if (types.includes("street_number")) {
      addressLine1 = component.long_name + " " + addressLine1;
    }
    if (types.includes("route")) {
      addressLine1 += component.long_name;
    }
    if (types.includes("locality")) {
      addressLine2 = component.long_name;
    }
    if (types.includes("postal_town")) {
      town = component.long_name;
    }
    if (types.includes("postal_code")) {
      postcode = component.long_name;
    }
    if (types.includes("administrative_area_level_2")) {
      county = component.long_name;
    }
    if (types.includes("premise")) {
      premise = component.long_name;
    }
  });

  if (addressLine1 === "") {
    if (premise) {
      addressLine1 = premise;
    } else if (addressLine2) {
      addressLine1 = addressLine2;
      addressLine2 = "";
    }
  }
  return { addressLine1, addressLine2, town, postcode, county };
};
