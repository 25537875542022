import "./index.css";
import React, { useState, useEffect } from "react";
import PageWrapper from "../../components/PageWrapper";
import PopoutModal from "../../components/PopoutModal";
import EditWasteContainerInfo from "../../components/EditWasteContainerInfo";
import AddWasteContainerInfo from "../../components/AddWasteContainerInfo";
import {
  fetchWasteContainerInfo,
  deleteWasteContainerInfo,
} from "../../api/quote";
import {
  currencyFormat,
  weightFormat,
  relativeTimeFormatter,
} from "../../lib/formatters";
import { MdModeEdit, MdDeleteForever } from "react-icons/md";
import { IoMdAddCircle } from "react-icons/io";
import SelectedCompany from "../../components/SelectedCompany";
import { useGlobalContext } from "../../GlobalContext";

const WasteContainerInfo = () => {
  const { state } = useGlobalContext();
  const [wciList, setWciList] = useState(null);
  const [editingWci, setEditingWci] = useState(null);
  const [isAddingWci, setIsAddingWci] = useState(false);

  const loadData = async () => {
    try {
      const wasteContainerInfo = await fetchWasteContainerInfo(
        state.selectedCompany,
      );
      setWciList(wasteContainerInfo);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    loadData();
  }, [state.selectedCompany]);

  return (
    <PageWrapper>
      <SelectedCompany />
      <div className="waste-container-info-container">
        <h2>Waste Container Info</h2>
        <IoMdAddCircle
          onClick={() => setIsAddingWci(true)}
          className="add-icon"
        />
        <div className="waste-container-info-table-wrapper">
          <table className="waste-container-info-table">
            <thead>
              <tr>
                <th>Container Type</th>
                <th>Waste Type</th>
                <th>Excess Weight Charge</th>
                <th>Weight Restriction</th>
                <th>Last Updated</th>
                <th>Updated By</th>
                <th />
                <th />
              </tr>
            </thead>
            <tbody>
              {wciList &&
                wciList.map((wci) => (
                  <tr key={`${wci.container_type}.${wci.waste_type}`}>
                    <td>{wci.container_type}</td>
                    <td>{wci.waste_type}</td>
                    <td>{currencyFormat(wci.excess_weight_charge_gbp)}</td>
                    <td>{weightFormat(wci.weight_restriction_kilos)}</td>
                    <td>{relativeTimeFormatter(wci.updated_at)}</td>
                    <td>Jon McCormack</td>
                    <td>
                      <MdModeEdit
                        onClick={() => setEditingWci(wci)}
                        className="edit-icon"
                      />
                    </td>
                    <td>
                      <MdDeleteForever
                        onClick={() => {
                          deleteWasteContainerInfo(
                            state.selectedCompany,
                            wci.container_type,
                            wci.waste_type,
                          ).then(() => loadData());
                        }}
                        className="delete-icon"
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      <PopoutModal isOpen={editingWci !== null}>
        <EditWasteContainerInfo
          wasteContainerInfo={editingWci}
          closeModalCb={() => {
            loadData();
            setEditingWci(null);
          }}
        />
      </PopoutModal>
      <PopoutModal isOpen={isAddingWci}>
        <AddWasteContainerInfo
          closeModalCb={() => {
            loadData();
            setIsAddingWci(false);
          }}
        />
      </PopoutModal>
    </PageWrapper>
  );
};

export default WasteContainerInfo;
