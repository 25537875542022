import React from "react";
import PropTypes from "prop-types";
import { currencyFormat } from "../../lib/formatters";

const QuoteAccepted = ({
  quote,
  userDetails,
  frequencyLookup,
  wasteTypeNameLookup,
}) => {
  return (
    <div className="w-full p-4 xl:p-6 2xl:p-8">
      <p className="text-black-700 border-b-2 border-slate-600 pb-5 text-lg xl:pb-8 xl:text-xl 2xl:pb-12 2xl:text-2xl">
        Thank you for accepting the quote. An email with the details has been
        sent to <span className="italic">{userDetails.email}</span>. The
        supplier will get in touch with you about the sale.
      </p>
      <h2 className="text-black-700 mb-2 mt-5 text-xl font-bold xl:mb-3 xl:mt-8 xl:text-2xl 2xl:mb-4 2xl:mt-12 2xl:text-3xl">
        Quote Details
      </h2>
      <p className="text-black-700 text-md mb-2 xl:mb-3 xl:text-lg 2xl:mb-4 2xl:text-xl">
        <strong>Supplier:</strong> {quote.supplier_uuid}
      </p>
      <p className="text-black-700 text-md border-b-2 border-slate-600 pb-5 xl:pb-8 xl:text-lg 2xl:pb-12 2xl:text-xl">
        <strong>Total Monthly Price:</strong> {currencyFormat(quote.price_gbp)}
      </p>
      <h2 className="text-black-700 mb-2 mt-5 text-xl font-bold xl:mb-3 xl:mt-8 xl:text-2xl 2xl:mb-4 2xl:mt-12 2xl:text-3xl">
        Service Breakdown
      </h2>
      <div className="relative w-full overflow-x-auto">
        <table className="w-full table-auto text-left text-sm text-gray-500">
          <thead className="bg-gray-100 text-xs uppercase text-gray-700">
            <tr>
              <th className="px-3 py-2 xl:px-6 xl:py-3">Type</th>
              <th className="px-3 py-2 xl:px-6 xl:py-3">Container</th>
              <th className="px-3 py-2 xl:px-6 xl:py-3">Price</th>
              <th className="px-3 py-2 xl:px-6 xl:py-3">Quantity</th>
              <th className="px-3 py-2 xl:px-6 xl:py-3">Frequency</th>
              <th className="px-3 py-2 xl:px-6 xl:py-3">Monthly Price</th>
              <th className="px-3 py-2 xl:px-6 xl:py-3">
                Excess Weight Charge
              </th>
              <th className="px-3 py-2 xl:px-6 xl:py-3">Weight Restriction</th>
            </tr>
          </thead>
          <tbody>
            {quote.price_breakdown.map((breakdown) => (
              <tr
                key={`${breakdown.type}.${breakdown.container}`}
                className="border-b bg-white"
              >
                <td className="xl:text-md px-3 py-2 text-sm text-gray-900 xl:px-6 xl:py-3">
                  {wasteTypeNameLookup[breakdown.type]}
                </td>
                <td className="xl:text-md px-3 py-2 text-sm text-gray-900 xl:px-6 xl:py-3">
                  {breakdown.container}
                </td>
                <td className="xl:text-md px-3 py-2 text-sm text-gray-900 xl:px-6 xl:py-3">
                  {currencyFormat(breakdown.price_gbp)}
                </td>
                <td className="xl:text-md px-3 py-2 text-sm text-gray-900 xl:px-6 xl:py-3">
                  {breakdown.quantity}
                </td>
                <td className="xl:text-md px-3 py-2 text-sm text-gray-900 xl:px-6 xl:py-3">
                  {frequencyLookup[breakdown.frequency]}
                </td>
                <td className="xl:text-md px-3 py-2 text-sm text-gray-900 xl:px-6 xl:py-3">
                  {currencyFormat(breakdown.total_price_gbp)}
                </td>
                <td className="xl:text-md px-3 py-2 text-sm text-gray-900 xl:px-6 xl:py-3">
                  {breakdown.excess_weight_charge_gbp
                    ? currencyFormat(breakdown.excess_weight_charge_gbp)
                    : "None"}
                </td>
                <td className="xl:text-md px-3 py-2 text-sm text-gray-900 xl:px-6 xl:py-3">
                  {breakdown.weight_restriction_kilos ?? "None"}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

QuoteAccepted.propTypes = {
  quote: PropTypes.shape({
    supplier_uuid: PropTypes.string.isRequired,
    price_gbp: PropTypes.number.isRequired,
    price_breakdown: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string.isRequired,
        container: PropTypes.string.isRequired,
        price_gbp: PropTypes.number.isRequired,
        quantity: PropTypes.number.isRequired,
        total_price_gbp: PropTypes.number.isRequired,
        excess_weight_charge_gbp: PropTypes.number,
        weight_restriction_kilos: PropTypes.number,
      }),
    ).isRequired,
  }).isRequired,
  userDetails: PropTypes.shape({
    email: PropTypes.string.isRequired,
  }).isRequired,
  frequencyLookup: PropTypes.object.isRequired,
  wasteTypeNameLookup: PropTypes.object.isRequired,
};

export default QuoteAccepted;
