import { getAccessToken } from "../lib/auth";
import { getQuoteApiUrl } from "./main";

export const createInternalQuote = async (
  postcode,
  requestedServices,
  companyUuid,
) => {
  const response = await fetch(
    getQuoteApiUrl(`/quote/internal/company/${companyUuid}`),
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        postcode: postcode,
        requested_services: requestedServices,
      }),
    },
  );

  if (response.ok) {
    return await response.json();
  } else {
    throw response;
  }
};

export const createWhitelabelQuote = async (
  email,
  postcode,
  requestedServices,
  quoteSessionUUID,
) => {
  const response = await fetch(getQuoteApiUrl("/quote/whitelabel"), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      quote_session_uuid: quoteSessionUUID,
      postcode_email_details: { email, postcode },
      requested_services: requestedServices,
    }),
  });

  if (response.ok) {
    return await response.json();
  } else {
    throw response;
  }
};

export const acceptInternalQuote = async (quoteSessionUUID, quoteUUID) => {
  const response = await fetch(
    getQuoteApiUrl(`/quote/internal/${quoteSessionUUID}/${quoteUUID}/accept`),
    {
      method: "POST",
    },
  );

  if (response.ok) {
    return await response.json();
  } else {
    console.error("Failed to change quote status");
    throw new Error("Failed to change quote status");
  }
};

export const acceptQuoteNoDetailsCollected = async (
  quoteSessionUUID,
  quoteUUID,
) => {
  const response = await fetch(
    getQuoteApiUrl(`/quote/${quoteSessionUUID}/${quoteUUID}/accept_no_details`),
    {
      method: "POST",
    },
  );

  if (response.ok) {
    return await response.json();
  } else {
    console.error("Failed to change quote status");
    throw new Error("Failed to change quote status");
  }
};
export const acceptAlternativeQuoteNoDetailsCollected = async (
  quoteSessionUUID,
  quote,
) => {
  const response = await fetch(
    getQuoteApiUrl(
      `/quote/whitelabel/${quoteSessionUUID}/alternative/accept_no_details`,
    ),
    {
      method: "POST",
      body: JSON.stringify(quote),
    },
  );

  if (response.ok) {
    return await response.json();
  } else {
    console.error("Failed to change quote status");
    throw new Error("Failed to change quote status");
  }
};

export const acceptQuote = async (
  customerDetails,
  quoteSessionUUID,
  quoteUUID,
  pdfBytes,
) => {
  const formData = new FormData();
  formData.append("customer_details", JSON.stringify(customerDetails));
  formData.append("pdf", pdfBytes);
  const response = await fetch(
    getQuoteApiUrl(`/quote/${quoteSessionUUID}/${quoteUUID}/accept`),
    {
      method: "POST",
      body: formData,
    },
  );

  if (response.ok) {
    return await response.json();
  } else {
    console.error("Failed to accept quote");
    throw new Error("Failed to accept quote");
  }
};

export const patchInternalQuote = async (
  customerDetails,
  quoteSessionUUID,
  quoteUUID,
  pdfBytes,
) => {
  const formData = new FormData();
  formData.append("customer_details", JSON.stringify(customerDetails));
  formData.append("pdf", pdfBytes);
  const response = await fetch(
    getQuoteApiUrl(`/quote/internal/${quoteSessionUUID}/${quoteUUID}`),
    {
      method: "PATCH",
      body: formData,
    },
  );

  if (response.ok) {
    return await response.json();
  } else {
    console.error("Failed to accept quote");
    throw new Error("Failed to accept quote");
  }
};

export const fetchAvailableInternalServices = async (
  postcodeAreaDistrict,
  companyUuid,
) => {
  const response = await fetch(
    getQuoteApiUrl(
      `/quote/internal/${companyUuid}/available-services/${postcodeAreaDistrict}`,
    ),
  );

  if (response.ok) {
    return await response.json();
  } else {
    console.error("Failed to fetch available services");
    throw new Error("Failed to fetch available services");
  }
};

export const fetchAvailableWhitelabelServices = async (
  customerDetails,
  postcodeAreaDistrict,
) => {
  const response = await fetch(
    getQuoteApiUrl(
      `/quote/whitelabel/available-services/${postcodeAreaDistrict}`,
    ),
    {
      method: "POST",
      body: JSON.stringify(customerDetails),
    },
  );

  if (response.ok) {
    return await response.json();
  } else {
    console.error("Failed to fetch available services");
    throw new Error("Failed to fetch available services");
  }
};

export const uploadWhitelabelRequestedServices = async (
  quoteSessionUUID,
  requestedServices,
) => {
  const response = await fetch(
    getQuoteApiUrl(`/quote/whitelabel/requested-services`),
    {
      method: "POST",
      body: JSON.stringify({
        uuid: quoteSessionUUID,
        requested_services: requestedServices,
      }),
    },
  );

  if (response.ok) {
    return;
  } else {
    console.error("Failed to fetch available services");
    throw new Error("Failed to fetch available services");
  }
};

export const fetchCompanyQuotes = async (companyUuid, startDate, endDate) => {
  const response = await fetch(
    getQuoteApiUrl(
      `/quote/company/${companyUuid}?startDate=${startDate}&endDate=${endDate}`,
    ),
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getAccessToken()}`,
      },
    },
  );

  if (response.ok) {
    return await response.json();
  } else {
    throw new Error("Failed to fetch company quotes");
  }
};

export const fetchQuotePDF = async (companyUuid, quoteUUID) => {
  const response = await fetch(
    getQuoteApiUrl(`/quote/company/${companyUuid}/pdf/${quoteUUID}`),
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getAccessToken()}`,
      },
    },
  );

  if (response.ok) {
    return await response.blob();
  } else {
    throw new Error("Failed to fetch quote pdf");
  }
};

export const fetchInternalQuote = async (companyUuid, quoteUUID) => {
  const response = await fetch(
    getQuoteApiUrl(`/quote/company/${companyUuid}/quote/${quoteUUID}`),
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getAccessToken()}`,
      },
    },
  );

  if (response.ok) {
    return await response.json();
  } else {
    throw new Error("Failed to fetch quote");
  }
};

export const fetchCompanyLostLeads = async (
  companyUuid,
  startDate,
  endDate,
) => {
  const response = await fetch(
    getQuoteApiUrl(
      `/quote/company/lost-leads/${companyUuid}?startDate=${startDate}&endDate=${endDate}`,
    ),
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getAccessToken()}`,
      },
    },
  );

  if (response.ok) {
    return await response.json();
  } else {
    throw new Error("Failed to fetch company lost leads");
  }
};

export const fetchPriceList = async (companyUuid, pageNumber, searchTerm) => {
  const queryParams = new URLSearchParams();
  queryParams.append("page", pageNumber);
  if (searchTerm) {
    queryParams.append("search", searchTerm);
  }
  const response = await fetch(
    getQuoteApiUrl(`/pricing/${companyUuid}?${queryParams.toString()}`),
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getAccessToken()}`,
      },
    },
  );

  if (response.ok) {
    return await response.json();
  } else {
    throw new Error("Failed to fetch price list");
  }
};

export const fetchPriceListCSV = async (companyUuid) => {
  const response = await fetch(
    getQuoteApiUrl(`/pricing/export/${companyUuid}`),
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getAccessToken()}`,
      },
    },
  );

  if (response.ok) {
    return await response.blob();
  } else {
    throw new Error("Failed to fetch price list");
  }
};

export const getPublicPriceList = async (companyUuid) => {
  const response = await fetch(
    getQuoteApiUrl(`/pricing/public-price-list/${companyUuid}`),
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getAccessToken()}`,
      },
    },
  );

  if (response.ok) {
    return await response.json();
  } else {
    throw new Error("Failed to fetch price list");
  }
};

export const uploadPriceList = async (companyUuid, file) => {
  const formData = new FormData();
  formData.append("price_list", file);

  const response = await fetch(
    getQuoteApiUrl(`/pricing/${companyUuid}/csv-upload`),
    {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    },
  );

  if (response.ok) {
    return;
  } else {
    // Handle error
    console.error("Failed to upload price list");
    throw new Error("Failed to upload price list");
  }
};

export const uploadPriceListWithPostcodes = async (
  companyUuid,
  file,
  postcodes,
) => {
  const formData = new FormData();
  formData.append("price_list", file);
  formData.append("postcode_area_districts", JSON.stringify(postcodes));

  const response = await fetch(
    getQuoteApiUrl(`/pricing/${companyUuid}/csv-upload/postcode`),
    {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    },
  );

  if (response.ok) {
    return;
  } else {
    throw response;
  }
};

export const fetchWasteContainerInfo = async (companyUuid) => {
  const response = await fetch(
    getQuoteApiUrl(`/pricing/company-waste-container-info/${companyUuid}`),
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getAccessToken()}`,
      },
    },
  );

  if (response.ok) {
    return await response.json();
  } else {
    console.error("Failed to fetch waste containers");
    throw new Error("Failed to fetch waste containers");
  }
};

export const patchWasteContainerInfo = async (
  companyUuid,
  wasteContainerInfo,
) => {
  const response = await fetch(
    getQuoteApiUrl(`/pricing/company-waste-container-info/${companyUuid}`),
    {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: JSON.stringify(wasteContainerInfo),
    },
  );

  if (response.ok) {
    return;
  } else {
    throw response;
  }
};

export const createWasteContainerInfo = async (
  companyUuid,
  wasteContainerInfo,
) => {
  const response = await fetch(
    getQuoteApiUrl(`/pricing/company-waste-container-info/${companyUuid}`),
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: JSON.stringify(wasteContainerInfo),
    },
  );

  if (response.ok) {
    return;
  } else {
    throw response;
  }
};

export const deleteWasteContainerInfo = async (companyUuid, wasteType) => {
  const response = await fetch(
    getQuoteApiUrl(
      `/pricing/company-waste-container-info/${companyUuid}?waste_type=${encodeURIComponent(wasteType)}`,
    ),
    {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    },
  );

  if (response.ok) {
    return;
  } else {
    console.error("Failed to delete waste containers");
    throw new Error("Failed to delete waste containers");
  }
};

export const postPriceListItem = async (companyUuid, priceListItem) => {
  const response = await fetch(getQuoteApiUrl(`/pricing/${companyUuid}`), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAccessToken()}`,
    },
    body: JSON.stringify(priceListItem),
  });

  if (response.ok) {
    return;
  } else {
    console.error("Failed to post price list item");
    throw response;
  }
};

export const updatePriceListItem = async (companyUuid, priceListItem) => {
  const response = await fetch(getQuoteApiUrl(`/pricing/${companyUuid}`), {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAccessToken()}`,
    },
    body: JSON.stringify(priceListItem),
  });

  if (response.ok) {
    return;
  } else {
    console.error("Failed to update price list item");
    throw response;
  }
};

export const deletePriceListItem = async (companyUuid, priceListItemUuid) => {
  const response = await fetch(
    getQuoteApiUrl(
      `/pricing/${companyUuid}?price_list_item=${priceListItemUuid}`,
    ),
    {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    },
  );

  if (response.ok) {
    return;
  } else {
    console.error("Failed to delete price list item");
    throw new Error("Failed to delete price list item");
  }
};

export const deleteAllCompanyPriceListItems = async (companyUuid) => {
  const response = await fetch(getQuoteApiUrl(`/pricing/all/${companyUuid}`), {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });

  if (response.ok) {
    return;
  } else {
    console.error("Failed to delete price list items");
    throw new Error("Failed to delete price list items");
  }
};

export const setPriceListVisibility = async (companyUuid, visible) => {
  const response = await fetch(
    getQuoteApiUrl(
      `/pricing/${companyUuid}/visibility/${visible ? "public" : "private"}`,
    ),
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getAccessToken()}`,
      },
    },
  );

  if (response.ok) {
    return;
  } else {
    throw response;
  }
};

export const getPriceListVisibility = async (companyUuid) => {
  const response = await fetch(
    getQuoteApiUrl(`/pricing/${companyUuid}/visibility`),
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    },
  );

  if (response.ok) {
    return (await response.json()).visibility;
  } else {
    throw response;
  }
};

export const fetchRefererWhitelabelDetails = async () => {
  const response = await fetch(
    getQuoteApiUrl(`/whitelabel/domain/referer-whitelabel`),
    { method: "GET" },
  );

  if (response.ok) {
    return await response.json();
  } else {
    throw new Error("Failed to fetch referer whitelabel details");
  }
};

export const fetchWhitelabelDetails = async (companyUuid) => {
  const response = await fetch(
    getQuoteApiUrl(`/whitelabel/domain/${companyUuid} `),
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    },
  );

  if (response.ok) {
    return await response.json();
  } else {
    throw response;
  }
};

export const createWhitelabelDomain = async (companyUuid, domain) => {
  const response = await fetch(
    getQuoteApiUrl(`/whitelabel/domain/${companyUuid}`),
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: JSON.stringify({ domain }),
    },
  );

  if (response.ok) {
    return;
  } else {
    throw new Error("Failed to create whitelabel domain");
  }
};

export const deleteWhitelabelDomain = async (companyUuid) => {
  const response = await fetch(
    getQuoteApiUrl(`/whitelabel/domain/${companyUuid}`),
    {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    },
  );

  if (response.ok) {
    return;
  } else {
    throw new Error("Failed to delete whitelabel domain");
  }
};

export const verifyWhitelabelDomain = async (companyUuid) => {
  const response = await fetch(
    getQuoteApiUrl(`/whitelabel/domain/${companyUuid}/verify`),
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    },
  );

  if (response.ok) {
    return;
  } else {
    throw new Error("Failed to verify whitelabel domain");
  }
};

export const setWhitelabelDomainStyle = async (companyUuid, style) => {
  const response = await fetch(
    getQuoteApiUrl(`/whitelabel/domain/${companyUuid}/style`),
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: JSON.stringify(style),
    },
  );

  if (response.ok) {
    return;
  } else {
    if (response.status === 400) {
      const error = await response.json();
      throw new Error(error.error);
    }
    throw new Error("Failed to set whitelabel domain style");
  }
};

export const fetchWhitelabelDomainStlye = async (companyUuid) => {
  const response = await fetch(
    getQuoteApiUrl(`/whitelabel/domain/${companyUuid}/style`),
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    },
  );

  if (response.ok) {
    return await response.json();
  } else {
    throw response;
  }
};

export const uploadWhitelabelIcon = async (companyUuid, file) => {
  const formData = new FormData();
  formData.append("icon", file);

  const response = await fetch(
    getQuoteApiUrl(`/whitelabel/domain/${companyUuid}/style/icon`),
    {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    },
  );

  if (response.ok) {
    return;
  } else {
    throw new Error("Failed to upload whitelabel icon");
  }
};

export const fetchWhitelabelIcon = async (companyUuid) => {
  const response = await fetch(
    getQuoteApiUrl(`/whitelabel/domain/${companyUuid}/style/icon`),
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    },
  );

  if (response.ok) {
    return await response.blob();
  } else {
    throw response;
  }
};

export const getRefererWhitelabelIcon = async () => {
  const response = await fetch(
    getQuoteApiUrl(`/whitelabel/domain/referer-whitelabel/icon`),
    {
      method: "GET",
    },
  );

  if (response.ok) {
    return await response.blob();
  } else {
    throw response;
  }
};

export const getPriceListImports = async (companyUuid) => {
  const response = await fetch(
    getQuoteApiUrl(`/pricing/${companyUuid}/imports`),
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    },
  );

  if (response.ok) {
    return await response.json();
  } else {
    throw response;
  }
};

export const getPublicPriceLists = async () => {
  const response = await fetch(getQuoteApiUrl("/pricing/public-price-lists"), {
    method: "GET",
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });

  if (response.ok) {
    return await response.json();
  } else {
    throw response;
  }
};

export const createPriceListImport = async (
  companyUuid,
  supplierUuid,
  markupFactor,
  redactSupplierDetails,
) => {
  const response = await fetch(
    getQuoteApiUrl(`/pricing/${companyUuid}/imports`),
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: JSON.stringify({
        supplier_uuid: supplierUuid,
        markup_factor: markupFactor,
        redact_supplier_details: redactSupplierDetails,
      }),
    },
  );

  if (response.ok) {
    return;
  } else {
    throw response;
  }
};

export const deletePriceListImport = async (companyUuid, supplierUuid) => {
  const response = await fetch(
    getQuoteApiUrl(`/pricing/${companyUuid}/imports/${supplierUuid}`),
    {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    },
  );

  if (response.ok) {
    return;
  } else {
    throw response;
  }
};

export const getPostcodes = async () => {
  const response = await fetch(getQuoteApiUrl(`/postcode`), {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (response.ok) {
    return await response.json();
  } else {
    throw response;
  }
};
