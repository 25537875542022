import React, { useState, useEffect, useRef } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Error from "./pages/Error";
import Company from "./pages/Company";
import OAuthCallback from "./pages/OAuthCallback";
import CompanyUsers from "./pages/CompanyUsers";
import PriceList from "./pages/PriceList";
import Whitelabel from "./pages/Whitelabel";
import { fetchRefererWhitelabelDetails } from "./api/quote";
import { ToastContainer } from "react-toastify";
import SystemUsers from "./pages/SystemUsers";
import CompanyManagement from "./pages/CompanyManagement";
import CreateCompany from "./pages/CreateCompany";
import EmailCollection from "./components/EmailCollection";
import CustomerDetailsCollection from "./components/CustomerDetailsCollection";
import QuoteAccepted from "./components/QuoteAccepted";
import QuoteManagement from "./pages/QuoteManagement";
import QuoteInternal from "./pages/QuoteInternal";
import PostcodeCollectionInternal from "./components/PostcodeCollectionInternal";
import WasteServiceSelectionInternal from "./components/WasteServiceSelectionInternal";
import QuoteSelectionInternal from "./components/QuoteSelectionInternal";
import QuoteDetails from "./pages/QuoteManagement/QuoteDetails";
import QuoteWhitelabel from "./pages/QuoteWhitelabel";
import PostcodeCollectionWhitelabel from "./components/PostcodeCollectionWhitelabel";
import WasteServiceSelectionWhitelabel from "./components/WasteServiceSelectionWhitelabel";
import QuoteSelectionWhitelabel from "./components/QuoteSelectionWhitelabel";
import ImportWizard from "./pages/PriceList/ImportWizard";
import LostLeadManagement from "./pages/LostLeads";
import { getCompanyPublicDetails } from "./api/company";
import { setTokenRefreshTimeout } from "./lib/auth";
import ScrollToTop from "./components/ScrollToTop";

export const App = () => {
  const [whitelabelLoading, setWhitelabelLoading] = useState(true);
  const hasRunRef = useRef(false);
  const [isWhitelabel, setIsWhitelabel] = useState(false);
  const [whitelabelIconLink, setWhitelabelIconLink] = useState("");
  const [whitelabelCompanyName, setWhitelabelCompanyName] = useState("");
  const [whitelabelUuid, setWhitelabelUuid] = useState(null);
  const [whitelabelHeadline, setWhitelabelHeadline] = useState("");

  useEffect(() => {
    const fetchWhitelabelDetails = async () => {
      try {
        const details = await fetchRefererWhitelabelDetails();
        if (details) {
          document.documentElement.style.setProperty(
            "--primary-color",
            details.primary_color,
          );
          document.documentElement.style.setProperty(
            "--secondary-color",
            details.secondary_color,
          );
          document.documentElement.style.setProperty(
            "--success-color",
            details.success_color,
          );
          document.documentElement.style.setProperty(
            "--error-color",
            details.error_color,
          );
          document.documentElement.style.setProperty(
            "--background-color",
            details.background_color,
          );
          document.documentElement.style.setProperty(
            "--font-family",
            details.font_family,
          );

          const [company] = await getCompanyPublicDetails([
            details.company_uuid,
          ]);
          setWhitelabelUuid(details.company_uuid);
          setWhitelabelCompanyName(company.name);
          setWhitelabelIconLink(details.icon_link);
          setWhitelabelHeadline(details.headline);
          setIsWhitelabel(true);
        }
      } catch (error) {
        console.error(error);
      }
      setWhitelabelLoading(false);
    };

    fetchWhitelabelDetails();
  }, []);

  useEffect(() => {
    if (!hasRunRef.current) {
      hasRunRef.current = true;
      setTokenRefreshTimeout();
    }
  }, []);

  return (
    !whitelabelLoading && (
      <>
        <Router>
          <ScrollToTop />
          <Routes>
            {isWhitelabel ? (
              <Route
                path="*"
                element={
                  <QuoteWhitelabel
                    whitelabelIconLink={whitelabelIconLink}
                    whitelabelCompanyName={whitelabelCompanyName}
                    whitelabelHeadline={whitelabelHeadline}
                  />
                }
              >
                <Route
                  path="quote/postcode"
                  element={<PostcodeCollectionWhitelabel />}
                />
                <Route
                  path="quote/waste-services"
                  element={
                    <WasteServiceSelectionWhitelabel
                      whitelabelUuid={whitelabelUuid}
                      whitelabelCompanyName={whitelabelCompanyName}
                    />
                  }
                />
                <Route path="quote/email" element={<EmailCollection />} />
                <Route
                  path="quote/quote-selection"
                  element={<QuoteSelectionWhitelabel />}
                />
                <Route
                  path="quote/customer-details"
                  element={<CustomerDetailsCollection />}
                />
                <Route
                  path="quote/accepted"
                  element={
                    <QuoteAccepted
                      whitelabelIconLink={whitelabelIconLink}
                      whitelabelCompanyName={whitelabelCompanyName}
                    />
                  }
                />
              </Route>
            ) : (
              <>
                <Route path="/company" element={<Company />} />
                <Route path="/company-users" element={<CompanyUsers />} />
                <Route path="/company-quotes" element={<QuoteManagement />} />
                <Route
                  path="/company-lost-leads"
                  element={<LostLeadManagement />}
                />
                <Route
                  path="/company-quotes/quote/:quoteuuid"
                  element={<QuoteDetails />}
                />
                <Route path="/system-users" element={<SystemUsers />} />
                <Route path="/internal-quote" element={<QuoteInternal />}>
                  <Route
                    path="postcode"
                    element={<PostcodeCollectionInternal />}
                  />
                  <Route
                    path="waste-services"
                    element={<WasteServiceSelectionInternal />}
                  />
                  <Route
                    path="quote-selection"
                    element={<QuoteSelectionInternal />}
                  />
                </Route>
                <Route path="/price-list" element={<PriceList />} />
                <Route
                  path="/price-list/import-wizard"
                  element={<ImportWizard />}
                />
                <Route path="/oauth-callback" element={<OAuthCallback />} />
                <Route path="/whitelabel" element={<Whitelabel />} />
                <Route path="/" element={<Company />} />
                <Route path="/company" element={<Company />} />
                <Route
                  path="/company-management"
                  element={<CompanyManagement />}
                />
                <Route path="/create-company" element={<CreateCompany />} />
                <Route path="*" element={<Error />} />
              </>
            )}
          </Routes>
        </Router>
        <ToastContainer />
      </>
    )
  );
};

export default App;
