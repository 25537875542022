import "./index.css";
import React, { useState } from "react";
import PageWrapper from "../../components/PageWrapper";
import WasteServiceSelection from "../../components/WasteServiceSelection";
import QuoteSelection from "../../components/QuoteSelection";
import QuoteAccepted from "../../components/QuoteAccepted";
import ConfirmationModal from "../../components/ConfirmationModal";
import { currencyFormat } from "../../lib/formatters";
import {
  acceptQuote,
  acceptQuoteNoDetailsCollected,
  createQuote,
} from "../../api/quote";
import { getPostcodeAreaDistrict } from "../../lib/address";
import PropTypes from "prop-types";
import WhitelabelWrapper from "../../components/WhitelabelWrapper";
import PostcodeCollection from "../../components/PostcodeCollection";
import EmailCollection from "../../components/EmailCollection";
import CustomerDetailsCollection from "../../components/CustomerDetailsCollection";
import ProgressBar from "../../components/ProgressBar";

const QuoteBuildingStage = {
  POSTCODE_COLLECTION: "POSTCODE_COLLECTION",
  SERVICE_SELECTION: "SERVICE_SELECTION",
  EMAIL_COLLECTION: "EMAIL_COLLECTION",
  QUOTE_SELECTION: "QUOTE_SELECTION",
  CUSTOMER_DETAILS_COLLECTION: "CUSTOMER_DETAILS_COLLECTION",
  QUOTE_ACCEPTED: "QUOTE_ACCEPTED",
};

const Quote = ({ isWhitelabel, whitelabelIconLink }) => {
  const [stage, setStage] = useState(QuoteBuildingStage.POSTCODE_COLLECTION);
  const [desiredServices, setDesiredServices] = useState([]);
  const [quotes, setQuotes] = useState([]);
  const [quoteSessionUUID, setQuoteSessionUUID] = useState(null);
  const [customerDetails, setCustomerDetails] = useState({
    name: "",
    company_name: "",
    email: "",
    phone_number: "",
    address_line_1: "",
    address_line_2: "",
    town: "",
    postcode: "",
    county: "",
  });
  const [desiredQuote, setDesiredQuote] = useState(null);
  const [quoteAcceptConfirm, setQuoteAcceptConfirm] = useState(false);
  const pages = [
    "POSTCODE_COLLECTION",
    "SERVICE_SELECTION",
    "EMAIL_COLLECTION",
    "QUOTE_SELECTION",
  ];
  const frequencyLookup = {
    1: "Monthly",
    2.17: "Fortnightly",
    4.33: "Weekly",
  };
  const wasteTypeNameLookup = {
    CRD: "Cardboard",
    DMR: "Dry Mixed Recycling",
    FOD: "Food",
    GEN: "General waste",
    GLS: "Glass",
    WEEE: "Electronics",
    GAR: "Garden",
    "M&V": "Man & Van",
  };

  // useEffect(() => {
  //   setStage(QuoteBuildingStage.QUOTE_SELECTION);
  //   setPostcodeAreaDistrict("S26");
  //   setQuotes([{ "quote_uuid": "0192e219-41dd-7c95-95ca-6725056e6d1b", "supplier_uuid": "01928a84-9b6c-7ed1-8c99-c0d16d97058d", "price_gbp": 10.19, "price_breakdown": [{ "company": "", "type": "GEN", "container": "660LTR", "price_gbp": 10.2, "quantity": 1, "total_price_gbp": 10.2, "excess_weight_charge_gbp": null, "weight_restriction_kilos": null }] }])
  //   setServices([{ "waste_type": "CRD", "container_type": "240LTR", "quantity": 1 }, { "waste_type": "GEN", "container_type": "240LTR", "quantity": 1 }]);
  //   setUserDetails({
  //     "name": "Jon",
  //     "company_name": "JB Inc",
  //     "email": "jb@beartech.dev",
  //     "phone": "07883432386",
  //     "address_line_1": "17 Tortmayns",
  //     "address_line_2": "",
  //     "town": "Todwick",
  //     "postcode": "S261je",
  //     "county": "Yorkshire"
  //   });
  // }, [])

  const content = (
    <>
      {stage === QuoteBuildingStage.POSTCODE_COLLECTION && (
        <div className="flex size-full flex-col">
          <ProgressBar
            setPage={(page) => setStage(QuoteBuildingStage[page])}
            currPageIndex={0}
            pages={pages}
          />
          <PostcodeCollection
            onSubmit={(postcode) => {
              setCustomerDetails((prevDetails) => ({
                ...prevDetails,
                postcode,
              }));
              setStage(QuoteBuildingStage.SERVICE_SELECTION);
            }}
            existingCustomerPostcode={customerDetails.postcode}
          />
        </div>
      )}
      {stage === QuoteBuildingStage.SERVICE_SELECTION && (
        <div className="flex size-full flex-col">
          <ProgressBar
            setPage={(page) => setStage(QuoteBuildingStage[page])}
            currPageIndex={1}
            pages={pages}
          />
          <WasteServiceSelection
            onSubmit={(services) => {
              setDesiredServices(services);
              setStage(QuoteBuildingStage.EMAIL_COLLECTION);
            }}
            existingServices={desiredServices}
            postcodeAreaDistrict={getPostcodeAreaDistrict(
              customerDetails.postcode,
            )}
            frequencyLookup={frequencyLookup}
            wasteTypeNameLookup={wasteTypeNameLookup}
          />
        </div>
      )}
      {stage === QuoteBuildingStage.EMAIL_COLLECTION && (
        <div className="flex size-full flex-col">
          <ProgressBar
            setPage={(page) => setStage(QuoteBuildingStage[page])}
            currPageIndex={2}
            pages={pages}
          />
          <EmailCollection
            onSubmit={(email) => {
              setCustomerDetails((prevDetails) => ({ ...prevDetails, email }));
              createQuote(email, customerDetails.postcode, desiredServices)
                .then((response) => {
                  setQuotes(response.quotes);
                  setQuoteSessionUUID(response.quote_session_uuid);
                  setStage(QuoteBuildingStage.QUOTE_SELECTION);
                })
                .catch((error) => console.error(error));
            }}
            onCancel={() => {}}
            existingCustomerEmail={customerDetails.email}
          />
        </div>
      )}
      {stage === QuoteBuildingStage.QUOTE_SELECTION && (
        <div className="flex size-full flex-col">
          <ProgressBar
            setPage={(page) => setStage(QuoteBuildingStage[page])}
            currPageIndex={3}
            pages={pages}
          />
          <QuoteSelection
            customerDetails={customerDetails}
            quotes={quotes}
            services={desiredServices}
            modifyServices={(stage) => setStage(QuoteBuildingStage[stage])}
            onQuoteSelection={(quote) => {
              setDesiredQuote(quote);
              setQuoteAcceptConfirm(true);
            }}
            frequencyLookup={frequencyLookup}
            wasteTypeNameLookup={wasteTypeNameLookup}
          />
        </div>
      )}
      {stage === QuoteBuildingStage.CUSTOMER_DETAILS_COLLECTION && (
        <CustomerDetailsCollection
          onSubmit={(customerDetails) => {
            setCustomerDetails(customerDetails);
            acceptQuote(
              customerDetails,
              quoteSessionUUID,
              desiredQuote.quote_uuid,
            );
            setStage(QuoteBuildingStage.QUOTE_ACCEPTED);
          }}
          onCancel={() => {
            setStage(QuoteBuildingStage.QUOTE_SELECTION);
          }}
          existingCustomerDetails={customerDetails}
        />
      )}
      {stage === QuoteBuildingStage.QUOTE_ACCEPTED && (
        <QuoteAccepted
          quote={desiredQuote}
          userDetails={customerDetails}
          frequencyLookup={frequencyLookup}
          wasteTypeNameLookup={wasteTypeNameLookup}
        />
      )}
      {quoteAcceptConfirm === true && (
        <ConfirmationModal
          isOpen
          onClose={() => setQuoteAcceptConfirm(false)}
          onConfirm={() => {
            setStage(QuoteBuildingStage.CUSTOMER_DETAILS_COLLECTION);
            acceptQuoteNoDetailsCollected(
              quoteSessionUUID,
              desiredQuote.quote_uuid,
            );

            setQuoteAcceptConfirm(false);
          }}
          question={`Are you sure you want to accept the quote of ${currencyFormat(
            desiredQuote.price_gbp,
          )} from ${desiredQuote.supplier_uuid}?`}
        />
      )}
    </>
  );

  if (isWhitelabel) {
    // TODO(Jon): could have a whitelabel wrapper component or something? that will style, etc, children
    return (
      <WhitelabelWrapper iconLink={whitelabelIconLink}>
        {content}
      </WhitelabelWrapper>
    );
  }

  return <PageWrapper>{content}</PageWrapper>;
};

Quote.propTypes = {
  isWhitelabel: PropTypes.bool,
};

export default Quote;
