import React, { useState, useEffect } from "react";
import "./index.css";
import PageWrapper from "../../components/PageWrapper";
import { useGlobalContext } from "../../GlobalContext";
import { getCompanyUsers } from "../../api/company";
import { relativeTimeFormatter } from "../../lib/formatters";
import { FaUserPen, FaUserMinus, FaUserPlus } from "react-icons/fa6";
import SelectedCompany from "../../components/SelectedCompany";
import AddCompanyUser from "../../components/AddCompanyUser";
import PopoutModal from "../../components/PopoutModal";
import ConfirmationModal from "../../components/ConfirmationModal";
import { removeCompanyUser } from "../../api/company";
import { formatCompanyRole } from "../../lib/formatters";

const Users = () => {
  const { state } = useGlobalContext();
  const [companyUsers, setCompanyUsers] = useState([]);
  const [showAddUser, setShowAddUser] = useState(false);
  const [deletingUser, setDeletingUser] = useState(null);
  const [refresh, setRefresh] = useState(false);

  const refreshUsers = () => {
    setRefresh((prev) => !prev);
  };

  useEffect(() => {
    const loadCompanyUsers = async () => {
      try {
        const users = await getCompanyUsers(state.selectedCompany);
        setCompanyUsers(users);
      } catch (error) {
        console.error("Failed to load company users", error);
      }
    };
    loadCompanyUsers();
  }, [state.selectedCompany, refresh]);

  return (
    <PageWrapper>
      <SelectedCompany />
      <h2>Company Users</h2>
      <FaUserPlus
        className="user-add-icon"
        onClick={() => setShowAddUser(true)}
      />
      {companyUsers.length === 0 ? (
        <p>No users found</p>
      ) : (
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Company Role</th>
              <th>Modified</th>
              <th />
              <th />
            </tr>
          </thead>
          <tbody>
            {companyUsers.map((user) => (
              <tr key={user.uuid}>
                <td>{user.name}</td>
                <td>{user.email}</td>
                <td>{formatCompanyRole(user.company_role)}</td>
                <td>{relativeTimeFormatter(user.updated_at)}</td>
                <td>
                  <FaUserPen className="user-edit-icon" />
                </td>
                <td>
                  <FaUserMinus
                    className="user-delete-icon"
                    onClick={() => {
                      setDeletingUser(user);
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <ConfirmationModal
        isOpen={deletingUser !== null}
        question={`Remove ${deletingUser?.name} (${deletingUser?.email}) from this company?`}
        onClose={() => setDeletingUser(null)}
        onConfirm={async () => {
          try {
            await removeCompanyUser(state.selectedCompany, deletingUser.uuid);
            setCompanyUsers(
              companyUsers.filter((u) => u.uuid !== deletingUser.uuid),
            );
            setDeletingUser(null);
          } catch (error) {
            console.error("Failed to delete user", error);
          }
        }}
      />
      <PopoutModal isOpen={showAddUser}>
        <AddCompanyUser
          closeModalCb={() => {
            setShowAddUser(false);
            refreshUsers();
          }}
        />
      </PopoutModal>
    </PageWrapper>
  );
};

export default Users;
