import React, { useState } from "react";
import PropTypes from "prop-types";
import Modal from "../Modal";
import WasteIcon from "../WasteIcon";
import ContainerIcon from "../ContainerIcon";
import {
  containerTypeNameLookup,
  frequencyLookup,
  wasteTypeNameLookup,
  weeklyFrequencyLookup,
} from "../../lib/lookups";

const WasteServiceType = ({
  wasteType,
  containerTypes,
  services,
  setServices,
  onSubmit,
}) => {
  const [isSuccessfullyAdded, setIsSuccessfullyAdded] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [formData, setFormData] = useState({
    size: "",
    quantity: 1,
    frequency: "4.33",
    weekly_frequency: "4.33",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const weeklyFrequencies = [4.33, 8.67, 13, 17.33, 21.67, 26, 30.33];
  const handleServiceClick = () => {
    setIsOpen(true);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSizeChange = (size) => {
    setFormData((prevData) => ({ ...prevData, size: size }));
    setCurrentPage(2);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData.size === "") {
      return;
    } else {
      const newService = { ...formData };
      if (newService.frequency == "4.33") {
        newService.frequency = newService.weekly_frequency;
      }

      setServices((prevServices) => {
        const updatedServices = [...prevServices];

        for (let i = 0; i < updatedServices.length; i++) {
          if (
            updatedServices[i].waste_type === wasteType &&
            updatedServices[i].container_type === newService.size &&
            updatedServices[i].frequency === newService.frequency
          ) {
            updatedServices[i] = {
              waste_type: updatedServices[i].waste_type,
              container_type: updatedServices[i].container_type,
              frequency: updatedServices[i].frequency,
              quantity:
                updatedServices[i].quantity + Number(newService.quantity),
            };
            return updatedServices;
          }
        }

        return [
          ...prevServices,
          {
            container_type: newService.size,
            waste_type: wasteType,
            quantity: Number(newService.quantity),
            frequency: newService.frequency,
          },
        ];
      });
      setIsSuccessfullyAdded(true);
      setFormData({
        size: "",
        quantity: 1,
        frequency: "4.33",
        weekly_frequency: "4.33",
      });
    }
  };

  const closeModal = () => {
    setIsOpen(false);
    setIsSuccessfullyAdded(false);
    setCurrentPage(1);
  };

  const prevPage = () => {
    setCurrentPage(1);
  };

  return (
    <>
      <button
        onClick={handleServiceClick}
        className="h-32 w-24 lg:h-56 lg:w-40 xl:h-64 xl:w-52"
      >
        <div className="flex size-full flex-col items-center justify-center rounded-md border border-transparent bg-slate-200 p-3 text-center shadow-sm transition-all hover:bg-slate-400 hover:shadow-lg xl:p-6">
          <div className="mb-3 size-12 lg:mb-6 lg:size-24 xl:size-28">
            <WasteIcon wasteType={wasteType} />
          </div>
          <h3 className="block text-sm font-bold text-black lg:text-base xl:text-lg">
            {wasteTypeNameLookup[wasteType]}
          </h3>
        </div>
      </button>

      <Modal isOpen={isOpen}>
        {isSuccessfullyAdded ? (
          <div className="flex w-[90vw] flex-col items-center justify-center p-5 md:w-[80vw] lg:w-[50vw] lg:p-6 xl:p-8">
            <div className="mb-3 size-16 md:size-24 lg:mb-10 lg:size-32">
              <WasteIcon wasteType={wasteType} />
            </div>

            <p className="mb-10 block text-center text-base font-bold text-black md:text-lg lg:mb-20 lg:text-xl">
              {wasteTypeNameLookup[wasteType]} has been added to your quote.
            </p>
            <div className="flex w-full justify-center">
              <button
                onClick={closeModal}
                className="rounded-md border border-transparent bg-secondaryColor px-4 py-1 text-center text-xs text-primaryColor shadow-sm transition-all hover:border-slate-800 hover:bg-slate-800 hover:text-white hover:shadow-lg focus:border-slate-800 focus:bg-slate-800 focus:text-white active:border-slate-800 active:bg-slate-800 active:text-white md:text-sm lg:px-8 lg:py-2 lg:text-base"
              >
                Add something else
              </button>
              <button
                onClick={() => onSubmit(services)}
                className="ml-8 rounded-md border border-transparent bg-primaryColor px-4 py-1 text-center text-xs text-secondaryColor shadow-md transition-all hover:bg-slate-700 hover:shadow-lg focus:bg-slate-700 focus:shadow-none active:bg-slate-700 active:shadow-none md:text-sm lg:ml-16 lg:px-8 lg:py-2 lg:text-base"
              >
                Continue to quotes
              </button>
            </div>
          </div>
        ) : (
          <>
            {currentPage === 1 && (
              <div className="flex w-[90vw] flex-col items-center justify-center py-5 md:w-[80vw] lg:w-[50vw] lg:py-6 xl:py-8">
                <div className="mb-2 size-10 md:size-14 lg:mb-3 lg:size-20 xl:size-24">
                  <WasteIcon wasteType={wasteType} />
                </div>
                <h3 className="mb-1 block text-base font-bold text-black lg:mb-2 xl:mb-3 xl:text-lg">
                  {wasteTypeNameLookup[wasteType]}
                </h3>
                <div className="relative mb-1 w-5/6">
                  <label
                    htmlFor="size"
                    className="block text-xs text-gray-800 md:text-sm lg:mb-1 lg:text-base xl:mb-2 xl:text-lg"
                  >
                    Select a size
                  </label>
                  <div className="flex w-full flex-wrap gap-2 lg:gap-4 xl:gap-6">
                    {containerTypes?.popular?.map((containerType, index) => {
                      return (
                        <button
                          className={`flex flex-col items-center p-2 lg:p-4 xl:p-6 ${formData.size === containerType && "bg-slate-200"}`}
                          key={index}
                          type="button"
                          onClick={(e) => {
                            e.preventDefault();
                            handleSizeChange(containerType);
                          }}
                        >
                          <div className="size-16 md:size-20 lg:size-24 xl:size-32">
                            <ContainerIcon containerType={containerType} />
                          </div>
                          <p className="text-xs md:text-sm lg:text-base xl:text-lg">
                            {containerTypeNameLookup[containerType]}
                          </p>
                        </button>
                      );
                    })}
                  </div>
                  <div className="flex w-full flex-wrap gap-2 lg:gap-4 xl:gap-6">
                    {containerTypes?.rest?.map((containerType, index) => {
                      return (
                        <button
                          className={`flex flex-col items-center p-2 lg:p-4 xl:p-6 ${formData.size === containerType && "bg-slate-200"}`}
                          key={index}
                          type="button"
                          onClick={(e) => {
                            e.preventDefault();
                            handleSizeChange(containerType);
                          }}
                        >
                          <div className="size-16 md:size-20 lg:size-24 xl:size-32">
                            <ContainerIcon containerType={containerType} />
                          </div>
                          <p className="text-xs md:text-sm lg:text-base xl:text-lg">
                            {containerTypeNameLookup[containerType]}
                          </p>
                        </button>
                      );
                    })}
                  </div>
                </div>
                <div className="flex w-full justify-center">
                  <button
                    className="rounded-md border border-transparent bg-secondaryColor px-2 py-1 text-center text-xs text-primaryColor shadow-md transition-all hover:border-slate-800 hover:bg-slate-800 hover:text-white hover:shadow-lg focus:border-slate-800 focus:bg-slate-800 focus:text-white active:border-slate-800 active:bg-slate-800 active:text-white lg:px-4 lg:text-sm"
                    type="button"
                    onClick={closeModal}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            )}
            {currentPage === 2 && (
              <div className="flex w-[90vw] flex-col items-center justify-center py-5 md:w-[80vw] lg:w-[50vw] lg:py-6 xl:py-8">
                <div className="mb-2 size-10 md:size-14 lg:mb-3 lg:size-20 xl:size-24">
                  <WasteIcon wasteType={wasteType} />
                </div>
                <h3 className="mb-1 block text-base font-bold text-black lg:mb-2 xl:mb-3 xl:text-lg">
                  {wasteTypeNameLookup[wasteType]}
                </h3>
                <div className="size-8 md:size-10 lg:size-12 xl:size-16">
                  <ContainerIcon containerType={formData.size} />
                </div>
                <p className="text-xs md:text-sm lg:text-base xl:text-lg">
                  {containerTypeNameLookup[formData.size]}
                </p>
                <form
                  onSubmit={handleSubmit}
                  className="flex w-[90vw] flex-col items-center justify-center py-5 md:w-[80vw] lg:w-[50vw] lg:py-6 xl:py-8"
                >
                  <div className="mb-4 w-5/6 lg:mb-6">
                    <label
                      htmlFor="quantity"
                      className="block text-xs text-gray-800 md:text-sm lg:mb-1 lg:text-base xl:mb-2 xl:text-lg"
                    >
                      Quantity
                    </label>
                    <input
                      type="number"
                      min={1}
                      id="quantity"
                      name="quantity"
                      value={formData.quantity}
                      onChange={handleChange}
                      className="w-full appearance-none rounded border-2 p-1 text-xs leading-tight text-gray-700 shadow focus:outline-none focus:ring lg:h-8 lg:px-2 lg:text-sm"
                    />
                  </div>
                  <div className="mb-2 w-5/6 lg:mb-4">
                    <label
                      htmlFor="frequency"
                      className="block text-xs text-gray-800 md:text-sm lg:mb-1 lg:text-base xl:mb-2 xl:text-lg"
                    >
                      Frequency
                    </label>
                    <select
                      className="w-full rounded border-2 p-1 text-xs leading-tight text-gray-700 shadow focus:outline-none focus:ring lg:h-8 lg:px-2 lg:text-sm"
                      value={formData.frequency}
                      onChange={handleChange}
                      id="frequency"
                      name="frequency"
                    >
                      {Object.keys(frequencyLookup).map((frequency, index) => {
                        return (
                          <option key={index} value={frequency}>
                            {frequencyLookup[frequency]}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div
                    className={`${formData.frequency != "4.33" && "hidden"} mb-2 w-5/6 lg:mb-4`}
                  >
                    <label
                      htmlFor="weekly_frequency"
                      className="block text-xs text-gray-800 md:text-sm lg:mb-1 lg:text-base xl:mb-2 xl:text-lg"
                    >
                      How many times a week
                    </label>
                    <select
                      className="w-full rounded border-2 p-1 text-xs leading-tight text-gray-700 shadow focus:outline-none focus:ring lg:h-8 lg:px-2 lg:text-sm"
                      value={formData.weekly_frequency}
                      onChange={handleChange}
                      id="weekly_frequency"
                      name="weekly_frequency"
                    >
                      {weeklyFrequencies.map((weeklyFrequency, index) => {
                        return (
                          <option key={index} value={weeklyFrequency}>
                            {weeklyFrequencyLookup[weeklyFrequency]}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="flex w-full justify-center">
                    <button
                      className="rounded-md border border-transparent bg-secondaryColor px-2 py-1 text-center text-xs text-primaryColor shadow-md transition-all hover:border-slate-800 hover:bg-slate-800 hover:text-white hover:shadow-lg focus:border-slate-800 focus:bg-slate-800 focus:text-white active:border-slate-800 active:bg-slate-800 active:text-white lg:px-4 lg:text-sm"
                      type="button"
                      onClick={prevPage}
                    >
                      Back
                    </button>
                    <button
                      className="ml-6 rounded-md border border-transparent bg-primaryColor px-2 py-1 text-center text-xs text-secondaryColor shadow-md transition-all hover:bg-slate-700 hover:shadow-lg focus:bg-slate-700 focus:shadow-none active:bg-slate-700 active:shadow-none lg:ml-12 lg:px-4 lg:text-sm"
                      type="submit"
                    >
                      Add
                    </button>
                  </div>
                </form>
              </div>
            )}
          </>
        )}
      </Modal>
    </>
  );
};
WasteServiceType.propTypes = {
  wasteType: PropTypes.string.isRequired,
  containerTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  services: PropTypes.arrayOf(PropTypes.object).isRequired,
  setServices: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  frequencyLookup: PropTypes.object.isRequired,
  weeklyFrequencyLookup: PropTypes.object.isRequired,
  wasteTypeNameLookup: PropTypes.object.isRequired,
};

export default WasteServiceType;
