import csvFile from "../assets/price-list-template.csv";
export const downloadCSVTemplate = async () => {
  try {
    downloadCSV(csvFile, "price-list-template.csv");
  } catch (error) {
    console.error("Error fetching the CSV file:", error);
  }
};

export const downloadCSV = async (fileRef, fileName) => {
  try {
    const a = document.createElement("a");
    a.href = fileRef;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  } catch (error) {
    console.error("Error downloading the CSV file:", error);
  }
};
