import PropTypes from "prop-types";
import React from "react";
import SIXYARD from "../../assets/container_icons/6YARD.png";
import EIGHTYARD from "../../assets/container_icons/8YARD.png";
import TENYARD from "../../assets/container_icons/10YARD.png";
import BAGS from "../../assets/container_icons/BAGS.png";
import TWOFORTYLTR from "../../assets/container_icons/240LTR.png";
import THREESIXTYLTR from "../../assets/container_icons/360LTR.png";
import SIXSIXTYLTR from "../../assets/container_icons/660LTR.png";
import SEVENSEVENTYLTR from "../../assets/container_icons/770LTR.png";
import THOUSANDONEHUNDREDLTR from "../../assets/container_icons/1100LTR.png";
import MandV from "../../assets/container_icons/M&V.png";
import RORO from "../../assets/container_icons/RORO.png";

import ONEFORTYLTR from "../../assets/container_icons/140LTR.png";
import TWELVEYARDREL from "../../assets/container_icons/12YARDREL.png";
import FOURTEENYARDREL from "../../assets/container_icons/14YARDREL.png";
import SKIP from "../../assets/container_icons/SKIP.png";

const ContainerIcon = ({ containerType }) => {
  const containerTypeIconLookup = {
    "50BAGS": <img src={BAGS} className="size-full object-contain" />,
    "240LTR": <img src={TWOFORTYLTR} className="size-full object-contain" />,
    "260LTR": <img src={TWOFORTYLTR} className="size-full object-contain" />,
    "360LTR": <img src={THREESIXTYLTR} className="size-full object-contain" />,
    "660LTR": <img src={SIXSIXTYLTR} className="size-full object-contain" />,
    "770LTR": (
      <img src={SEVENSEVENTYLTR} className="size-full object-contain" />
    ),
    "1100LTR": (
      <img src={THOUSANDONEHUNDREDLTR} className="size-full object-contain" />
    ),
    "M&V": <img src={MandV} className="size-full object-contain" />,

    "1280LTR": (
      <img src={THOUSANDONEHUNDREDLTR} className="size-full object-contain" />
    ),
    "140LTR": <img src={ONEFORTYLTR} className="size-full object-contain" />,
    "6YARDFEL": <img src={SIXYARD} className="size-full object-contain" />,
    "8YARDFEL": <img src={EIGHTYARD} className="size-full object-contain" />,
    "10YARDFEL": <img src={TENYARD} className="size-full object-contain" />,
    "10YARDREL": <img src={SKIP} className="size-full object-contain" />,
    "12YARDREL": (
      <img src={TWELVEYARDREL} className="size-full object-contain" />
    ),
    "14YARDREL": (
      <img src={FOURTEENYARDREL} className="size-full object-contain" />
    ),
    "20YARDRORO": <img src={RORO} className="size-full object-contain" />,
    "25YARDRORO": <img src={RORO} className="size-full object-contain" />,
    "35YARDRORO": <img src={RORO} className="size-full object-contain" />,
    "40YARDRORO": <img src={RORO} className="size-full object-contain" />,
    "2YARDSKIP": <img src={SKIP} className="size-full object-contain" />,
    "3YARDSKIP": <img src={SKIP} className="size-full object-contain" />,
    "4YARDSKIP": <img src={SKIP} className="size-full object-contain" />,
    "5YARDSKIP": <img src={SKIP} className="size-full object-contain" />,
    "6YARDSKIP": <img src={SKIP} className="size-full object-contain" />,
    "8YARDSKIP": <img src={SKIP} className="size-full object-contain" />,
    "10YARDSKIP": <img src={SKIP} className="size-full object-contain" />,
    "12YARDSKIP": <img src={SKIP} className="size-full object-contain" />,
  };
  return <>{containerTypeIconLookup[containerType]}</>;
};
ContainerIcon.propTypes = {
  containerType: PropTypes.string.isRequired,
};
export default ContainerIcon;
