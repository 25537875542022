import React from "react";
import PropTypes from "prop-types";
import WasteIcon from "../WasteIcon";

const QuoteSelectionWasteService = ({
  selectedService,
  frequencyLookup,
  wasteTypeNameLookup,
}) => {
  return (
    <div className="mb-2 flex h-36 w-28 flex-col items-center rounded-md border border-transparent bg-slate-200 p-2 text-center shadow-sm xl:h-48 xl:w-36 xl:p-4 2xl:h-56 2xl:w-44 2xl:p-6">
      <div className="mb-1 size-10 xl:size-16 2xl:mb-2 2xl:size-24">
        <WasteIcon wasteType={selectedService.waste_type} />
      </div>
      <p className="text-black-700 2xl:text-md mb-1 block text-[10px] font-bold xl:text-xs 2xl:mb-2">
        {wasteTypeNameLookup[selectedService.waste_type]}
      </p>
      <p className="text-black-700 2xl:text-md mb-1 block text-[10px] font-bold xl:text-xs 2xl:mb-2">
        {selectedService.container_type}
      </p>
      <p className="text-black-700 2xl:text-md mb-1 block text-[10px] font-bold xl:text-xs 2xl:mb-2">
        Quantity: {selectedService.quantity}
      </p>
      <p className="text-black-700 2xl:text-md block text-[10px] font-bold xl:text-xs">
        {frequencyLookup[selectedService.frequency]}
      </p>
    </div>
  );
};
QuoteSelectionWasteService.propTypes = {
  selectedService: PropTypes.object.isRequired,
  frequencyLookup: PropTypes.object.isRequired,
  wasteTypeNameLookup: PropTypes.object.isRequired,
};

export default QuoteSelectionWasteService;
