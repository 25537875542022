export const getNavLinks = (systemRole, companyRole) => {
  const navLinks = [];

  if (systemRole === "admin") {
    // system admins can see everything
    navLinks.push({
      id: "2",
      name: "Create a Quote",
      url: "/internal-quote/postcode",
      category: "Company",
    });
    navLinks.push({
      id: "3",
      name: "Company Details",
      url: "/company",
      category: "Company",
    });
    navLinks.push({
      id: "4",
      name: "User List",
      url: "/company-users",
      category: "Company",
    });
    navLinks.push({
      id: "5",
      name: "System User List",
      url: "/system-users",
      category: "Admin",
    });
    navLinks.push({
      id: "6",
      name: "Whitelabel",
      url: "/whitelabel",
      category: "Company",
    });
    navLinks.push({
      id: "7",
      name: "Price List",
      url: "/price-list",
      category: "Company",
    });
    navLinks.push({
      id: "8",
      name: "All Companies",
      url: "/company-management",
      category: "Admin",
    });
    navLinks.push({
      id: "9",
      name: "Quotes Management",
      url: "/company-quotes",
      category: "Company",
    });
    navLinks.push({
      id: "10",
      name: "Lost Leads",
      url: "/company-lost-leads",
      category: "Company",
    });

    return navLinks;
  }

  if (companyRole != null) {
    // anyone who is a company user can see these
    navLinks.push({
      id: "2",
      name: "Create a Quote",
      url: "/internal-quote/postcode",
      category: "Company",
    });
    navLinks.push({
      id: "3",
      name: "Company Details",
      url: "/company",
      category: "Company",
    });
    navLinks.push({
      id: "7",
      name: "Price List",
      url: "/price-list",
      category: "Company",
    });
    navLinks.push({
      id: "9",
      name: "Quotes Management",
      url: "/company-quotes",
      category: "Company",
    });
    navLinks.push({
      id: "10",
      name: "Lost Leads",
      url: "/company-lost-leads",
      category: "Company",
    });
  }

  if (companyRole === "admin") {
    // company admins can see these
    navLinks.push({
      id: "4",
      name: "User List",
      url: "/company-users",
      category: "Company",
    });
    navLinks.push({
      id: "6",
      name: "Whitelabel",
      url: "/whitelabel",
      category: "Company",
    });
  }

  // TODO(Jon): perhaps i can have a company nav links, and a system nav links.
  // company ones are shown if the user is part of a company,
  // system ones are shown if the user is a system admin

  return navLinks;
};
