import React from "react";
import "./index.css";
import PageWrapper from "../../components/PageWrapper";
import SelectedCompany from "../../components/SelectedCompany";
import CompanyDetails from "../../components/CompanyDetails";

const Company = () => {
  return (
    <PageWrapper>
      <div>
        <SelectedCompany />
        <CompanyDetails />
      </div>
    </PageWrapper>
  );
};

export default Company;
