import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { extractAddressParts } from "../../lib/google-maps";

const CustomerDetailsCollection = ({
  onSubmit,
  onCancel,
  existingCustomerDetails,
}) => {
  const inputRef = useRef(null);
  const [formData, setFormData] = useState(existingCustomerDetails);
  const [formValidation, setFormValidation] = useState({
    name: null,
    email: null,
    address_line_1: null,
    town: null,
    postcode: null,
    county: null,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const isValidName = validateName();
    const isValidEmail = validateEmail();
    const isValidAddressLine1 = validateAddressLine1();
    const isValidTown = validateTown();
    const isValidCounty = validateCounty();
    const isValidPostcode = validatePostcode();

    if (
      !isValidName ||
      !isValidEmail ||
      !isValidAddressLine1 ||
      !isValidTown ||
      !isValidCounty ||
      !isValidPostcode
    ) {
      return;
    }
    onSubmit(formData);
  };

  const validateName = () => {
    if (formData.name) {
      setFormValidation((prevFormValidation) => ({
        ...prevFormValidation,
        name: true,
      }));
      return true;
    } else {
      setFormValidation((prevFormValidation) => ({
        ...prevFormValidation,
        name: false,
      }));
      return false;
    }
  };

  const handleNameChange = (e) => {
    formValidation.name === false &&
      e.target.value &&
      setFormValidation((prevFormValidation) => ({
        ...prevFormValidation,
        name: true,
      }));
    setFormData((prevData) => ({
      ...prevData,
      name: e.target.value,
    }));
  };
  const validateEmail = () => {
    const emailRegex =
      // TODO(SW-25)
      // eslint-disable-next-line no-control-regex
      /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
    if (emailRegex.test(formData.email)) {
      setFormValidation((prevFormValidation) => ({
        ...prevFormValidation,
        email: true,
      }));
      return true;
    } else {
      setFormValidation((prevFormValidation) => ({
        ...prevFormValidation,
        email: false,
      }));
      return false;
    }
  };

  const handleEmailChange = (e) => {
    const emailRegex =
      // TODO(SW-25)
      // eslint-disable-next-line no-control-regex
      /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
    formValidation.email === false &&
      emailRegex.test(e.target.value) &&
      setFormValidation((prevFormValidation) => ({
        ...prevFormValidation,
        email: true,
      }));
    setFormData((prevData) => ({
      ...prevData,
      email: e.target.value,
    }));
  };

  const validateAddressLine1 = () => {
    if (formData.address_line_1) {
      setFormValidation((prevFormValidation) => ({
        ...prevFormValidation,
        address_line_1: true,
      }));
      return true;
    } else {
      setFormValidation((prevFormValidation) => ({
        ...prevFormValidation,
        address_line_1: false,
      }));
      return false;
    }
  };

  const handleAddressLine1Change = (e) => {
    formValidation.address_line_1 === false &&
      e.target.value &&
      setFormValidation((prevFormValidation) => ({
        ...prevFormValidation,
        address_line_1: true,
      }));
    setFormData((prevData) => ({
      ...prevData,
      address_line_1: e.target.value,
    }));
  };

  const validateTown = () => {
    if (formData.town) {
      setFormValidation((prevFormValidation) => ({
        ...prevFormValidation,
        town: true,
      }));
      return true;
    } else {
      setFormValidation((prevFormValidation) => ({
        ...prevFormValidation,
        town: false,
      }));
      return false;
    }
  };

  const handleTownChange = (e) => {
    formValidation.town === false &&
      e.target.value &&
      setFormValidation((prevFormValidation) => ({
        ...prevFormValidation,
        town: true,
      }));
    setFormData((prevData) => ({
      ...prevData,
      town: e.target.value,
    }));
  };

  const validateCounty = () => {
    if (formData.county) {
      setFormValidation((prevFormValidation) => ({
        ...prevFormValidation,
        county: true,
      }));
      return true;
    } else {
      setFormValidation((prevFormValidation) => ({
        ...prevFormValidation,
        county: false,
      }));
      return false;
    }
  };

  const handleCountyChange = (e) => {
    formValidation.county === false &&
      e.target.value &&
      setFormValidation((prevFormValidation) => ({
        ...prevFormValidation,
        county: true,
      }));
    setFormData((prevData) => ({
      ...prevData,
      county: e.target.value,
    }));
  };

  const validatePostcode = () => {
    const postcodeRegex =
      /^([a-zA-Z]{1,2}[0-9][0-9a-zA-Z]?)\s?[0-9][a-zA-Z]{2}?/;
    if (postcodeRegex.test(formData.postcode)) {
      setFormValidation((prevFormValidation) => ({
        ...prevFormValidation,
        postcode: true,
      }));
      return true;
    } else {
      setFormValidation((prevFormValidation) => ({
        ...prevFormValidation,
        postcode: false,
      }));
      return false;
    }
  };

  useEffect(() => {
    if (!window.google) return; // Ensure the Google script has loaded

    const autocomplete = new window.google.maps.places.Autocomplete(
      inputRef.current,
      {
        types: ["geocode"],
        componentRestrictions: { country: "GB" },
      },
    );

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          const userLocation = new window.google.maps.LatLng(
            latitude,
            longitude,
          );

          // Bias the autocomplete results to the user's location
          autocomplete.setBounds(
            new window.google.maps.LatLngBounds(userLocation),
          );
        },
        (error) => {
          console.error("Error getting user's location:", error);
        },
      );
    }

    inputRef.current.placeholder = "Start typing your address";

    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      if (place.address_components) {
        const components = place.address_components;
        const addressParts = extractAddressParts(components);
        setFormData((prevData) => ({
          ...prevData,
          address_line_1: addressParts.addressLine1,
          address_line_2: addressParts.addressLine2,
          town: addressParts.town,
          county: addressParts.county,
        }));
      }
    });
  }, []);

  return (
    <div className="flex w-full justify-center">
      <div className="w-8/12 px-6 py-10">
        <h2 className="text-black-900 my-2 block text-center text-xl font-bold 2xl:my-3 2xl:text-2xl">
          Personal Details
        </h2>
        <p className="text-black-700 mb-6 text-center text-lg 2xl:mb-10 2xl:text-xl">
          Please provide your contact details and address so we can confirm the
          quote.
        </p>
        <form onSubmit={handleSubmit} noValidate>
          <div className="relative mb-2 2xl:mb-3">
            <input
              type="text"
              id="name"
              className={`peer appearance-none border-2 shadow ${formValidation.name ? "border-successColor" : formValidation.name === false && "border-errorColor"} text-md focus:shadow-outline h-12 w-full rounded px-3 py-2 leading-tight text-gray-700 placeholder:text-transparent focus:outline-none 2xl:h-16 2xl:text-lg`}
              name="name"
              value={formData.name || ""}
              onChange={handleNameChange}
              onBlur={validateName}
              required
              placeholder="Name"
            />
            <label
              htmlFor="name"
              className="text-md absolute left-0 top-2.5 ml-2 -translate-y-6 bg-white px-1 text-gray-500 duration-100 ease-linear peer-placeholder-shown:translate-y-0 peer-focus:ml-2 peer-focus:-translate-y-6 peer-focus:px-1 2xl:top-5 2xl:-translate-y-8 2xl:text-xl 2xl:peer-focus:-translate-y-8"
            >
              Name
            </label>
            <p
              className={`2xl:text-md text-sm italic text-errorColor ${formValidation.name !== false && "invisible"}`}
            >
              Please provide a valid name.
            </p>
          </div>
          <div className="relative mb-7 2xl:mb-10">
            <input
              type="text"
              id="company_name"
              name="company_name"
              className="text-md focus:shadow-outline peer h-12 w-full appearance-none rounded border-2 px-3 py-2 leading-tight text-gray-700 shadow placeholder:text-transparent focus:outline-none 2xl:h-16 2xl:text-lg"
              value={formData.company_name}
              onChange={handleChange}
              placeholder="Company name"
            />
            <label
              htmlFor="company_name"
              className="text-md absolute left-0 top-2.5 ml-2 -translate-y-6 bg-white px-1 text-gray-500 duration-100 ease-linear peer-placeholder-shown:translate-y-0 peer-focus:ml-2 peer-focus:-translate-y-6 peer-focus:px-1 2xl:top-5 2xl:-translate-y-8 2xl:text-xl 2xl:peer-focus:-translate-y-8"
            >
              Company Name (optional)
            </label>
          </div>
          <div className="relative mb-2 2xl:mb-3">
            <input
              type="email"
              id="email"
              name="email"
              className={`peer appearance-none border-2 shadow ${formValidation.email ? "border-successColor" : formValidation.email === false && "border-errorColor"} text-md focus:shadow-outline h-12 w-full rounded px-3 py-2 leading-tight text-gray-700 placeholder:text-transparent focus:outline-none 2xl:h-16 2xl:text-lg`}
              value={formData.email}
              onChange={handleEmailChange}
              placeholder="Email"
              onBlur={validateEmail}
              required
            />
            <label
              htmlFor="email"
              className="text-md absolute left-0 top-2.5 ml-2 -translate-y-6 bg-white px-1 text-gray-500 duration-100 ease-linear peer-placeholder-shown:translate-y-0 peer-focus:ml-2 peer-focus:-translate-y-6 peer-focus:px-1 2xl:top-5 2xl:-translate-y-8 2xl:text-xl 2xl:peer-focus:-translate-y-8"
            >
              Email
            </label>
            <p
              className={`italic text-errorColor ${formValidation.email !== false && "invisible"}`}
            >
              Please provide a valid email.
            </p>
          </div>
          <div className="relative mb-7 2xl:mb-10">
            <input
              type="tel"
              id="phone"
              name="phone"
              className="text-md focus:shadow-outline peer h-12 w-full appearance-none rounded border-2 px-3 py-2 leading-tight text-gray-700 shadow placeholder:text-transparent focus:outline-none 2xl:h-16 2xl:text-lg"
              value={formData.phone}
              onChange={handleChange}
              placeholder="Phone"
            />
            <label
              htmlFor="phone"
              className="text-md absolute left-0 top-2.5 ml-2 -translate-y-6 bg-white px-1 text-gray-500 duration-100 ease-linear peer-placeholder-shown:translate-y-0 peer-focus:ml-2 peer-focus:-translate-y-6 peer-focus:px-1 2xl:top-5 2xl:-translate-y-8 2xl:text-xl 2xl:peer-focus:-translate-y-8"
            >
              Phone (optional)
            </label>
          </div>
          <div className="relative mb-2 2xl:mb-3">
            <input
              type="text"
              id="address_line_1"
              className={`peer appearance-none border-2 shadow ${formValidation.address_line_1 ? "border-successColor" : formValidation.address_line_1 === false && "border-errorColor"} text-md focus:shadow-outline h-12 w-full rounded px-3 py-2 leading-tight text-gray-700 placeholder:text-transparent focus:outline-none focus:placeholder:text-gray-500 2xl:h-16 2xl:text-lg`}
              name="address_line_1"
              ref={inputRef}
              value={formData.address_line_1 || ""}
              onChange={handleAddressLine1Change}
              onBlur={validateAddressLine1}
              required
              placeholder="Address Line 1"
            />
            <label
              htmlFor="address_line_1"
              className="text-md absolute left-0 top-2.5 ml-2 -translate-y-6 bg-white px-1 text-gray-500 duration-100 ease-linear peer-placeholder-shown:translate-y-0 peer-focus:ml-2 peer-focus:-translate-y-6 peer-focus:px-1 2xl:top-5 2xl:-translate-y-8 2xl:text-xl 2xl:peer-focus:-translate-y-8"
            >
              Address Line 1
            </label>
            <p
              className={`italic text-errorColor ${formValidation.address_line_1 !== false && "invisible"}`}
            >
              Please provide a valid address.
            </p>
          </div>
          <div className="relative mb-7 2xl:mb-10">
            <input
              type="text"
              id="address_line_2"
              className="text-md focus:shadow-outline peer h-12 w-full appearance-none rounded border-2 px-3 py-2 leading-tight text-gray-700 shadow placeholder:text-transparent focus:outline-none 2xl:h-16 2xl:text-lg"
              name="address_line_2"
              value={formData.address_line_2 || ""}
              onChange={handleChange}
              placeholder="Address Line 2"
            />
            <label
              htmlFor="address_line_2"
              className="text-md absolute left-0 top-2.5 ml-2 -translate-y-6 bg-white px-1 text-gray-500 duration-100 ease-linear peer-placeholder-shown:translate-y-0 peer-focus:ml-2 peer-focus:-translate-y-6 peer-focus:px-1 2xl:top-5 2xl:-translate-y-8 2xl:text-xl 2xl:peer-focus:-translate-y-8"
            >
              Address Line 2 (optional)
            </label>
          </div>
          <div className="relative mb-2 2xl:mb-3">
            <input
              type="text"
              id="town"
              className={`peer appearance-none border-2 shadow ${formValidation.town ? "border-successColor" : formValidation.town === false && "border-errorColor"} text-md focus:shadow-outline h-12 w-full rounded px-3 py-2 leading-tight text-gray-700 placeholder:text-transparent focus:outline-none 2xl:h-16 2xl:text-lg`}
              name="town"
              value={formData.town || ""}
              onChange={handleTownChange}
              onBlur={validateTown}
              required
              placeholder="Town"
            />
            <label
              htmlFor="town"
              className="text-md absolute left-0 top-2.5 ml-2 -translate-y-6 bg-white px-1 text-gray-500 duration-100 ease-linear peer-placeholder-shown:translate-y-0 peer-focus:ml-2 peer-focus:-translate-y-6 peer-focus:px-1 2xl:top-5 2xl:-translate-y-8 2xl:text-xl 2xl:peer-focus:-translate-y-8"
            >
              Town
            </label>
            <p
              className={`italic text-errorColor ${formValidation.town !== false && "invisible"}`}
            >
              Please provide a valid town.
            </p>
          </div>
          <div className="relative mb-2 2xl:mb-3">
            <input
              type="text"
              id="county"
              className={`peer appearance-none border-2 shadow ${formValidation.county ? "border-successColor" : formValidation.county === false && "border-errorColor"} text-md focus:shadow-outline h-12 w-full rounded px-3 py-2 leading-tight text-gray-700 placeholder:text-transparent focus:outline-none 2xl:h-16 2xl:text-lg`}
              name="county"
              value={formData.county || ""}
              onChange={handleCountyChange}
              onBlur={validateCounty}
              required
              placeholder="County"
            />
            <label
              htmlFor="county"
              className="text-md absolute left-0 top-2.5 ml-2 -translate-y-6 bg-white px-1 text-gray-500 duration-100 ease-linear peer-placeholder-shown:translate-y-0 peer-focus:ml-2 peer-focus:-translate-y-6 peer-focus:px-1 2xl:top-5 2xl:-translate-y-8 2xl:text-xl 2xl:peer-focus:-translate-y-8"
            >
              County
            </label>
            <p
              className={`italic text-errorColor ${formValidation.county !== false && "invisible"}`}
            >
              Please provide a valid county.
            </p>
          </div>
          <div className="relative mb-2 2xl:mb-3">
            <input
              type="text"
              id="postcode"
              className={`peer appearance-none border-2 shadow ${formValidation.postcode ? "border-successColor" : formValidation.postcode === false && "border-errorColor"} text-md focus:shadow-outline h-12 w-full rounded px-3 py-2 leading-tight text-gray-700 placeholder:text-transparent focus:outline-none 2xl:h-16 2xl:text-lg`}
              name="postcode"
              value={formData.postcode}
              readOnly
              required
              placeholder="Postcode"
            />
            <label
              htmlFor="postcode"
              className="text-md absolute left-0 top-2.5 ml-2 -translate-y-6 bg-white px-1 text-gray-500 duration-100 ease-linear peer-placeholder-shown:translate-y-0 peer-focus:ml-2 peer-focus:-translate-y-6 peer-focus:px-1 2xl:top-5 2xl:-translate-y-8 2xl:text-xl 2xl:peer-focus:-translate-y-8"
            >
              Postcode
            </label>
          </div>
          <div className="flex justify-center">
            <button
              className="h-12 w-1/5 rounded-md border border-transparent bg-secondaryColor px-4 py-2 text-center text-lg text-primaryColor shadow-sm transition-all hover:border-slate-800 hover:bg-slate-800 hover:text-white hover:shadow-lg focus:border-slate-800 focus:bg-slate-800 focus:text-white active:border-slate-800 active:bg-slate-800 active:text-white"
              type="button"
              onClick={onCancel}
            >
              Cancel
            </button>
            <button
              className="ml-16 h-12 w-1/5 rounded-md border border-transparent bg-primaryColor px-4 py-2 text-center text-lg text-secondaryColor shadow-md transition-all hover:bg-slate-700 hover:shadow-lg focus:bg-slate-700 focus:shadow-none active:bg-slate-700 active:shadow-none"
              type="submit"
            >
              Next
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

CustomerDetailsCollection.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  existingCustomerDetails: PropTypes.shape({
    name: PropTypes.string,
    company_name: PropTypes.string,
    email: PropTypes.string,
    phone_number: PropTypes.string,
    address_line_1: PropTypes.string,
    address_line_2: PropTypes.string,
    town: PropTypes.string,
    postcode: PropTypes.string,
    county: PropTypes.string,
  }),
};

export default CustomerDetailsCollection;
