import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";

const Modal = ({ children, isOpen }) => {
  useEffect(() => {
    if (isOpen) {
      openModal();
    } else {
      closeModal();
    }
  }, [isOpen]);
  const modalRef = useRef(null);
  const openModal = () => {
    modalRef.current?.showModal();
  };
  const closeModal = () => {
    modalRef.current?.close();
  };
  return (
    <dialog ref={modalRef} className="rounded">
      {children}
    </dialog>
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
};

export default Modal;
