import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { getRefererWhitelabelIcon } from "../../api/quote";

const WhitelabelWrapper = ({ children, iconLink }) => {
  const [refererIcon, setRefererIcon] = useState(null);
  useEffect(() => {
    const fetchRefererIcon = async () => {
      try {
        const icon = await getRefererWhitelabelIcon();
        setRefererIcon(URL.createObjectURL(icon));
      } catch (error) {
        console.error("Failed to fetch referer icon", error);
      }
    };

    fetchRefererIcon();
  }, []);

  return (
    <div className="flex h-screen w-full flex-col font-fontFamily">
      <header className="h-28 bg-primaryColor p-10 xl:h-32 2xl:h-36">
        <a href={iconLink}>
          <img
            src={refererIcon}
            alt="logo"
            className="size-24 object-contain"
          />
        </a>
      </header>
      <main className="flex-1 bg-backgroundColor">{children}</main>
    </div>
  );
};

WhitelabelWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  whitelabelDetails: PropTypes.shape({
    company_uuid: PropTypes.string,
    primary_color: PropTypes.string,
    secondary_color: PropTypes.string,
    logo: PropTypes.string,
  }),
};

export default WhitelabelWrapper;
