import React, { useState } from "react";
import PropTypes from "prop-types";

const EmailCollection = ({ onSubmit, existingCustomerEmail }) => {
  const [customerEmail, setCustomerEmail] = useState(
    existingCustomerEmail || "",
  );
  const [formValidation, setFormValidation] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    const isValidEmail = validateEmail();

    if (!isValidEmail) {
      return;
    }
    onSubmit(customerEmail);
  };

  const validateEmail = () => {
    const emailRegex =
      // TODO(SW-25)
      // eslint-disable-next-line no-control-regex
      /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
    if (emailRegex.test(customerEmail)) {
      setFormValidation(true);
      return true;
    } else {
      setFormValidation(false);
      return false;
    }
  };

  const handleEmailChange = (e) => {
    const emailRegex =
      // TODO(SW-25)
      // eslint-disable-next-line no-control-regex
      /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

    formValidation === false &&
      emailRegex.test(e.target.value) &&
      setFormValidation(true);
    setCustomerEmail(e.target.value);
  };

  return (
    <div className="-mt-12 flex w-full flex-1 flex-col items-center justify-center xl:-mt-16 2xl:-mt-28">
      <h1 className="text-black-900 mb-2 block text-center text-2xl font-bold xl:mb-4 xl:text-3xl 2xl:mb-6 2xl:text-4xl">
        Please enter your email.
      </h1>
      <h2 className="text-black-900 mb-10 block text-center text-xl xl:mb-20 xl:text-2xl 2xl:mb-24 2xl:text-3xl">
        We'll send over your quotes so you can access them later on.
      </h2>
      <form
        onSubmit={handleSubmit}
        noValidate
        className="flex w-8/12 flex-col items-center"
      >
        <div className="relative mb-8 w-3/4 xl:mb-16 2xl:mb-20">
          <input
            type="text"
            id="email"
            className={`peer appearance-none border-2 shadow ${formValidation ? "border-successColor" : formValidation === false && "border-errorColor"} focus:shadow-outline h-16 w-full rounded px-3 py-2 text-lg leading-tight text-gray-700 placeholder:text-transparent focus:outline-none 2xl:h-24 2xl:px-5 2xl:py-4 2xl:text-2xl`}
            name="email"
            value={customerEmail || ""}
            onChange={handleEmailChange}
            onBlur={validateEmail}
            required
            placeholder="Email"
          />
          <label
            htmlFor="email"
            className="absolute left-0 top-5 ml-2 -translate-y-8 bg-white px-1 text-xl text-gray-500 duration-100 ease-linear peer-placeholder-shown:translate-y-0 peer-focus:ml-2 peer-focus:-translate-y-8 peer-focus:px-1 2xl:top-7 2xl:ml-4 2xl:-translate-y-11 2xl:text-3xl 2xl:peer-focus:ml-4 2xl:peer-focus:-translate-y-11"
          >
            Email
          </label>
          <p
            className={`mt-1 italic text-errorColor ${formValidation !== false && "invisible"}`}
          >
            Please provide a valid email.
          </p>
        </div>
        <button
          className="h-16 w-2/5 rounded-md border border-transparent bg-primaryColor px-4 py-2 text-center text-lg text-secondaryColor shadow-md transition-all hover:bg-slate-700 hover:shadow-lg focus:bg-slate-700 focus:shadow-none active:bg-slate-700 active:shadow-none xl:text-2xl 2xl:text-3xl"
          type="submit"
        >
          Continue to quotes
        </button>
      </form>
    </div>
  );
};

EmailCollection.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  existingCustomerEmail: PropTypes.string,
};

export default EmailCollection;
