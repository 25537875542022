import "./index.css";
import React, { useState, useEffect, useRef } from "react";
import PageWrapper from "../../components/PageWrapper";
import {
  fetchPriceList,
  uploadPriceList,
  deletePriceListItem,
} from "../../api/quote";
import ConfirmationModal from "../../components/ConfirmationModal";
import { currencyFormat, relativeTimeFormatter } from "../../lib/formatters";
import { MdModeEdit, MdDeleteForever } from "react-icons/md";
import { FaFileImport } from "react-icons/fa";
import EditPriceListItem from "../../components/EditPriceListItem";
import PopoutModal from "../../components/PopoutModal";
import SelectedCompany from "../../components/SelectedCompany";
import { useGlobalContext } from "../../GlobalContext";

const PriceList = () => {
  const [priceList, setPriceList] = useState(null);
  const [file, setFile] = useState(null);
  const [rows, setRows] = useState(0);
  const fileInputRef = useRef(null);
  const [editingPriceList, setIsEditingPriceList] = useState(null);
  const { state } = useGlobalContext();

  const handleCsvImport = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (!file) {
      return;
    }
    const reader = new FileReader();
    reader.onload = function (e) {
      const content = e.target.result;
      const rows = content.split("\n");
      const nonEmptyRows = rows.filter((row) => row.trim() !== "");
      const rowCount = nonEmptyRows.length - 1; // minus header
      setRows(rowCount);
      setFile(file);
      event.target.value = null;
    };

    reader.readAsText(file);
  };

  const handleConfirmCsvImport = () => {
    const uploadFile = async () => {
      try {
        await uploadPriceList(state.selectedCompany, file);
        await loadData();
      } catch (error) {
        console.error(error);
      }
      setFile(null);
    };

    uploadFile();
  };

  const loadData = async () => {
    try {
      const priceList = await fetchPriceList(state.selectedCompany);
      setPriceList(priceList);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    loadData();
  }, [state.selectedCompany]);

  return (
    <PageWrapper>
      <SelectedCompany />
      <div className="price-list-container">
        <h2>Price List</h2>
        <FaFileImport onClick={handleCsvImport} className="add-icon" />
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: "none" }}
          accept=".csv"
          onChange={handleFileChange}
        />
        {priceList === null ? (
          <p>Loading...</p>
        ) : (
          <div className="price-list-table-wrapper">
            <table className="price-list-table">
              <thead>
                <tr>
                  <th>Container</th>
                  <th>Waste</th>
                  <th>Postcode Area District</th>
                  <th>Price</th>
                  <th>Updated By</th>
                  <th>Last Updated</th>
                  <th />
                  <th />
                </tr>
              </thead>
              <tbody>
                {priceList.map((item) => (
                  <tr key={item.uuid}>
                    <td>{item.container_type}</td>
                    <td>{item.waste_type}</td>
                    <td>{item.postcode_area_district}</td>
                    <td>{currencyFormat(item.price)}</td>
                    <td>Jon McCormack</td>
                    <td>{relativeTimeFormatter(item.updated_at)}</td>
                    <td>
                      <MdModeEdit
                        onClick={() => setIsEditingPriceList(item)}
                        className="edit-icon"
                      />
                    </td>
                    <td>
                      <MdDeleteForever
                        onClick={() => {
                          deletePriceListItem(
                            state.selectedCompany,
                            item.uuid,
                          ).then(() => loadData());
                        }}
                        className="delete-icon"
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
      <ConfirmationModal
        isOpen={file !== null}
        onClose={() => setFile(null)}
        onConfirm={handleConfirmCsvImport}
        question={`Are you sure you want to upload ${rows} rows?`}
      />
      <PopoutModal isOpen={editingPriceList !== null}>
        <EditPriceListItem
          priceListItem={editingPriceList}
          closeModalCb={() => {
            loadData();
            setIsEditingPriceList(null);
          }}
        />
      </PopoutModal>
    </PageWrapper>
  );
};

export default PriceList;
