import React, { useState, useEffect } from "react";
import ChangeCompanyModal from "../ChangeCompanyModal";
import { getCompanies } from "../../api/company";
import { getDefaultCompanyUuid } from "../../lib/persistence";
import { useGlobalContext } from "../../GlobalContext";
import { isLoggedIn } from "../../lib/auth";

const SelectedCompany = () => {
  const { state, setState } = useGlobalContext();
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [companies, setCompanies] = useState([]);
  const [otherCompanies, setOtherCompanies] = useState([]);
  const [changingCompanies, setChangingCompanies] = useState(false);

  useEffect(() => {
    const loadCompanies = async () => {
      if (!isLoggedIn()) {
        return;
      }
      try {
        const companiesList = await getCompanies();
        setCompanies(companiesList);
        if (companiesList.length < 1) {
          return;
        }

        const defaultCompanyUuid = getDefaultCompanyUuid();
        let selectedIdx = companiesList.findIndex(
          (company) => company.uuid === defaultCompanyUuid,
        );
        if (selectedIdx === -1) {
          selectedIdx = 0;
        }
        setSelectedCompany(companiesList[selectedIdx]);
        setState((prevState) => ({
          ...prevState,
          selectedCompany: companiesList[selectedIdx].uuid,
        }));
        setOtherCompanies(
          companiesList.filter(
            (company) =>
              companiesList[selectedIdx] &&
              company.uuid !== companiesList[selectedIdx].uuid,
          ),
        );
      } catch (error) {
        console.error("Failed to load companies", error);
      }
    };
    loadCompanies();
  }, []);

  const handleClick = (e) => {
    e.preventDefault();
    setChangingCompanies(true);
  };

  if (!selectedCompany) {
    return (
      <div className="h-10 w-full rounded bg-slate-100 xl:h-12 2xl:h-16"></div>
    );
  }

  return (
    <>
      <div className="flex w-full flex-col items-center justify-center rounded bg-slate-100 py-2 xl:py-3 2xl:py-4">
        <span className="text-lg xl:text-xl 2xl:text-2xl">
          You are currently viewing <b>{selectedCompany?.name}</b>
        </span>
        {otherCompanies && otherCompanies.length > 0 && (
          <button
            onClick={handleClick}
            className="text-md text-blue-600 xl:text-lg 2xl:text-xl"
          >
            Change Company
          </button>
        )}
      </div>
      <ChangeCompanyModal
        isOpen={changingCompanies}
        onCompanySelected={(uuid) => {
          const company = companies.find((c) => c.uuid === uuid);
          if (!company) {
            return;
          }
          setState({
            ...state,
            selectedCompany: company.uuid,
          });
          setSelectedCompany(company);
          setOtherCompanies(companies.filter((c) => c.uuid !== uuid));
          setChangingCompanies(false);
        }}
        onCancel={() => setChangingCompanies(false)}
        companies={companies.filter(
          (company) => company.uuid !== selectedCompany?.uuid,
        )}
      />
    </>
  );
};

export default SelectedCompany;
