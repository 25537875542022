import React from "react";
import { useNavigate } from "react-router-dom";
import "./index.css";
import PageWrapper from "../../components/PageWrapper";

const Error = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1); // Navigate to the previous page
  };

  return (
    <PageWrapper>
      <div>
        <h1>Something Went Wrong</h1>
        <p>We're sorry, but something went wrong. Please try again later.</p>
        <button onClick={handleGoBack}>Go Back</button>
      </div>
    </PageWrapper>
  );
};

export default Error;
