const DEFAULT_COMPANY_KEY = "defaultCompany";

export const getDefaultCompanyUuid = () => {
  return localStorage.getItem(DEFAULT_COMPANY_KEY);
};

export const setDefaultCompanyUuid = (companyUuid) => {
  if (companyUuid === null) {
    localStorage.removeItem(DEFAULT_COMPANY_KEY);
    return;
  }
  localStorage.setItem(DEFAULT_COMPANY_KEY, companyUuid);
};
