import React, { useState } from "react";
import PropTypes from "prop-types";

const PostcodeCollection = ({ onSubmit, existingCustomerPostcode }) => {
  const [customerPostcode, setCustomerPostcode] = useState(
    existingCustomerPostcode || "",
  );
  const [formValidation, setFormValidation] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    const isValidPostcode = validatePostcode();

    if (!isValidPostcode) {
      return;
    }
    onSubmit(customerPostcode);
  };

  const validatePostcode = () => {
    const postcodeRegex =
      /^([a-zA-Z]{1,2}[0-9][0-9a-zA-Z]?)\s?[0-9][a-zA-Z]{2}?/;
    if (postcodeRegex.test(customerPostcode)) {
      setFormValidation(true);
      return true;
    } else {
      setFormValidation(false);
      return false;
    }
  };

  const handlePostcodeChange = (e) => {
    const postcodeRegex =
      /^([a-zA-Z]{1,2}[0-9][0-9a-zA-Z]?)\s?[0-9][a-zA-Z]{2}?/;

    formValidation === false &&
      postcodeRegex.test(e.target.value) &&
      setFormValidation(true);
    setCustomerPostcode(e.target.value.toUpperCase());
  };

  return (
    <div className="-mt-12 flex w-full flex-1 flex-col items-center justify-center xl:-mt-16 2xl:-mt-28">
      <h1 className="text-black-900 mb-10 block text-center text-2xl font-bold xl:mb-20 xl:text-3xl 2xl:mb-24 2xl:text-4xl">
        Hi, what is your postcode?
      </h1>
      <form
        onSubmit={handleSubmit}
        noValidate
        className="flex w-8/12 flex-col items-center"
      >
        <div className="relative mb-8 w-3/4 xl:mb-16 2xl:mb-20">
          <input
            type="text"
            id="postcode"
            className={`peer appearance-none border-2 shadow ${formValidation ? "border-successColor" : formValidation === false && "border-errorColor"} focus:shadow-outline h-16 w-full rounded px-3 py-2 text-lg leading-tight text-gray-700 placeholder:text-transparent focus:outline-none 2xl:h-24 2xl:px-5 2xl:py-4 2xl:text-2xl`}
            name="postcode"
            value={customerPostcode || ""}
            onChange={handlePostcodeChange}
            onBlur={validatePostcode}
            required
            placeholder="Postcode"
          />
          <label
            htmlFor="postcode"
            className="absolute left-0 top-5 ml-2 -translate-y-8 bg-white px-1 text-xl text-gray-500 duration-100 ease-linear peer-placeholder-shown:translate-y-0 peer-focus:ml-2 peer-focus:-translate-y-8 peer-focus:px-1 2xl:top-7 2xl:ml-4 2xl:-translate-y-11 2xl:text-3xl 2xl:peer-focus:ml-4 2xl:peer-focus:-translate-y-11"
          >
            Postcode
          </label>
          <p
            className={`mt-1 italic text-errorColor ${formValidation !== false && "invisible"}`}
          >
            Please provide a valid postcode.
          </p>
        </div>
        <button
          className="h-16 w-1/5 rounded-md border border-transparent bg-primaryColor px-4 py-2 text-center text-lg text-secondaryColor shadow-md transition-all hover:bg-slate-700 hover:shadow-lg focus:bg-slate-700 focus:shadow-none active:bg-slate-700 active:shadow-none xl:text-2xl 2xl:text-3xl"
          type="submit"
        >
          Next
        </button>
      </form>
    </div>
  );
};

PostcodeCollection.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  existingCustomerPostcode: PropTypes.string,
};

export default PostcodeCollection;
