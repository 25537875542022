import React from "react";
import PageWrapper from "../../components/PageWrapper";
import WhitelabelDomain from "../../components/WhitelabelDomain";
import WhitelabelStyle from "../../components/WhitelabelStyle";
import SelectedCompany from "../../components/SelectedCompany";

const Whitelabel = () => {
  return (
    <PageWrapper>
      <div className="flex w-full flex-col gap-6 p-6 xl:gap-8 xl:p-8 2xl:gap-10 2xl:p-10">
        <SelectedCompany />
        <div>
          <h1 className="text-black-900 mb-3 block text-center text-2xl font-bold xl:mb-5 xl:text-3xl 2xl:mb-6 2xl:text-4xl">
            Whitelabel
          </h1>
          <p className="text-black-900 block text-center text-lg xl:text-xl 2xl:text-2xl">
            Configuration to enable a subdomain on your companies website to be
            served by Switch Waste's tailored quote builder.
          </p>
        </div>
        <WhitelabelDomain />
        <WhitelabelStyle />
      </div>
    </PageWrapper>
  );
};

export default Whitelabel;
