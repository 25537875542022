import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Error from "./pages/Error";
import Company from "./pages/Company";
import OAuthCallback from "./pages/OAuthCallback";
import Users from "./pages/Users";
import Quote from "./pages/Quote";
import PriceList from "./pages/PriceList";
import WasteContainerInfo from "./pages/WasteContainerInfo";
import Home from "./pages/Home";
import Whitelabel from "./pages/Whitelabel";
import { fetchRefererWhitelabelDetails } from "./api/quote";
import { ToastContainer } from "react-toastify";

// TODO(Jon): on app mount, if we have a refresh token, set a timeout to refresh the access token
// on access token expiry

export const App = () => {
  const [isWhitelabel, setIsWhitelabel] = useState(false);
  const [whitelabelError, setWhitelabelError] = useState(false);
  const [whitelabelIconLink, setWhitelabelIconLink] = useState("");

  useEffect(() => {
    const fetchWhitelabelDetails = async () => {
      try {
        const details = await fetchRefererWhitelabelDetails();
        if (details) {
          document.documentElement.style.setProperty(
            "--primary-color",
            details.primary_color,
          );
          document.documentElement.style.setProperty(
            "--secondary-color",
            details.secondary_color,
          );
          document.documentElement.style.setProperty(
            "--success-color",
            details.success_color,
          );
          document.documentElement.style.setProperty(
            "--error-color",
            details.error_color,
          );
          document.documentElement.style.setProperty(
            "--background-color",
            details.background_color,
          );
          document.documentElement.style.setProperty(
            "--font-family",
            details.font_family,
          );
          setWhitelabelIconLink(details.icon_link);
          setIsWhitelabel(true);
        }
      } catch (error) {
        console.error(error);
        setWhitelabelError(true);
      }
    };

    fetchWhitelabelDetails();
  }, []);

  return (
    <div className="App">
      <Router>
        <Routes>
          {whitelabelError ? (
            <Route path="*" element={<Error />} />
          ) : isWhitelabel ? (
            <Route
              path="*"
              element={
                <Quote
                  isWhitelabel={isWhitelabel}
                  whitelabelIconLink={whitelabelIconLink}
                />
              }
            />
          ) : (
            <>
              <Route path="/company" element={<Company />} />
              <Route path="/users" element={<Users />} />
              <Route
                path="/quote"
                element={<Quote isWhitelabel={isWhitelabel} />}
              />
              <Route path="/price-list" element={<PriceList />} />
              <Route
                path="/waste-container-info"
                element={<WasteContainerInfo />}
              />
              <Route path="/oauth-callback" element={<OAuthCallback />} />
              <Route path="/whitelabel" element={<Whitelabel />} />
              <Route path="/" element={<Home />} />
              <Route path="*" element={<Error />} />
            </>
          )}
        </Routes>
      </Router>
      <ToastContainer />
    </div>
  );
};

export default App;
