import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { updatePriceListItem } from "../../api/quote";
import "./index.css";
import { useGlobalContext } from "../../GlobalContext";

const EditPriceListItem = ({ priceListItem, closeModalCb }) => {
  const { state } = useGlobalContext();
  const [formData, setFormData] = useState({
    container_type: "",
    waste_type: "",
    postcode_area_district: "",
    weight_restriction: 0,
    quantity: 0,
    price: 0,
  });

  useEffect(() => {
    if (priceListItem) {
      setFormData({
        weight_restriction: priceListItem.weight_restriction,
        quantity: priceListItem.quantity,
        price: priceListItem.price,
      });
    }
  }, [priceListItem]);

  const handleEdit = async () => {
    // what has changed?
    const updatedData = {};
    if (formData.weight_restriction !== priceListItem.weight_restriction) {
      updatedData.weight_restriction = formData.weight_restriction;
    }
    if (formData.price !== priceListItem.price) {
      updatedData.price = formData.price;
    }

    try {
      await updatePriceListItem(state.selectedCompany, {
        uuid: priceListItem.uuid,
        ...updatedData,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleChange = (e) => {
    const { name, value, type } = e.target;
    setFormData((prevData) => {
      const updatedData = {
        ...prevData,
        [name]: type === "number" ? parseFloat(value) : value,
      };

      return updatedData;
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleEdit().then(() => {
      closeModalCb();
    });
  };

  return (
    <div className="edit-price-list">
      <h2>Edit Price List</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="container_type">Container Type</label>
          <div className="read-only-value">{priceListItem.container_type}</div>
        </div>
        <div className="form-group">
          <label htmlFor="waste_type">Waste Type</label>
          <div className="read-only-value">{priceListItem.waste_type}</div>
        </div>
        <div className="form-group">
          <label htmlFor="waste_type">Postcode Area District</label>
          <div className="read-only-value">
            {priceListItem.postcode_area_district}
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="excess_weight_charge_gbp">Price (GBP)</label>
          <input
            type="number"
            id="price"
            name="price"
            value={formData.price}
            onChange={handleChange}
            step="0.01"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="excess_weight_charge_gbp">
            Weight Restriction (KG)
          </label>
          <input
            type="number"
            id="weight_restriction"
            name="weight_restriction"
            value={
              formData.weight_restriction !== null
                ? formData.weight_restriction
                : ""
            }
            onChange={handleChange}
            placeholder="None"
            step="1"
          />
        </div>
        <div className="form-actions">
          <button type="submit">Save</button>
          <button type="button" onClick={closeModalCb}>
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

EditPriceListItem.propTypes = {
  wasteContainerInfo: PropTypes.object,
  closeModalCb: PropTypes.func.isRequired,
};

export default EditPriceListItem;
