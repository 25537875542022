import React, { useEffect, useState } from "react";
import { currencyFormat } from "../../lib/formatters";
import PropTypes from "prop-types";
import QuoteSelectionWasteService from "../QouteSelectionWasteService";
import { MdEdit } from "react-icons/md";
import { useNavigate, useOutletContext } from "react-router-dom";
import ConfirmationModal from "../ConfirmationModal";
import {
  acceptAlternativeQuoteNoDetailsCollected,
  acceptQuoteNoDetailsCollected,
} from "../../api/quote";
import ProgressBar from "../ProgressBar";
import {
  containerTypeNameLookup,
  frequencyLookup,
  wasteTypeNameLookup,
  weeklyFrequencyLookup,
} from "../../lib/lookups";
import Modal from "../Modal";
import AlternativeQuotesList from "../AlternativeQuotesList";
import Tooltip from "../Tooltip";

const QuoteOption = ({ quote, onQuoteSelection, index }) => {
  const [detailsVisible, setDetailsVisible] = useState(
    index < 3 ? true : false,
  );

  const th = "px-1.5 py-1 lg:px-3 lg:py-2";
  const td =
    "px-1.5 py-1 text-[0.5rem] leading-3 text-gray-900 md:text-xs lg:px-3 lg:py-2 lg:text-sm";

  return (
    <div className="w-full rounded bg-slate-200 p-2 shadow-lg lg:p-4">
      <p className="border-b border-slate-600 p-2 text-xs text-gray-900 md:text-sm lg:border-b-2 lg:p-4 lg:text-base xl:text-lg">
        {quote.supplier_name}
      </p>
      <div className="flex justify-between p-2 lg:p-4">
        <p className="text-base text-black md:text-lg lg:text-xl xl:text-2xl">
          <b className="text-lg font-bold md:text-xl lg:text-2xl xl:text-3xl">
            {currencyFormat(quote.price_gbp)}
          </b>{" "}
          a month
        </p>
        <div className="flex flex-col gap-1.5 lg:gap-3 xl:gap-4">
          <button
            className="rounded-md border border-transparent bg-primaryColor px-2 py-1 text-center text-xs text-secondaryColor shadow-md transition-all hover:bg-slate-700 hover:shadow-lg focus:bg-slate-700 focus:shadow-none active:bg-slate-700 active:shadow-none md:text-sm lg:px-4 lg:py-2 lg:text-base xl:h-14 xl:w-40 xl:px-6 xl:py-3 xl:text-xl"
            onClick={() => onQuoteSelection(quote)}
          >
            Select
          </button>
          <button
            className="text-xs text-black underline hover:text-black md:text-sm lg:text-base xl:text-lg"
            onClick={() => setDetailsVisible(!detailsVisible)}
          >
            {detailsVisible ? "Less info" : "More info"}
          </button>
        </div>
      </div>
      {detailsVisible && (
        <div className="w-full border-t border-slate-600 p-2 lg:border-t-2 lg:p-4">
          <h2 className="mb-4 text-sm text-black md:text-base lg:text-lg">
            Service Breakdown
          </h2>
          <div className="relative w-full overflow-x-auto">
            <table className="w-full table-auto text-left text-[0.5rem] text-gray-500 md:text-xs lg:text-sm">
              <thead className="bg-gray-100 text-[0.5rem] uppercase leading-3 text-gray-700 md:text-[0.6rem] lg:text-xs">
                <tr>
                  <th className={th}>Type</th>
                  <th className={th}>Container</th>
                  <th className={th}>Price</th>
                  <th className={th}>Quantity</th>
                  <th className={th}>Frequency</th>
                  <th className={th}>Monthly Price</th>
                  <th className={th}>Excess Weight Charge</th>
                  <th className={th}>Weight Restriction</th>
                </tr>
              </thead>
              <tbody>
                {quote.price_breakdown.map((breakdown) => (
                  <tr
                    key={`${breakdown.type}.${breakdown.container}`}
                    className="border-b bg-white"
                  >
                    <td className={td}>
                      {wasteTypeNameLookup[breakdown.type]}
                    </td>
                    <td className={td}>
                      {containerTypeNameLookup[breakdown.container]}
                    </td>
                    <td className={td}>
                      {currencyFormat(breakdown.price_gbp)}
                    </td>
                    <td className={td}>{breakdown.quantity}</td>
                    <td className={td}>
                      {+breakdown.frequency >= 4.33
                        ? weeklyFrequencyLookup[breakdown.frequency]
                        : frequencyLookup[breakdown.frequency]}
                    </td>
                    <td className={td}>
                      {currencyFormat(breakdown.total_price_gbp)}
                    </td>
                    <td className={td}>
                      {breakdown.excess_weight_charge_gbp
                        ? currencyFormat(breakdown.excess_weight_charge_gbp)
                        : "None"}
                    </td>
                    <td className={td}>
                      {breakdown.weight_restriction_kilos ?? "None"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

const QuoteSelectionWhitelabel = () => {
  const navigate = useNavigate();
  const context = useOutletContext();
  const setCustomerDetails = context[0];
  const customerDetails = context[1];
  const services = context[2];
  const setQuotes = context[4];
  const setQuoteSessionUUID = context[5];
  const quotes = context[6];
  const setDesiredQuote = context[7];
  const setQuoteAcceptConfirm = context[8];
  const desiredQuote = context[9];
  const quoteAcceptConfirm = context[10];
  const quoteSessionUUID = context[11];
  const pages = context[12];
  const alternativeQuotes = context[15];
  const setAlternativeQuotes = context[16];
  const [showAlternativeQuotes, setShowAlternativeQuotes] = useState(false);
  const [isAlternativeQuoteSelected, setIsAlternativeQuoteSelected] =
    useState(false);
  const [selectedAlternativeQuote, setSelectedAlternativeQuote] =
    useState(null);
  const [isAlternativeButtonHighlighted, setIsAlternativeButtonHighlighted] =
    useState(true);

  useEffect(() => {
    if (customerDetails?.postcode === "") {
      navigate("/quote/postcode");
      return;
    } else if (services.length === 0) {
      navigate("/quote/waste-services");
      return;
    } else if (customerDetails?.email === "") {
      navigate("/quote/email");
      return;
    }
    setTimeout(() => {
      setIsAlternativeButtonHighlighted(false);
    }, 4000);
  }, []);

  const onQuoteSelection = (quote) => {
    setDesiredQuote(quote);
    setQuoteAcceptConfirm(true);
  };

  const resetQuoteBuilder = () => {
    setCustomerDetails({
      name: "",
      company_name: "",
      email: "",
      phone_number: "",
      address_line_1: "",
      address_line_2: "",
      town: "",
      postcode: "",
      county: "",
    });
    setQuotes([]);
    setQuoteSessionUUID(null);
    setAlternativeQuotes([]);
    navigate("/quote/postcode");
  };

  return (
    <>
      <ProgressBar
        setPage={(page) => navigate(`/quote/${page}`)}
        currPageIndex={3}
        pages={pages}
      />
      <div className="flex w-full flex-col gap-6 bg-slate-400 p-5 lg:flex-row lg:p-10">
        <div className="w-1/2">
          <h2 className="mb-3 block text-lg font-bold text-white md:text-xl lg:mb-6 lg:text-2xl xl:mb-10 xl:text-3xl">
            Your details
          </h2>
          <div className="flex w-fit gap-2 rounded bg-white p-2 lg:gap-4 lg:p-4">
            <div className="flex flex-col">
              <div className="flex justify-between gap-3 border-b px-1 py-2 text-[0.7rem] text-gray-700 md:text-xs lg:border-b-2 lg:px-2 lg:py-4 lg:text-sm xl:text-base">
                <p className="w-full">
                  Postcode :&nbsp;
                  <span className="font-bold text-black">
                    {customerDetails.postcode}
                  </span>
                </p>
                <button
                  onClick={() => navigate("/quote/postcode")}
                  className="flex flex-col items-center rounded-md border border-transparent bg-primaryColor px-1 py-0.5 text-center text-xs text-secondaryColor shadow-md transition-all hover:bg-slate-700 hover:shadow-lg focus:bg-slate-700 focus:shadow-none active:bg-slate-700 active:shadow-none md:text-sm lg:px-2 lg:py-1 lg:text-base"
                >
                  <MdEdit />
                  <p>Edit</p>
                </button>
              </div>
              <div className="flex justify-between gap-3 border-b px-1 py-2 text-[0.7rem] text-gray-700 md:text-xs lg:border-b-2 lg:px-2 lg:py-4 lg:text-sm xl:text-base">
                <p className="w-full">
                  Email :&nbsp;
                  <span className="font-bold text-black">
                    {customerDetails.email}
                  </span>
                </p>
                <button
                  onClick={() => navigate("/quote/email")}
                  className="flex flex-col items-center rounded-md border border-transparent bg-primaryColor px-1 py-0.5 text-center text-xs text-secondaryColor shadow-md transition-all hover:bg-slate-700 hover:shadow-lg focus:bg-slate-700 focus:shadow-none active:bg-slate-700 active:shadow-none md:text-sm lg:px-2 lg:py-1 lg:text-base"
                >
                  <MdEdit />
                  <p>Edit</p>
                </button>
              </div>
              <button
                onClick={resetQuoteBuilder}
                className="mt-3 self-center rounded-md border border-transparent bg-primaryColor px-2 py-1 text-center text-xs text-secondaryColor shadow-md transition-all hover:bg-slate-700 hover:shadow-lg focus:bg-slate-700 focus:shadow-none active:bg-slate-700 active:shadow-none"
              >
                Clear All
              </button>
            </div>
            <div className="flex flex-col">
              <div className="flex justify-between gap-3 px-1 py-2 text-[0.7rem] text-gray-700 md:text-xs lg:px-2 lg:py-4 lg:text-sm xl:text-base">
                <p className="w-full">Selected services:</p>
                <button
                  onClick={() => navigate("/quote/waste-services")}
                  className="flex flex-col items-center rounded-md border border-transparent bg-primaryColor px-1 py-0.5 text-center text-xs text-secondaryColor shadow-md transition-all hover:bg-slate-700 hover:shadow-lg focus:bg-slate-700 focus:shadow-none active:bg-slate-700 active:shadow-none md:text-sm lg:px-2 lg:py-1 lg:text-base"
                >
                  <MdEdit />
                  <p>Edit</p>
                </button>
              </div>
              <div className="flex flex-wrap gap-2 lg:gap-4">
                {isAlternativeQuoteSelected
                  ? selectedAlternativeQuote?.alternative_services?.map(
                      (service, index) => (
                        <QuoteSelectionWasteService
                          selectedService={service}
                          key={index}
                        />
                      ),
                    )
                  : services?.map((service, index) => (
                      <QuoteSelectionWasteService
                        selectedService={service}
                        key={index}
                      />
                    ))}
              </div>
            </div>
          </div>
        </div>
        <div className="flex w-1/2 flex-col items-center justify-center gap-4">
          {alternativeQuotes?.length > 0 && (
            <Tooltip
              text={
                "Check out these alternative options to help comply with new recycling regulations."
              }
              visible={isAlternativeButtonHighlighted}
            >
              <button
                onClick={() => {
                  setShowAlternativeQuotes(true);
                  setIsAlternativeButtonHighlighted(false);
                }}
                className="rounded-md border border-transparent bg-primaryColor px-2 py-1 text-center text-xs text-secondaryColor shadow-md transition-all hover:bg-slate-700 hover:shadow-lg focus:bg-slate-700 focus:shadow-none active:bg-slate-700 active:shadow-none md:text-sm lg:px-4 lg:py-2 lg:text-base xl:px-6 xl:py-3 xl:text-xl"
              >
                Alternative Recycling Options
              </button>
            </Tooltip>
          )}
          {isAlternativeQuoteSelected && (
            <button
              onClick={() => {
                setIsAlternativeQuoteSelected(false);
                setSelectedAlternativeQuote(null);
              }}
              className="rounded-md border border-transparent bg-primaryColor px-2 py-1 text-center text-xs text-secondaryColor shadow-md transition-all hover:bg-slate-700 hover:shadow-lg focus:bg-slate-700 focus:shadow-none active:bg-slate-700 active:shadow-none md:text-sm lg:px-4 lg:py-2 lg:text-base xl:px-6 xl:py-3 xl:text-xl"
            >
              Back to Original Quote
            </button>
          )}
        </div>
      </div>
      {(isAlternativeQuoteSelected &&
        selectedAlternativeQuote?.quotes?.length === 0) ||
      quotes?.length === 0 ? (
        <p className="mb-1.5 p-2 text-center text-base text-black md:text-lg lg:mb-3 lg:p-4 lg:text-xl xl:text-2xl">
          Sorry there are no quotes available at this time.
        </p>
      ) : (
        <div className="w-full p-5 lg:p-10">
          <h2 className="mb-3 block text-lg font-bold text-black md:text-xl lg:mb-6 lg:text-2xl xl:text-3xl">
            Your quotes
          </h2>

          <div className="flex w-full flex-col gap-2 lg:gap-4">
            {isAlternativeQuoteSelected
              ? selectedAlternativeQuote?.quotes
                  ?.sort((a, b) => a.price_gbp - b.price_gbp)
                  .map((quote, index) => (
                    <QuoteOption
                      key={quote.quote_uuid}
                      quote={quote}
                      onQuoteSelection={onQuoteSelection}
                      index={index}
                    />
                  ))
              : quotes
                  ?.sort((a, b) => a.price_gbp - b.price_gbp)
                  .map((quote, index) => (
                    <QuoteOption
                      key={quote.quote_uuid}
                      quote={quote}
                      onQuoteSelection={onQuoteSelection}
                      index={index}
                    />
                  ))}
          </div>
        </div>
      )}
      {quoteAcceptConfirm === true && (
        <ConfirmationModal
          isOpen
          onClose={() => setQuoteAcceptConfirm(false)}
          onConfirm={() => {
            navigate("/quote/customer-details");
            if (isAlternativeQuoteSelected) {
              acceptAlternativeQuoteNoDetailsCollected(
                quoteSessionUUID,
                desiredQuote,
              );
            } else {
              acceptQuoteNoDetailsCollected(
                quoteSessionUUID,
                desiredQuote.quote_uuid,
              );
            }

            setQuoteAcceptConfirm(false);
          }}
          question={`Are you sure you want to accept the quote of ${currencyFormat(
            desiredQuote.price_gbp,
          )} from ${desiredQuote.supplier_name || "Supplier 1"}?`}
        />
      )}
      <Modal isOpen={showAlternativeQuotes}>
        <AlternativeQuotesList
          alternativeQuotes={alternativeQuotes}
          setShowAlternativeQuotes={setShowAlternativeQuotes}
          setIsAlternativeQuoteSelected={setIsAlternativeQuoteSelected}
          setSelectedAlternativeQuote={setSelectedAlternativeQuote}
        />
      </Modal>
    </>
  );
};

QuoteOption.propTypes = {
  quote: PropTypes.object.isRequired,
  onQuoteSelection: PropTypes.func.isRequired,
};

export default QuoteSelectionWhitelabel;
