import React from "react";
import QuoteSelectionWasteService from "../QouteSelectionWasteService";
import { MdClose } from "react-icons/md";
import { currencyFormat } from "../../lib/formatters";
import { getCompanyPublicDetails } from "../../api/company";

const AlternativeQuotesList = ({
  alternativeQuotes,
  setShowAlternativeQuotes,
  setIsAlternativeQuoteSelected,
  setSelectedAlternativeQuote,
}) => {
  const selectAlternativeQuote = async (alternativeQuote) => {
    // check if more than one supplier in quotes
    const uniqueSuppliers = Array.from(
      new Set(alternativeQuote.quotes?.map((quote) => quote.supplier_uuid)),
    );
    const newAlternativeQuote = { ...alternativeQuote };
    // if no quotes or one redacted supplier skip and setSelectedAlternativeQuote as alternativeQuote
    // if one non redacted supplier get supplier name
    if (
      alternativeQuote?.quotes?.length > 0 &&
      uniqueSuppliers.length < 2 &&
      !alternativeQuote?.quotes[0]?.redact_supplier_details
    ) {
      const companyDetails = await getCompanyPublicDetails([
        alternativeQuote?.quotes[0]?.supplier_uuid,
      ]);
      const quotesWithSupplierName = alternativeQuote?.quotes?.map((quote) => ({
        ...quote,
        supplier_name: companyDetails[0].name,
      }));

      newAlternativeQuote.quotes = quotesWithSupplierName;
    } else if (
      alternativeQuote?.quotes?.length > 0 &&
      uniqueSuppliers.length >= 2
    ) {
      // if multiple suppliers always replace with redacted or non redacted name
      const nonRedactedSuppliers = [];
      alternativeQuote?.quotes?.forEach((quote) => {
        if (!quote.redact_supplier_details) {
          nonRedactedSuppliers.push(quote.supplier_uuid);
        }
      });
      let companyDetails = [];
      if (nonRedactedSuppliers.length > 0) {
        companyDetails = await getCompanyPublicDetails(nonRedactedSuppliers);
      }
      const companyLookup = {};
      companyDetails?.forEach((company) => {
        companyLookup[company.uuid] = company.name;
      });
      const quotesWithSupplierName = alternativeQuote?.quotes?.map(
        (quote, index) => {
          const newQuote = { ...quote };
          if (quote.redact_supplier_details) {
            newQuote.supplier_name = `Supplier ${index + 1}`;
          } else {
            newQuote.supplier_name = companyLookup[quote.supplier_uuid];
          }
          return newQuote;
        },
      );

      newAlternativeQuote.quotes = quotesWithSupplierName;
    }
    setIsAlternativeQuoteSelected(true);
    setSelectedAlternativeQuote(newAlternativeQuote);
    setShowAlternativeQuotes(false);
  };
  return (
    <div className="relative h-[80vh] w-[80vw] overflow-y-scroll p-6 lg:w-[50vw]">
      <div className="sticky top-0 flex w-full justify-end">
        <button onClick={() => setShowAlternativeQuotes(false)}>
          <MdClose className="text-4xl text-red-500" />
        </button>
      </div>
      <h2 className="text-center text-sm font-bold lg:text-lg">
        We have generated alternative quotes for you, based on what you
        selected.
      </h2>
      <ul className="flex w-full flex-col gap-2 lg:gap-4">
        {alternativeQuotes?.map((quote, index) => {
          if (quote.quotes === null) {
            return;
          }
          return (
            <li
              key={index}
              className="flex w-full justify-between rounded bg-gray-100 p-2 shadow-lg lg:p-4"
            >
              <div className="flex flex-wrap gap-2 lg:gap-4">
                {quote?.alternative_services?.map((service, index) => (
                  <QuoteSelectionWasteService
                    selectedService={service}
                    key={index}
                  />
                ))}
              </div>
              <div>
                <p className="text-xs text-black md:text-base lg:text-lg">
                  From{" "}
                  <b className="text-sm font-bold md:text-lg lg:text-xl">
                    {currencyFormat(quote.lowest_price)}
                  </b>{" "}
                  a month
                </p>
                <button
                  onClick={() => selectAlternativeQuote(quote)}
                  className="rounded-md border border-transparent bg-primaryColor px-2 py-1 text-center text-xs text-secondaryColor shadow-md transition-all hover:bg-slate-700 hover:shadow-lg focus:bg-slate-700 focus:shadow-none active:bg-slate-700 active:shadow-none md:text-sm lg:px-4 lg:py-2 lg:text-base"
                >
                  See Quotes
                </button>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default AlternativeQuotesList;
