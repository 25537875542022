import React, { useEffect, useState } from "react";
import PageWrapper from "../../components/PageWrapper";
import PaginationControls from "../../components/PaginationControls";
import { fetchCompanyQuotes, fetchQuotePDF } from "../../api/quote";
import { useGlobalContext } from "../../GlobalContext";
import {
  currencyFormat,
  relativeTimeFormatter,
  singleLineAddress,
} from "../../lib/formatters";
import { downloadPDF } from "../../lib/pdf";
import { DayPicker } from "react-day-picker";
import "react-day-picker/style.css";
import Modal from "../../components/Modal";
import { useNavigate } from "react-router-dom";
import {
  containerTypeNameLookup,
  daysLookup,
  frequencyLookup,
  wasteTypeNameLookup,
  weeklyFrequencyLookup,
} from "../../lib/lookups";
import { getCompanyPublicDetails } from "../../api/company";
import { getUserUuid } from "../../lib/auth";

const QuoteDetails = ({ quote, currentUser }) => {
  const [detailsVisible, setDetailsVisible] = useState(false);
  const [PDFBlob, setPDFBlob] = useState(null);
  const { state } = useGlobalContext();
  const navigate = useNavigate();

  const getPDF = async () => {
    if (PDFBlob !== null) {
      downloadPDF(PDFBlob);
    } else {
      const pdf = await fetchQuotePDF(state?.selectedCompany, quote.uuid);
      downloadPDF(pdf);
      setPDFBlob(pdf);
    }
  };

  const th = "px-3 py-2";
  const td = "px-3 py-2 text-sm text-gray-900";

  return (
    <div
      className={`${quote.alternative_quote ? "bg-green-100" : quote.created_by === currentUser ? "bg-purple-100" : quote.is_internal ? "bg-blue-100" : "bg-slate-200"} w-full rounded p-4 shadow-lg`}
    >
      <div className="flex justify-between p-4">
        <div className="flex flex-col gap-3 xl:gap-4">
          <p className="text-xl text-black xl:text-2xl">
            <b className="text-2xl font-bold xl:text-3xl">
              {currencyFormat(quote?.total_price_gbp)}
            </b>{" "}
            a month
          </p>

          <p>
            {quote?.customer_details?.name &&
              quote?.customer_details?.name + ", "}
            {quote?.customer_details?.email &&
              quote?.customer_details?.email + ", "}
            {quote?.customer_details?.postcode &&
              quote?.customer_details?.postcode + ", "}
            {relativeTimeFormatter(quote?.created_at)}
          </p>
          <p>Supplier: {quote?.supplier_name || quote?.supplier_uuid}</p>
        </div>
        <div className="flex flex-col gap-3 xl:gap-4">
          {quote?.is_internal && !quote?.customer_details?.name ? (
            <button
              className="rounded-md border border-transparent bg-primaryColor px-4 py-2 text-center text-base text-secondaryColor shadow-md transition-all hover:bg-slate-700 hover:shadow-lg focus:bg-slate-700 focus:shadow-none active:bg-slate-700 active:shadow-none"
              onClick={() => navigate(`/company-quotes/quote/${quote.uuid}`)}
            >
              Add Customer Details
            </button>
          ) : (
            <button
              className="rounded-md border border-transparent bg-primaryColor px-4 py-2 text-center text-base text-secondaryColor shadow-md transition-all hover:bg-slate-700 hover:shadow-lg focus:bg-slate-700 focus:shadow-none active:bg-slate-700 active:shadow-none"
              onClick={getPDF}
            >
              Download PDF
            </button>
          )}

          <button
            className="text-base text-black underline hover:text-black"
            onClick={() => setDetailsVisible(!detailsVisible)}
          >
            {detailsVisible ? "Less info" : "More info"}
          </button>
        </div>
      </div>
      {detailsVisible && (
        <>
          <div className="w-full border-t-2 border-slate-600 p-4">
            <h2 className="mb-4 text-base text-black xl:text-lg">
              Customer Details
            </h2>
            {quote?.is_internal && !quote?.customer_details?.name ? (
              <p className="mb-4 text-sm text-black xl:text-base">
                No customer details available
              </p>
            ) : (
              <div className="relative w-full overflow-x-auto">
                <table className="w-full table-auto text-left text-sm text-gray-500">
                  <thead className="bg-gray-100 text-xs uppercase text-gray-700">
                    <tr>
                      <th className={th}>Name</th>
                      <th className={th}>Company Name</th>
                      <th className={th}>Email</th>
                      <th className={th}>Phone</th>
                      <th className={th}>Address</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="border-b bg-white">
                      <td className={td}>{quote?.customer_details?.name}</td>
                      <td className={td}>
                        {quote?.customer_details?.company_name ||
                          "Not provided"}
                      </td>
                      <td className={td}>{quote?.customer_details?.email}</td>
                      <td className={td}>
                        {quote?.customer_details?.phone || "Not provided"}
                      </td>
                      <td className={td}>
                        {singleLineAddress(quote?.customer_details)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </div>
          <div className="w-full border-t-2 border-slate-600 p-4">
            <h2 className="mb-4 text-base text-black xl:text-lg">
              Service Breakdown
            </h2>
            <div className="relative w-full overflow-x-auto">
              <table className="w-full table-auto text-left text-sm text-gray-500">
                <thead className="bg-gray-100 text-xs uppercase text-gray-700">
                  <tr>
                    <th className={th}>Type</th>
                    <th className={th}>Container</th>
                    <th className={th}>Marked Up Price</th>
                    <th className={th}>Original Price</th>
                    <th className={th}>Markup Factor</th>
                    <th className={th}>Quantity</th>
                    <th className={th}>Frequency</th>
                    <th className={th}>Monthly Price</th>
                    <th className={th}>Excess Weight Charge</th>
                    <th className={th}>Weight Restriction</th>
                  </tr>
                </thead>
                <tbody>
                  {quote?.pricing_breakdown?.map((breakdown) => (
                    <tr
                      key={`${breakdown.type}.${breakdown.container}`}
                      className="border-b bg-white"
                    >
                      <td className={td}>
                        {wasteTypeNameLookup[breakdown.type]}
                      </td>
                      <td className={td}>
                        {containerTypeNameLookup[breakdown.container]}
                      </td>
                      <td className={td}>
                        {currencyFormat(breakdown.price_gbp)}
                      </td>
                      <td className={td}>
                        {breakdown.original_price > 0
                          ? currencyFormat(breakdown.original_price)
                          : ""}
                      </td>
                      <td className={td}>
                        {breakdown.markup_factor > 0 && breakdown.markup_factor}
                      </td>
                      <td className={td}>{breakdown.quantity}</td>
                      <td className={td}>
                        {+breakdown.frequency >= 4.33
                          ? weeklyFrequencyLookup[breakdown.frequency]
                          : frequencyLookup[breakdown.frequency]}
                      </td>
                      <td className={td}>
                        {currencyFormat(breakdown.total_price_gbp)}
                      </td>
                      <td className={td}>
                        {breakdown.excess_weight_charge_gbp
                          ? currencyFormat(breakdown.excess_weight_charge_gbp)
                          : "None"}
                      </td>
                      <td className={td}>
                        {breakdown.weight_restriction_kilos ?? "None"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const FilterByOwnToggle = ({ checked, handleChange }) => {
  return (
    <div className="relative">
      <label className="inline-flex cursor-pointer items-center">
        <label className="mr-2 bg-white px-1 text-base">My Quotes</label>
        <input
          type="checkbox"
          className="peer sr-only"
          checked={checked}
          onChange={handleChange}
        />
        <div className="peer relative h-6 w-11 rounded-full bg-gray-200 after:absolute after:start-[2px] after:top-[2px] after:size-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-blue-600 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rtl:peer-checked:after:-translate-x-full dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-blue-800"></div>
      </label>
    </div>
  );
};

const QuoteManagement = () => {
  const { state } = useGlobalContext();
  const daysAgo = (n) => {
    const d = new Date();
    d.setDate(d.getDate() - n);
    return d;
  };

  const [companyQuotes, setCompanyQuotes] = useState([]);
  const [filteredCompanyQuotes, setFilteredCompanyQuotes] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [startDateValue, setStartDateValue] = useState(30);
  const [dateRange, setDateRange] = useState({
    from: daysAgo(30),
    to: daysAgo(0),
  });
  const [showDatePickerButton, setShowDatePickerButton] = useState(false);
  const [showDatePickerModal, setShowDatePickerModal] = useState(false);
  const [filterByCurrentUser, setFilterByCurrentUser] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const user = getUserUuid();
    setCurrentUser(user);
  }, [state]);

  useEffect(() => {
    const fetchQuotes = async () => {
      const date = new Date();
      date.setDate(new Date(dateRange?.to).getDate() + 1);
      const newEndDate = date.toISOString();
      const quotes = await fetchCompanyQuotes(
        state?.selectedCompany,
        new Date(dateRange?.from).toISOString(),
        newEndDate,
      );
      if (quotes.length === 0) {
        setCompanyQuotes([]);
        setFilteredCompanyQuotes([]);
        setPageCount(1);
        setPageNumber(1);
      } else {
        const nonRedactedSuppliers = [];
        quotes?.forEach((quote) => {
          if (quote.supplier_uuid) {
            nonRedactedSuppliers.push(quote.supplier_uuid);
          }
        });
        const companyDetails =
          await getCompanyPublicDetails(nonRedactedSuppliers);
        const companyLookup = {};
        companyDetails?.forEach((company) => {
          companyLookup[company.uuid] = company.name;
        });
        const quotesWithSupplierName = quotes?.map((quote) => {
          const newQuote = { ...quote };
          newQuote.supplier_name = companyLookup[quote.supplier_uuid];
          return newQuote;
        });
        setCompanyQuotes(quotesWithSupplierName);
        setFilteredCompanyQuotes(quotesWithSupplierName);
        setSearchTerm("");
        setPageNumber(1);
        setPageCount(Math.ceil(quotes.length / 15));
      }
    };
    fetchQuotes();
  }, [dateRange, state.selectedCompany]);

  useEffect(() => {
    let quotesToFilter;
    if (filterByCurrentUser) {
      quotesToFilter = companyQuotes.filter((quote) => {
        return currentUser === quote.created_by;
      });
    } else {
      quotesToFilter = companyQuotes;
    }
    const quotes = quotesToFilter.filter((quote) => {
      return (
        quote.customer_details?.name
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        quote.supplier_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        quote.customer_details?.email
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        quote.customer_details?.address_line_1
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        quote.customer_details?.postcode
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase())
      );
    });

    setFilteredCompanyQuotes(quotes);
    setPageCount(Math.ceil(quotes.length / 15));
  }, [searchTerm, filterByCurrentUser]);

  const onSearchChange = (e) => {
    setPageNumber(1);
    setSearchTerm(e.target.value);
  };

  const handleDateChange = async (e) => {
    const date = e.target.value;
    setStartDateValue(date);
    if (date === "custom") {
      setShowDatePickerButton(true);
    } else {
      setShowDatePickerButton(false);
      setDateRange({ from: daysAgo(date), to: daysAgo(0) });
    }
  };

  return (
    <>
      <PageWrapper>
        <div className="size-full p-8 xl:p-10 2xl:p-12">
          <h1 className="my-8 block text-2xl font-bold text-black">
            Quote Management
          </h1>
          <div className="mb-6 flex flex-wrap items-center gap-4 rounded-md border border-gray-200 p-3 shadow-sm">
            <h3>Quote Types:</h3>
            <div className="flex items-center">
              <div className="mr-2 size-4 rounded bg-purple-100"></div>
              <span className="text-sm">My Quotes</span>
            </div>
            <div className="flex items-center">
              <div className="mr-2 size-4 rounded bg-green-100"></div>
              <span className="text-sm">Alternative Quotes</span>
            </div>
            <div className="flex items-center">
              <div className="mr-2 size-4 rounded bg-blue-100"></div>
              <span className="text-sm">Internal Quotes</span>
            </div>
            <div className="flex items-center">
              <div className="mr-2 size-4 rounded bg-slate-200"></div>
              <span className="text-sm">Standard Quotes</span>
            </div>
          </div>
          <FilterByOwnToggle
            checked={filterByCurrentUser}
            handleChange={(e) => setFilterByCurrentUser(e.target.checked)}
          />
          <div className="mb-3 flex w-full items-center justify-between gap-4">
            <input
              name="search"
              id="search"
              type="text"
              placeholder="Search name, supplier, address or email"
              onChange={onSearchChange}
              className="h-8 w-1/4 appearance-none rounded border-2 px-3 py-2 text-sm leading-tight text-gray-700 shadow focus:outline-none focus:ring 2xl:h-10 2xl:text-base"
            />
            <select
              className="h-8 w-1/6 rounded border-2 px-2 py-1 text-sm leading-tight text-gray-700 shadow focus:outline-none focus:ring"
              id="time-period"
              name="time-period"
              value={startDateValue}
              onChange={handleDateChange}
            >
              {daysLookup.map((day, index) => {
                return (
                  <option key={index} value={day.value}>
                    {day.text}
                  </option>
                );
              })}
              <option value="custom">Custom</option>
            </select>
            {showDatePickerButton && (
              <button
                className="h-8 w-1/6 rounded border-2 px-2 py-1 text-sm leading-tight text-gray-700 shadow focus:outline-none focus:ring"
                onClick={() => setShowDatePickerModal(true)}
              >
                {new Date(dateRange?.from).toLocaleDateString("en-GB")} to{" "}
                {new Date(dateRange?.to).toLocaleDateString("en-GB")}
              </button>
            )}
            <PaginationControls
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
              pageCount={pageCount}
            />
          </div>
          <ul>
            {filteredCompanyQuotes
              ?.slice((pageNumber - 1) * 15, (pageNumber - 1) * 15 + 15)
              .map((quote, index) => (
                <li key={index} className="mb-4">
                  <QuoteDetails quote={quote} currentUser={currentUser} />
                </li>
              ))}
          </ul>
          <PaginationControls
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            pageCount={pageCount}
          />
        </div>
      </PageWrapper>
      <Modal isOpen={showDatePickerModal}>
        <div className="flex flex-col items-center gap-4 p-10">
          <DayPicker
            mode="range"
            selected={dateRange}
            onSelect={setDateRange}
          />
          <button
            className="rounded-md border border-transparent bg-primaryColor px-4 py-2 text-center text-base text-secondaryColor shadow-md transition-all hover:bg-slate-700 hover:shadow-lg focus:bg-slate-700 focus:shadow-none active:bg-slate-700 active:shadow-none"
            onClick={() => setShowDatePickerModal(false)}
          >
            Close
          </button>
        </div>
      </Modal>
    </>
  );
};

export default QuoteManagement;
