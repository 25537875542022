import React from "react";

import { MdOutlineDeleteForever } from "react-icons/md";
import PropTypes from "prop-types";
import WasteIcon from "../WasteIcon";

const SelectedWasteService = ({
  selectedService,
  setServices,
  index,
  frequencyLookup,
  wasteTypeNameLookup,
}) => {
  const handleDelete = () => {
    setServices((prevServices) => {
      const updatedServices = prevServices.toSpliced(index, 1);
      return updatedServices;
    });
  };
  return (
    <div className="mb-3 flex h-64 w-44 flex-col items-center rounded-md border border-transparent bg-slate-200 p-3 text-center shadow-sm xl:h-80 xl:w-56 xl:p-6 2xl:h-96 2xl:w-72">
      <div className="mb-3 size-20 xl:size-28 2xl:size-36">
        <WasteIcon wasteType={selectedService.waste_type} />
      </div>
      <p className="text-black-700 2xl:text-md mb-2 mt-1 block text-xs font-bold xl:text-sm">
        {wasteTypeNameLookup[selectedService.waste_type]?.name}
      </p>
      <p className="text-black-700 2xl:text-md my-1 block text-xs font-bold xl:text-sm">
        {selectedService.container_type}
      </p>
      <p className="text-black-700 2xl:text-md my-1 block text-xs font-bold xl:text-sm">
        Quantity: {selectedService.quantity}
      </p>
      <p className="text-black-700 2xl:text-md my-1 block text-xs font-bold xl:text-sm">
        Frequency: {frequencyLookup[selectedService.frequency]}
      </p>
      {setServices && (
        <button onClick={handleDelete}>
          <MdOutlineDeleteForever className="my-2 size-6 text-errorColor xl:size-8 2xl:size-10" />
        </button>
      )}
    </div>
  );
};
SelectedWasteService.propTypes = {
  selectedService: PropTypes.object.isRequired,
  setServices: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  frequencyLookup: PropTypes.object.isRequired,
  wasteTypeNameLookup: PropTypes.object.isRequired,
};

export default SelectedWasteService;
