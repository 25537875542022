import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { fetchAvailableServices } from "../../api/quote";
import WasteServiceType from "../WasteServiceType";
import SelectedWasteService from "../SelectedWasteService";
import { FaLongArrowAltRight } from "react-icons/fa";
import { InfinitySpin } from "react-loader-spinner";

const WasteServiceSelection = ({
  onSubmit,
  existingServices,
  postcodeAreaDistrict,
  frequencyLookup,
  wasteTypeNameLookup,
}) => {
  const [services, setServices] = useState(existingServices);
  const [availableServices, setAvailableServices] = useState({});
  const [loadingAvailableServices, setLoadingAvailableServices] =
    useState(true);

  useEffect(() => {
    fetchAvailableServices(postcodeAreaDistrict).then((services) => {
      const availableServicesByWasteType = {};
      services.forEach((service) => {
        if (availableServicesByWasteType[service.waste_type]) {
          availableServicesByWasteType[service.waste_type].push(
            service.container_type,
          );
        } else {
          availableServicesByWasteType[service.waste_type] = [
            service.container_type,
          ];
        }
      });
      setAvailableServices(availableServicesByWasteType);
      setLoadingAvailableServices(false);
    });
  }, []);

  return (
    <div className="flex w-full flex-1 flex-col items-center">
      <div className="w-5/6 border-b-2 px-3 py-4 xl:px-6 xl:py-8">
        <h2 className="text-black-900 mb-8 block text-center text-2xl font-bold">
          These are the services available in your area. Select at least one to
          continue.
        </h2>
        <div className="flex w-full justify-center">
          {loadingAvailableServices && (
            <InfinitySpin height="200" width="200" color="black" />
          )}
        </div>
        {availableServices.length === 0 && !loadingAvailableServices && (
          <p className="text-black-700 mb-3 text-center text-xl">
            Sorry there are no available services in your area.
          </p>
        )}
        <div className="flex flex-wrap justify-evenly gap-4 xl:gap-6 2xl:gap-8">
          {Object.keys(availableServices).map((wasteType, index) => {
            return (
              <WasteServiceType
                wasteType={wasteType}
                containerTypes={availableServices[wasteType]}
                key={index}
                setServices={setServices}
                onSubmit={onSubmit}
                services={services}
                frequencyLookup={frequencyLookup}
                wasteTypeNameLookup={wasteTypeNameLookup}
              />
            );
          })}
        </div>
      </div>
      <div className="w-5/6 px-3 py-4 xl:px-6 xl:py-8">
        <h2 className="text-black-900 mb-8 block text-center text-2xl font-bold 2xl:mb-10">
          Selected services
        </h2>
        {services.length === 0 ? (
          <p className="text-black-700 text-center text-xl">
            Add a service to continue your quote.
          </p>
        ) : (
          <div className="flex w-full px-6 py-10">
            <div className="flex w-4/5 flex-wrap gap-3 xl:gap-5 2xl:gap-8">
              {services.map((service, index) => {
                return (
                  <SelectedWasteService
                    selectedService={service}
                    setServices={setServices}
                    key={index}
                    index={index}
                    frequencyLookup={frequencyLookup}
                    wasteTypeNameLookup={wasteTypeNameLookup}
                  />
                );
              })}
            </div>
            <div className="my-8 ml-4 flex h-64 w-1/5 items-center justify-center xl:ml-8 2xl:ml-10">
              <button
                className="flex h-40 w-full flex-col items-center justify-center rounded-md border border-transparent bg-primaryColor px-4 py-2 text-center text-lg text-secondaryColor shadow-md transition-all hover:bg-slate-700 hover:shadow-lg focus:bg-slate-700 focus:shadow-none active:bg-slate-700 active:shadow-none disabled:pointer-events-none disabled:opacity-70 disabled:shadow-none xl:text-xl 2xl:text-2xl"
                onClick={() => onSubmit(services)}
                disabled={services.length == 0}
              >
                Continue
                <br />
                to quotes
                <FaLongArrowAltRight className="mt-2 w-full" />
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

WasteServiceSelection.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  existingServices: PropTypes.arrayOf(PropTypes.object).isRequired,
  postcodeAreaDistrict: PropTypes.string.isRequired,
  frequencyLookup: PropTypes.object.isRequired,
  wasteTypeNameLookup: PropTypes.object.isRequired,
};

export default WasteServiceSelection;
