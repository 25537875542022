import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useMemo,
} from "react";
import {
  getDefaultCompanyUuid,
  setDefaultCompanyUuid,
} from "./lib/persistence";
import { getCompanyRole } from "./api/company";

const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
  const defaultCompany = getDefaultCompanyUuid();
  const initialState = {
    selectedCompany: defaultCompany,
    selectedCompanyRole: null,
  };
  const [state, setState] = useState(initialState);

  useEffect(() => {
    const fetchCompanyRole = async () => {
      try {
        const companyRole = await getCompanyRole(state.selectedCompany);
        setState((prevState) => ({
          ...prevState,
          selectedCompanyRole: companyRole,
        }));
      } catch (error) {
        console.error(error);
      }
    };

    if (state.selectedCompany) {
      setDefaultCompanyUuid(state.selectedCompany);
      fetchCompanyRole();
    }
  }, [state.selectedCompany]);

  const resetState = () => {
    setState(initialState);
    setDefaultCompanyUuid(null);
  };

  const contextValue = useMemo(
    () => ({ state, setState, resetState }),
    [state, resetState],
  );

  return (
    <GlobalContext.Provider value={contextValue}>
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobalContext = () => useContext(GlobalContext);
