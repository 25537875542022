import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { patchWasteContainerInfo } from "../../api/quote";
import "./index.css";
import { useGlobalContext } from "../../GlobalContext";

const EditWasteContainerInfo = ({ wasteContainerInfo, closeModalCb }) => {
  const { state } = useGlobalContext();
  const [formData, setFormData] = useState({
    container_type: "",
    waste_type: "",
    excess_weight_charge_gbp: 0,
    weight_restriction_kilos: 0,
  });

  useEffect(() => {
    if (wasteContainerInfo) {
      setFormData({
        excess_weight_charge_gbp: wasteContainerInfo.excess_weight_charge_gbp,
        weight_restriction_kilos: wasteContainerInfo.weight_restriction_kilos,
      });
    }
  }, [wasteContainerInfo]);

  const handleEdit = async () => {
    // this is only a partial update, so provide only what we need
    if (
      formData.excess_weight_charge_gbp ===
      wasteContainerInfo.excess_weight_charge_gbp
    ) {
      delete formData.excess_weight_charge_gbp;
    }
    if (
      formData.weight_restriction_kilos ===
      wasteContainerInfo.weight_restriction_kilos
    ) {
      delete formData.weight_restriction_kilos;
    }

    try {
      await patchWasteContainerInfo(state.selectedCompany, {
        container_type: wasteContainerInfo.container_type,
        waste_type: wasteContainerInfo.waste_type,
        ...formData,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleChange = (e) => {
    const { name, value, type } = e.target;
    setFormData((prevData) => {
      const updatedData = {
        ...prevData,
        [name]: type === "number" ? parseFloat(value) : value,
      };

      return updatedData;
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleEdit().then(() => {
      closeModalCb();
    });
  };

  return (
    <div className="edit-waste-container-info">
      <h2>Edit Waste Container Info</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="container_type">Container Type</label>
          <div className="read-only-value">
            {wasteContainerInfo.container_type}
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="waste_type">Waste Type</label>
          <div className="read-only-value">{wasteContainerInfo.waste_type}</div>
        </div>
        <div className="form-group">
          <label htmlFor="excess_weight_charge_gbp">
            Excess Weight Charge (GBP)
          </label>
          <input
            type="number"
            id="excess_weight_charge_gbp"
            name="excess_weight_charge_gbp"
            value={formData.excess_weight_charge_gbp}
            onChange={handleChange}
            step="0.01"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="weight_restriction_kilos">
            Weight Restriction (Kilos)
          </label>
          <input
            type="number"
            id="weight_restriction_kilos"
            name="weight_restriction_kilos"
            value={formData.weight_restriction_kilos}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-actions">
          <button type="submit">Save</button>
          <button type="button" onClick={closeModalCb}>
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

EditWasteContainerInfo.propTypes = {
  wasteContainerInfo: PropTypes.object,
  closeModalCb: PropTypes.func.isRequired,
};

export default EditWasteContainerInfo;
