import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { handleOAuthCallback } from "../../lib/auth";
import ENV from "../../lib/config";

const OAuthCallback = () => {
  const location = useLocation();
  const [error, setError] = useState(null);
  const [callbackHandled, setCallbackHandled] = useState(false);

  // getting issues here because this useEffect is being called several times

  useEffect(() => {
    const handle = async () => {
      const params = new URLSearchParams(location.search);

      if (params.has("error")) {
        setError(params.get("error") + ": " + params.get("error_description"));
        return;
      }

      if (params.has("code") === false) {
        // No code in the URL, so we can't continue
        return;
      }

      try {
        await handleOAuthCallback(
          ENV.get().REACT_APP_OAUTH2_CLIENT_ID,
          ENV.get().REACT_APP_OAUTH2_TOKEN_URL,
          "/company",
        );
      } catch (error) {
        console.error(error);
      }
    };

    if (!callbackHandled) {
      setCallbackHandled(true);
      handle();
    }
  }, []);

  return (
    <div>
      {error != null ? (
        <p>Error logging you in: {JSON.stringify(error)}</p>
      ) : (
        <p>Logging you in...</p>
      )}
    </div>
  );
};

export default OAuthCallback;
