import { getAccessToken } from "../lib/auth";
import { getQuoteApiUrl } from "./main";

export const createQuote = async (email, postcode, requestedServices) => {
  const accessToken = getAccessToken();
  let header;
  accessToken
    ? (header = {
        Authorization: `Bearer ${getAccessToken()}`,
        "Content-Type": "application/json",
      })
    : (header = {
        "Content-Type": "application/json",
      });
  const response = await fetch(getQuoteApiUrl("/quote"), {
    method: "POST",
    headers: header,
    body: JSON.stringify({
      postcode_email_details: { email, postcode },
      requested_services: requestedServices,
    }),
  });

  if (response.ok) {
    return await response.json();
  } else {
    console.error("Failed to create quote");
    throw new Error("Failed to create quote");
  }
};
export const acceptQuoteNoDetailsCollected = async (
  quoteSessionUUID,
  quoteUUID,
) => {
  const response = await fetch(
    getQuoteApiUrl(`/quote/${quoteSessionUUID}/${quoteUUID}/accept_no_details`),
    {
      method: "POST",
    },
  );

  if (response.ok) {
    return await response.json();
  } else {
    console.error("Failed to change quote status");
    throw new Error("Failed to change quote status");
  }
};
export const acceptQuote = async (
  customerDetails,
  quoteSessionUUID,
  quoteUUID,
) => {
  const response = await fetch(
    getQuoteApiUrl(`/quote/${quoteSessionUUID}/${quoteUUID}/accept`),
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        customer_details: customerDetails,
      }),
    },
  );

  if (response.ok) {
    return await response.json();
  } else {
    console.error("Failed to accept quote");
    throw new Error("Failed to accept quote");
  }
};
export const fetchAvailableServices = async (postcodeAreaDistrict) => {
  const response = await fetch(
    getQuoteApiUrl(`/quote/available-services/${postcodeAreaDistrict}`),
  );
  if (response.ok) {
    return await response.json();
  } else {
    console.error("Failed to fetch available services");
    throw new Error("Failed to fetch available services");
  }
};

export const fetchPriceList = async (companyUuid) => {
  const response = await fetch(getQuoteApiUrl(`/pricing/${companyUuid}`), {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });

  if (response.ok) {
    return await response.json();
  } else {
    throw new Error("Failed to fetch price list");
  }
};

export const uploadPriceList = async (companyUuid, file) => {
  const formData = new FormData();
  formData.append("price_list", file);

  const response = await fetch(
    getQuoteApiUrl(`/pricing/${companyUuid}/csv-upload`),
    {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    },
  );

  if (response.ok) {
    return;
  } else {
    // Handle error
    console.error("Failed to upload price list");
    throw new Error("Failed to upload price list");
  }
};

export const fetchWasteContainerInfo = async (companyUuid) => {
  const response = await fetch(
    getQuoteApiUrl(`/pricing/company-waste-container-info/${companyUuid}`),
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getAccessToken()}`,
      },
    },
  );

  if (response.ok) {
    return await response.json();
  } else {
    console.error("Failed to fetch waste containers");
    throw new Error("Failed to fetch waste containers");
  }
};

export const patchWasteContainerInfo = async (
  companyUuid,
  wasteContainerInfo,
) => {
  const response = await fetch(
    getQuoteApiUrl(`/pricing/company-waste-container-info/${companyUuid}`),
    {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: JSON.stringify(wasteContainerInfo),
    },
  );

  if (response.ok) {
    return;
  } else {
    console.error("Failed to update waste containers");
    throw new Error("Failed to update waste containers");
  }
};

export const createWasteContainerInfo = async (
  companyUuid,
  wasteContainerInfo,
) => {
  const response = await fetch(
    getQuoteApiUrl(`/pricing/company-waste-container-info/${companyUuid}`),
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: JSON.stringify(wasteContainerInfo),
    },
  );

  if (response.ok) {
    return;
  } else {
    console.error("Failed to create waste containers");
    throw new Error("Failed to create waste containers");
  }
};

export const deleteWasteContainerInfo = async (
  companyUuid,
  containerType,
  wasteType,
) => {
  const response = await fetch(
    getQuoteApiUrl(
      `/pricing/company-waste-container-info/${companyUuid}?container_type=${encodeURIComponent(
        containerType,
      )}&waste_type=${encodeURIComponent(wasteType)}`,
    ),
    {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    },
  );

  if (response.ok) {
    return;
  } else {
    console.error("Failed to delete waste containers");
    throw new Error("Failed to delete waste containers");
  }
};

export const updatePriceListItem = async (companyUuid, priceListItem) => {
  const response = await fetch(getQuoteApiUrl(`/pricing/${companyUuid}`), {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAccessToken()}`,
    },
    body: JSON.stringify(priceListItem),
  });

  if (response.ok) {
    return;
  } else {
    console.error("Failed to update price list item");
    throw new Error("Failed to update price list item");
  }
};

export const deletePriceListItem = async (companyUuid, priceListItemUuid) => {
  const response = await fetch(
    getQuoteApiUrl(
      `/pricing/${companyUuid}?price_list_item=${priceListItemUuid}`,
    ),
    {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    },
  );

  if (response.ok) {
    return;
  } else {
    console.error("Failed to delete price list item");
    throw new Error("Failed to delete price list item");
  }
};

export const fetchRefererWhitelabelDetails = async () => {
  const response = await fetch(
    getQuoteApiUrl(`/whitelabel/domain/referer-whitelabel`),
    { method: "GET" },
  );

  if (response.ok) {
    return await response.json();
  } else {
    throw new Error("Failed to fetch referer whitelabel details");
  }
};

export const fetchWhitelabelDetails = async (companyUuid) => {
  const response = await fetch(
    getQuoteApiUrl(`/whitelabel/domain/${companyUuid} `),
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    },
  );

  if (response.ok) {
    return await response.json();
  } else {
    throw new Error("Failed to fetch white label details by company uuid");
  }
};

export const createWhitelabelDomain = async (companyUuid, domain) => {
  const response = await fetch(
    getQuoteApiUrl(`/whitelabel/domain/${companyUuid}`),
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: JSON.stringify({ domain }),
    },
  );

  if (response.ok) {
    return;
  } else {
    throw new Error("Failed to create whitelabel domain");
  }
};

export const deleteWhitelabelDomain = async (companyUuid) => {
  const response = await fetch(
    getQuoteApiUrl(`/whitelabel/domain/${companyUuid}`),
    {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    },
  );

  if (response.ok) {
    return;
  } else {
    throw new Error("Failed to delete whitelabel domain");
  }
};

export const verifyWhitelabelDomain = async (companyUuid) => {
  const response = await fetch(
    getQuoteApiUrl(`/whitelabel/domain/${companyUuid}/verify`),
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    },
  );

  if (response.ok) {
    return;
  } else {
    throw new Error("Failed to verify whitelabel domain");
  }
};

export const setWhitelabelDomainStyle = async (companyUuid, style) => {
  const response = await fetch(
    getQuoteApiUrl(`/whitelabel/domain/${companyUuid}/style`),
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getAccessToken()}`,
      },
      body: JSON.stringify(style),
    },
  );

  if (response.ok) {
    return;
  } else {
    if (response.status === 400) {
      const error = await response.json();
      throw new Error(error.error);
    }
    throw new Error("Failed to set whitelabel domain style");
  }
};

export const fetchWhitelabelDomainStlye = async (companyUuid) => {
  const response = await fetch(
    getQuoteApiUrl(`/whitelabel/domain/${companyUuid}/style`),
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    },
  );

  if (response.ok) {
    return await response.json();
  } else {
    throw new Error("Failed to fetch whitelabel domain style");
  }
};

export const uploadWhitelabelIcon = async (companyUuid, file) => {
  const formData = new FormData();
  formData.append("icon", file);

  const response = await fetch(
    getQuoteApiUrl(`/whitelabel/domain/${companyUuid}/style/icon`),
    {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    },
  );

  if (response.ok) {
    return;
  } else {
    throw new Error("Failed to upload whitelabel icon");
  }
};

export const fetchWhitelabelIcon = async (companyUuid) => {
  const response = await fetch(
    getQuoteApiUrl(`/whitelabel/domain/${companyUuid}/style/icon`),
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    },
  );

  if (response.ok) {
    return await response.blob();
  } else {
    throw new Error("Failed to fetch whitelabel icon");
  }
};

export const getRefererWhitelabelIcon = async () => {
  const response = await fetch(
    getQuoteApiUrl(`/whitelabel/domain/referer-whitelabel/icon`),
    {
      method: "GET",
    },
  );

  if (response.ok) {
    return await response.blob();
  } else {
    throw new Error("Failed to fetch referer whitelabel icon");
  }
};
