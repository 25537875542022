import React, { useState } from "react";
import PropTypes from "prop-types";
import "./index.css";
import { createWasteContainerInfo } from "../../api/quote";
import { useGlobalContext } from "../../GlobalContext";

const AddWasteContainerInfo = ({ closeModalCb }) => {
  const { state } = useGlobalContext();
  const [formData, setFormData] = useState({
    container_type: "240LTR",
    waste_type: "CRD",
    excess_weight_charge_gbp: 0,
    weight_restriction_kilos: 0,
  });

  const handleChange = (e) => {
    const { name, value, type } = e.target;
    setFormData((prevData) => {
      const updatedData = {
        ...prevData,
        [name]: type === "number" ? parseFloat(value) : value,
      };

      return updatedData;
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const create = async () => {
      try {
        await createWasteContainerInfo(state.selectedCompany, {
          ...formData,
        });
        closeModalCb();
      } catch (error) {
        console.error(error);
      }
    };

    create();
  };

  return (
    <div className="edit-waste-container-info">
      <h2>Add Waste Container Info</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="container_type">Container Type</label>
          <select
            type="text"
            id="container_type"
            name="container_type"
            value={formData.container_type}
            onChange={handleChange}
            required
          >
            <option value="240LTR">240 Litre</option>
            <option value="260LTR">260 Litre</option>
            <option value="660LTR">660 Litre</option>
            <option value="1100LTR">1100 Litre</option>
            <option value="6YARD">6 Yard</option>
            <option value="8YARD">8 Yard</option>
            <option value="10YARD">10 Yard</option>
            <option value="50BAGS">50 Bags</option>
            <option value="FEL">FEL</option>
            <option value="RORO">RoRO</option>
            <option value="M&V">M&V</option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="waste_type">Waste Type</label>
          <select
            type="text"
            id="waste_type"
            name="waste_type"
            value={formData.waste_type}
            onChange={handleChange}
            required
          >
            <option value="CRD">CRD</option>
            <option value="DMR">DMR</option>
            <option value="FOD">FOD</option>
            <option value="GEN">GEN</option>
            <option value="GLS">GLS</option>
            <option value="WEEE">WEEE</option>
            <option value="GAR">GAR</option>
            <option value="M&V">M&V</option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="excess_weight_charge_gbp">
            Excess Weight Charge (GBP)
          </label>
          <input
            type="number"
            id="excess_weight_charge_gbp"
            name="excess_weight_charge_gbp"
            value={formData.excess_weight_charge_gbp}
            onChange={handleChange}
            step="0.01"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="weight_restriction_kilos">
            Weight Restriction (Kilos)
          </label>
          <input
            type="number"
            id="weight_restriction_kilos"
            name="weight_restriction_kilos"
            value={formData.weight_restriction_kilos}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-actions">
          <button type="submit">Add</button>
          <button type="button" onClick={closeModalCb}>
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

AddWasteContainerInfo.propTypes = {
  closeModalCb: PropTypes.func.isRequired,
};

export default AddWasteContainerInfo;
