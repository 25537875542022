import React from "react";
import "./index.css";
import PageWrapper from "../../components/PageWrapper";

const Home = () => {
  return (
    <PageWrapper>
      <div>
        <h2>Home</h2>
      </div>
    </PageWrapper>
  );
};

export default Home;
