import React, { useState, useEffect } from "react";
import { MdClose } from "react-icons/md";
import {
  createPriceListImport,
  getPriceListImports,
  getPublicPriceLists,
  deletePriceListImport,
} from "../../api/quote";
import { toast } from "react-toastify";
import { useGlobalContext } from "../../GlobalContext";
import { CiCirclePlus } from "react-icons/ci";
import { getCompanyPublicDetails } from "../../api/company";

const InputLabel = ({ text }) => (
  <label className="mr-2 bg-white text-sm italic text-gray-600 duration-100 ease-linear">
    {text}
  </label>
);

const PendingPriceListImport = ({
  publicPriceLists,
  importedPriceLists,
  supplierNames,
  onClose,
  selectedCompany,
}) => {
  const [availablePriceLists, setAvailablePriceLists] = useState([]);
  const [formData, setFormData] = useState({});
  useEffect(() => {
    const availablePriceListsData = publicPriceLists.filter(
      (supplier) =>
        !importedPriceLists
          .map((ipl) => ipl.supplier_uuid)
          .includes(supplier) && supplier !== selectedCompany,
    );
    setAvailablePriceLists(availablePriceListsData);
    setFormData({
      supplier: availablePriceListsData[0],
      markupFactor: "1.00",
      redactSupplierDetails: true,
    });
  }, [importedPriceLists]);

  return (
    <div className="border p-2">
      <div className="flex justify-end">
        <button className="ml-auto" onClick={onClose}>
          <MdClose className="text-xl" />
        </button>
      </div>
      {availablePriceLists.length === 0 ? (
        <p className="mt-4 text-gray-600">
          No public price lists available to import.
        </p>
      ) : (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            createPriceListImport(
              selectedCompany,
              formData.supplier,
              Number(formData.markupFactor),
              formData.redactSupplierDetails,
            )
              .then(() => {
                onClose();
                toast.success("Price list imported successfully");
              })
              .catch((err) => {
                toast.error("Failed to import price list");
                console.error(err);
              });
          }}
        >
          <div className="mt-2 flex items-center gap-4">
            <p className="w-1/4">Supplier</p>
            <select
              className="border p-1"
              onChange={(e) =>
                setFormData((formData) => ({
                  ...formData,
                  supplier: e.target.value,
                }))
              }
              defaultValue={availablePriceLists[0]}
            >
              {availablePriceLists.map((supplierUuid) => (
                <option key={supplierUuid} value={supplierUuid}>
                  {supplierNames[supplierUuid]}
                </option>
              ))}
            </select>
          </div>
          <InputLabel text="The supplier who's price list you want to import" />
          <div className="mt-2 flex items-center gap-4">
            <p className="w-1/4">Markup Factor</p>
            <input
              type="text"
              className="w-14 border p-1"
              value={formData.markupFactor}
              onChange={(e) => {
                if (isNaN(e.target.value)) return;
                if (e.target.value.length > 4) return;
                setFormData((formData) => ({
                  ...formData,
                  markupFactor: e.target.value,
                }));
              }}
            />
          </div>
          <InputLabel text="The multiplication factor applied to prices imported from this price list in your quote builder" />
          <div className="relative mt-2 flex items-center gap-4">
            <p className="w-1/4">Redact supplier details?</p>
            <label className="relative cursor-pointer">
              <input
                type="checkbox"
                className="peer sr-only"
                checked={formData.redactSupplierDetails}
                disabled={false}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    redactSupplierDetails: e.target.checked,
                  });
                }}
              />
              <div className="peer relative h-6 w-11 cursor-pointer rounded-full bg-gray-200 p-1 after:absolute after:start-[2px] after:top-[2px] after:size-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-blue-600 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rtl:peer-checked:after:-translate-x-full dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-blue-800" />
            </label>
          </div>
          <InputLabel text="Whether to redact the supplier's details from quotes generated within your quote builder" />

          <div className="mt-4 flex justify-center">
            <button className="rounded bg-blue-500 p-2 text-white">
              Create Import
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

const ManageExternalPriceList = ({ onClose }) => {
  const { state } = useGlobalContext();

  const [importedPriceLists, setImportedPriceLists] = useState([]);
  const [publicPriceLists, setPublicPriceLists] = useState([]);
  const [priceListImportPending, setPriceListImportPending] = useState(false);
  const [supplierNames, setSupplierNames] = useState({});

  const loadSupplierNames = async () => {
    try {
      if (publicPriceLists.length < 1) return;
      const details = await getCompanyPublicDetails(publicPriceLists);
      const map = {};
      details.forEach((detail) => {
        map[detail.uuid] = detail.name;
      });
      setSupplierNames(map);
    } catch (error) {
      console.error(error);
    }
  };

  const loadPublicPriceLists = async () => {
    try {
      const publicPriceLists = await getPublicPriceLists();
      setPublicPriceLists(publicPriceLists);
    } catch (error) {
      toast.error("Failed to load public price lists");
      console.error(error);
    }
  };

  const loadPriceListImports = async () => {
    try {
      const importedPriceLists = await getPriceListImports(
        state.selectedCompany,
      );
      setImportedPriceLists(importedPriceLists);
    } catch (error) {
      toast.error("Failed to load imported price lists");
      console.error(error);
    }
  };

  useEffect(() => {
    loadPublicPriceLists();
    loadPriceListImports();
  }, []);

  useEffect(() => {
    loadSupplierNames();
  }, [publicPriceLists]);

  return (
    <div className="h-[60vh] w-[60vw] p-6">
      <div className="flex justify-end">
        <button className="ml-auto" onClick={onClose}>
          <MdClose className="text-xl" />
        </button>
      </div>
      <h1 className="mb-8 mt-2 text-2xl font-bold text-black">
        Imported Price Lists
      </h1>
      <p>
        Configure which company's you want to use as a price source in your
        quote generation.
      </p>
      {importedPriceLists.length === 0 ? (
        <p className="mt-4 text-gray-600">
          No price lists imported yet. Quotes generated through your company's
          quote builder will exclusively use your own price list.
        </p>
      ) : (
        <div className="mt-4">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
                  Supplier
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
                  Markup Factor
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
                  Redact Supplier Details
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {importedPriceLists.map((priceList) => (
                <tr key={priceList.supplier_uuid}>
                  <td className="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900">
                    {supplierNames[priceList.supplier_uuid]}
                  </td>
                  <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-800">
                    {priceList.markup_factor.toFixed(2)}
                  </td>
                  <td className="whitespace-nowrap px-6 py-4 text-sm text-gray-800">
                    {priceList.redact_supplier_details ? "Yes" : "No"}
                  </td>
                  <td className="whitespace-nowrap px-6 py-4 text-sm text-red-500">
                    <button
                      onClick={() => {
                        deletePriceListImport(
                          state.selectedCompany,
                          priceList.supplier_uuid,
                        )
                          .then(() => {
                            loadPriceListImports();
                            toast.success("Price list deleted successfully");
                          })
                          .catch((err) => {
                            toast.error("Failed to delete price list");
                            console.error(err);
                          });
                      }}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {priceListImportPending && (
        <PendingPriceListImport
          importedPriceLists={importedPriceLists}
          publicPriceLists={publicPriceLists}
          supplierNames={supplierNames}
          onClose={() => {
            setPriceListImportPending(false);
            loadPriceListImports();
          }}
          selectedCompany={state.selectedCompany}
        />
      )}
      <button
        className="mt-6 flex items-center gap-1 text-blue-500 disabled:text-gray-400"
        onClick={() => {
          if (priceListImportPending) return;
          setPriceListImportPending(true);
        }}
        disabled={priceListImportPending}
      >
        <CiCirclePlus className="text-3xl" />
        <p className="text-base">Import a public price list</p>
      </button>
    </div>
  );
};

export default ManageExternalPriceList;
