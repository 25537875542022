import React from "react";

const ProgressBar = ({ setPage, currPageIndex, pages }) => {
  return (
    <section className="my-4 flex w-full flex-col items-center xl:my-6 2xl:my-8">
      <div>
        <p className="text-black-900 mb-3 block text-2xl font-bold xl:mb-5 xl:text-3xl 2xl:mb-8 2xl:text-4xl">
          Step {currPageIndex + 1} of {pages.length}
        </p>
        <div className="flex items-center">
          {pages.map((page, index) => {
            return (
              <div key={index} className="flex items-center">
                <button
                  onClick={() => setPage(page)}
                  disabled={currPageIndex < index}
                  className={`z-10 -mx-1 size-10 rounded-full xl:size-12 2xl:size-16 ${currPageIndex >= index ? "bg-primaryColor" : "bg-gray-300"}`}
                >
                  <p
                    className={`${currPageIndex >= index ? "text-secondaryColor" : "text-black"}`}
                  >
                    {index + 1}
                  </p>
                </button>
                {index !== pages.length - 1 && (
                  <div
                    className={`h-2 w-32 xl:h-3 xl:w-40 2xl:h-4 2xl:w-44 ${currPageIndex > index ? "bg-primaryColor" : "bg-gray-300"}`}
                  ></div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default ProgressBar;
