import React from "react";

const Tooltip = ({ visible, children, text }) => {
  return (
    <div className="group relative">
      {children}
      <div
        className={`absolute left-full top-1/2 ml-2 -translate-y-1/2 rounded bg-gray-800 p-2 text-xs text-white group-hover:block lg:bottom-full lg:left-1/2 lg:top-auto lg:mb-2 lg:ml-0 lg:-translate-x-1/2 lg:translate-y-0 lg:p-4 lg:text-sm ${visible ? "block" : "hidden"}`}
      >
        {text}
      </div>
    </div>
  );
};

export default Tooltip;
