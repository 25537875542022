import PropTypes from "prop-types";
import React from "react";
import SIXYARD from "../../assets/container_icons/6YARD.png";
import EIGHTYARD from "../../assets/container_icons/8YARD.png";
import TENYARD from "../../assets/container_icons/10YARD.png";
import FIFTYBAGS from "../../assets/container_icons/50BAGS.png";
import TWOFORTYLTR from "../../assets/container_icons/240LTR.png";
import THREESIXTYLTR from "../../assets/container_icons/360LTR.png";
import SIXSIXTYLTR from "../../assets/container_icons/660LTR.png";
import SEVENSEVENTYLTR from "../../assets/container_icons/770LTR.png";
import THOUSANDONEHUNDREDLTR from "../../assets/container_icons/1100LTR.png";
import FEL from "../../assets/container_icons/FEL.png";
import MandV from "../../assets/container_icons/M&V.png";
import RORO from "../../assets/container_icons/RORO.png";

const ContainerIcon = ({ containerType }) => {
  const containerTypeIconLookup = {
    "6YARD": <img src={SIXYARD} className="size-full" />,
    "8YARD": <img src={EIGHTYARD} className="size-full" />,
    "10YARD": <img src={TENYARD} className="size-full" />,
    "50BAGS": <img src={FIFTYBAGS} className="size-full" />,
    "240LTR": <img src={TWOFORTYLTR} className="size-full" />,
    "260LTR": <img src={TWOFORTYLTR} className="size-full" />,
    "360LTR": <img src={THREESIXTYLTR} className="size-full" />,
    "660LTR": <img src={SIXSIXTYLTR} className="size-full" />,
    "770LTR": <img src={SEVENSEVENTYLTR} className="size-full" />,
    "1100LTR": <img src={THOUSANDONEHUNDREDLTR} className="size-full" />,
    FEL: <img src={FEL} className="size-full" />,
    "M&V": <img src={MandV} className="size-full" />,
    RORO: <img src={RORO} className="size-full" />,
  };
  return <>{containerTypeIconLookup[containerType]}</>;
};
ContainerIcon.propTypes = {
  containerType: PropTypes.string.isRequired,
};
export default ContainerIcon;
